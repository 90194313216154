import { useEffect, useState } from 'react';
import { Contract } from '../ContractsDropdown';
import { SelectOptionType } from '../types';
import { Box, Chip } from '@mui/material';
import Grid3x3Icon from '@mui/icons-material/Grid3x3';
import PlaceIcon from '@mui/icons-material/Place';

const OptionLabel = ({
    contract,
}: {
    contract: Contract;
}) => (
    <Box className="d-flex align-items-center">
        <Box sx={{ minWidth: '150px' }}>
            <Chip
                label={
                    <div className="d-flex align-items-center">
                        <Grid3x3Icon sx={{ mr: 1 }} fontSize="small" /> {contract.code}
                    </div>
                }
            />
        </Box>
        <Box className="d-flex align-items-center">
            <PlaceIcon sx={{ mr: 1 }} fontSize="small" /> {contract.label}
        </Box>
    </Box>
);

const getLabel = (contract: Contract) => contract.label + ' (' + contract.code + ')';

export const getOption = (contract: Contract) => {
    return {
        optionLabel: <OptionLabel contract={contract} />,
        label: getLabel(contract),
        value: contract.code
    };
};

const useGetContractsOptions = (contracts: Contract[]) => {
    const [options, setOptions] = useState<SelectOptionType[]>([]);

    useEffect(() => {
        const options = contracts
            ?.map((contract) => getOption(contract))
            .sort((a, b) => a.label.localeCompare(b.label));
        setOptions(options);
    }, [contracts]);

    return options;
};

export default useGetContractsOptions;
