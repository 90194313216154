import React, { useState } from 'react';
import {
    BirdzNotif,
    StyledTitle,
    useNotif
} from '@applications-terrains/birdz-react-library';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { DiffEditor } from '@monaco-editor/react';
import './ConfigurationCr.scss';
import { CrFilenames } from './ConfigurationCrForm';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Search } from '@mui/icons-material';
import { PiFileCsv } from 'react-icons/pi';
import PagePaper from '../Tools/Pages/PagePaper';
import BSearchForm from '../Tools/Search/BSearchForm';
import BTable from '../Tools/Table/BTable';
import TrueFalse from '../Tools/Table/components/TrueFalse';

type CR = {
    created_at: number;
    crplus: string;
    error: string;
    filename: string;
    id: number;
    new_config: string;
    original_config: string;
    send_date: string;
    send_sms: boolean;
    source_file_date: string;
    state: string;
    updated_at: number;
};

export const ConfigurationCr = () => {
    const [filters, setFilters] = useState<string>('');
    const [detailCR, setDetailCR] = useState<CR>();
    const today = new Date();
    const filename = `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}-${today.getHours()}h${today.getMinutes()}m${today.getSeconds()}s`;
    const exportFileName = `export-config-CR-${filename}.csv`;
    const { notif, notifOptions } = useNotif();
    const model = 'cr-configurations';
    const endpoint = '/api/cr-plus/config-updates/';

    const searchFields = [
        {
            name: 'crplus',
            label: 'Device',
            type: 'text'
        },
        {
            name: 'campaign__filename',
            type: 'select',
            label: 'Type de fichier',

            options: {
                values: CrFilenames.map((crFile: string) => {
                    return { value: crFile, label: crFile };
                })
            }
        },
        {
            name: 'send_date_after',
            label: 'Entre le ',
            type: 'datepicker'
        },
        {
            name: 'send_date_before',
            label: 'et le',
            type: 'datepicker'
        },
        {
            name: 'campaign__created_by',
            label: 'Créateur de la modif',
            options: {
                identifier: 'id',
                source: '/api/users/?ordering=name&',
                label: 'name'
            }
        },
        {
            name: 'state',
            label: 'Etat',
            type: 'select',
            options: {
                values: [
                    { value: 'SENT', label: 'Envoyé' },
                    { value: 'PROCESSING', label: 'Traitement en cours' },
                    { value: 'ERROR', label: 'Erreur' }
                ]
            }
        }
    ];

    const listFields = [
        { name: 'crplus', label: 'CR', orderable: true },
        {
            name: 'type',
            label: 'Type',
            transform: (item: any) => {
                return 'CR+';
            }
        },
        { name: 'filename', label: 'Type de fichier', orderable: true },
        {
            name: 'source_file_date',
            label: 'Date fichier source',
            orderable: true,
            transform: (item: any) => {
                return item ? moment(item).format('DD/MM/YYYY à HH:mm:ss') : '-';
            }
        },
        {
            name: 'send_date',
            label: 'Date envoi',
            orderable: true,
            transform: (item: any) => {
                return item ? moment(item).format('DD/MM/YYYY à HH:mm:ss') : '-';
            }
        },
        {
            name: 'send_sms',
            label: 'Envoi SMS / Retour',
            transform: (value: boolean) => {
                return <TrueFalse value={value} />;
            }
        },
        {
            name: 'state',
            label: 'Etat',
            orderable: true,
            transform: (value: any) => {
                switch (value) {
                    case 'SENT':
                        return 'Envoyé';
                    case 'PROCESSING':
                        return 'Traitement en cours';
                    case 'ERROR':
                        return 'Erreur';
                    default:
                        return value;
                }
            }
        }
    ];

    const displayField = (field: string, value: string) => {
        return (
            <>
                <Grid item xs={2}>
                    {field}
                </Grid>
                <Grid item xs={10}>
                    {value}
                </Grid>
            </>
        );
    };

    return (
        <PagePaper
            title={
                <Grid justifyContent="space-between" alignItems={'center'} container>
                    <Grid item>
                        <StyledTitle className="m-0">Configuration CR</StyledTitle>
                    </Grid>
                    <Grid item>
                        <Link to="/configuration-cr/new-config">
                            <Button id={'cr-new-config-button'} variant="contained">
                                <AddCircleIcon sx={{ mr: 1 }} /> Nouvelle modification
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
            }>
            <>
                <BSearchForm columns={2} fields={searchFields} setSearchString={setFilters} />
                <BTable
                    model={model}
                    endpoint={endpoint + filters}
                    fields={listFields}
                    actions={[
                        {
                            name: 'see',
                            label: 'Voir',
                            render: (CR: CR) => {
                                return (
                                    <IconButton
                                        id={'cr-see-button'}
                                        onClick={() => {
                                            setDetailCR(CR);
                                        }}>
                                        <Search fontSize="small" />
                                    </IconButton>
                                );
                            }
                        }
                    ]}
                    exportButtons={[
                        {
                            name: 'Exporter en csv',
                            fileName: exportFileName,
                            type: '', //csv
                            icon: <PiFileCsv />,
                            requestEndpoint: `/api/cr-plus/config-updates/export/?timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`,
                            requestMethod: 'POST',
                            responseType: 'json',
                            keepQueryParams: false,
                            id: 'export-csv-button',
                            onSuccess: () => {
                                notif({
                                    type: 'success',
                                    content: "L'export va vous être envoyé par mail."
                                });
                            },
                        }
                    ]}
                />
            </>
            <BirdzNotif options={notifOptions} />
            {detailCR?.id && (
                <Dialog
                    className="configCr"
                    sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
                    maxWidth="xl"
                    open={true}>
                    <DialogTitle>CR {detailCR.crplus}</DialogTitle>
                    <DialogContent dividers>
                        <Grid container spacing={1}>
                            {displayField('Fichier', detailCR.filename)}
                            {displayField('SMS', detailCR.send_sms ? 'Oui' : 'Non')}
                            {displayField(
                                'Date fichier source',
                                detailCR.source_file_date
                                    ? moment(detailCR.source_file_date).format(
                                          'DD/MM/YYYY à HH:mm:ss'
                                      )
                                    : '-'
                            )}
                            {displayField(
                                'Date envoi',
                                detailCR.send_date
                                    ? moment(detailCR.send_date).format('DD/MM/YYYY à HH:mm:ss')
                                    : '-'
                            )}

                            {detailCR.error && displayField('Erreurs', detailCR.error)}
                        </Grid>

                        <div className="diffeditor">
                            <div>Fichier source</div>
                            <div>Fichier modifié</div>
                        </div>
                        <DiffEditor
                            height="40vh"
                            original={detailCR?.original_config}
                            modified={detailCR?.new_config}
                            originalLanguage="xml"
                            modifiedLanguage="xml"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            id={'cr-close-button'}
                            variant="contained"
                            onClick={() => setDetailCR(undefined)}>
                            Fermer
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </PagePaper>
    );
};

export default ConfigurationCr;
