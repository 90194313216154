import React, { Fragment, ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import { ListField } from '@applications-terrains/birdz-react-library';
import { Box, Button, Checkbox, IconButton, TableCell, TableRow } from '@mui/material';

import { ArrayFieldType, TableRowType } from '../BTable';
import { capitalizeFirstLetter } from '../../../../utils';
import LightTooltip from '../../LightTooltip';
import ExportButton from '../export/ExportButton';
import { ExportButton as ExportButtonType } from '@applications-terrains/birdz-react-library';
import {
    blue
    //  green
} from '@mui/material/colors';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import EastIcon from '@mui/icons-material/East';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import SortIcon from '@mui/icons-material/Sort';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { isEmpty } from 'lodash';

const CustomHeaderCell = ({ text }: { text: string }) => {
    const [hover, setHover] = useState(false);
    const [textFits, setTextFits] = useState(true);
    const [maxTextWidth, setMaxTextWidth] = useState<number>(0);
    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const noWrapRef = useRef<HTMLDivElement | null>(null);
    const wrapRef = useRef<HTMLDivElement | null>(null);
    const [translateDistance, setTranslateDistance] = useState(0);

    // Vérifier si le texte dépasse la largeur de la box
    useEffect(() => {
        if (wrapperRef.current && noWrapRef.current && wrapRef.current) {
            const wrapperWidth = wrapperRef.current.clientWidth;
            const noWrapRefWidth = noWrapRef.current.clientWidth;
            const wrapRefWidth = wrapRef.current.clientWidth;

            setMaxTextWidth(wrapRefWidth);
            setTextFits(noWrapRefWidth <= wrapperWidth);
            setTranslateDistance(noWrapRefWidth - wrapperWidth); // Calculer la distance de déplacement
        }
    }, [text]);

    return (
        <Box className="w-100 d-flex align-items-center" ref={wrapperRef}>
            <Box
                ref={noWrapRef}
                sx={{
                    position: 'absolute',
                    whiteSpace: 'nowrap',
                    height: 0,
                    overflow: 'hidden'
                }}>
                {text}
            </Box>
            <Box
                ref={wrapRef}
                sx={{
                    position: 'absolute',
                    height: 0,
                    overflow: 'hidden'
                }}>
                {text}
            </Box>
            <Box
                sx={{
                    position: 'relative',
                    display: 'inline-block',
                    maxWidth: maxTextWidth, // La box garde une largeur adaptative
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    color: '#666666',
                    cursor: !textFits ? 'pointer' : 'default'
                }}
                onMouseEnter={() => !textFits && setHover(true)}
                onMouseLeave={() => setHover(false)}>
                <Box
                    sx={{
                        display: 'inline-block',
                        width: '100%',
                        transform: hover ? `translateX(-${translateDistance}px)` : 'translateX(0%)',
                        transition: hover ? 'transform 0.5s linear' : 'none'
                    }}>
                    {text}
                </Box>
                {!textFits && !hover && (
                    <Box
                        sx={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            backgroundColor: '#fff',
                            pl: '2px'
                        }}>
                        ...
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export const BTableHeadRow = ({
    fields,
    actions,
    handleSelectAll,
    selected,
    indeterminate
}: {
    fields: (ListField & { labelIcon?: ReactNode })[];
    actions?: any[];
    handleSelectAll?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    selected: boolean;
    indeterminate?: boolean;
}) => (
    <TableRow>
        {handleSelectAll && (
            <TableCell padding="checkbox">
                <Checkbox
                    className={selected || indeterminate ? 'theme-color' : ''}
                    size="small"
                    indeterminate={indeterminate}
                    checked={selected}
                    onChange={handleSelectAll}
                    inputProps={{
                        'aria-label': 'select all'
                    }}
                />
            </TableCell>
        )}
        {fields.map((el) => (
            <TableCell sx={{ py: 2 }} className="header-cell" key={el.label + 'head'}>
                <Box className="d-flex align-items-center" sx={{ maxHeight: '25px' }}>
                    {el.labelIcon && (
                        <Box
                            sx={{
                                mr: 0.75,
                                ml: -0.5,
                                my: 0,
                                width: '20px',
                                height: '20px'
                            }}>
                            {el.labelIcon}
                        </Box>
                    )}
                    <CustomHeaderCell text={el.label} />
                </Box>
            </TableCell>
        ))}
        {actions && actions?.length > 0 && (
            <TableCell
                sx={{ textAlign: 'right', color: '#666666' }}
                className="header-cell-actions">
                Actions
            </TableCell>
        )}
    </TableRow>
);

export const BTableRow = ({
    row,
    fields,
    actions,
    isSelected,
    onSelectItems,
    selectedItems
}: {
    row: any;
    fields: ListField[];
    actions?: any[];
    isSelected: boolean;
    onSelectItems?: (selectedItems: TableRowType[]) => void;
    selectedItems?: TableRowType[];
}) => (
    <TableRow
        data-label={row.name}
        key={'tableRow' + row.internalIdInTable}
        className={`table-row ${onSelectItems && selectedItems ? 'selectable' : ''} ${isSelected ? 'selected' : ''}`}
        onClick={() => {
            if (onSelectItems && selectedItems) {
                onSelectItems(
                    isSelected
                        ? selectedItems.filter(
                              (item) => item.internalIdInTable !== row.internalIdInTable
                          )
                        : [...selectedItems, row]
                );
            }
        }}>
        {fields.map((el) => {
            const transform = el?.transform ? el?.transform(row[el.name], row) : '';
            // const sx = isSelected
            //     ? {
            //           borderTop: 'none',
            //           borderColor: isSelected && "transparent",
            //           backgroundColor: isSelected && green[50]
            //       }
            //     : {};
            const content: ReactNode = transform || row[el.name] || '-';
            return (
                <TableCell
                    // sx={sx}
                    key={'tableRow' + el.label + 'Cell' + row.internalIdInTable}
                    className="cell" title={el.label}>
                    <Box className="inner-cell">
                        {typeof content !== 'number' && isEmpty(content) ? '-' : content}
                    </Box>
                </TableCell>
            );
        })}
        {actions && actions?.length > 0 && (
            <TableCell width={'1px'}>
                <Box
                    className="d-flex justify-content-end"
                    sx={{
                        marginRight: '-8px'
                    }}
                    onClick={(e) => e.stopPropagation()}>
                    {actions?.map(
                        (action) =>
                            typeof action.render === 'function' && (
                                <Fragment key={action.name}>{action?.render(row)}</Fragment>
                            )
                    )}
                </Box>
            </TableCell>
        )}
    </TableRow>
);

export const SelectAllButton = ({
    selected,
    setSelected
}: {
    selected: boolean;
    setSelected: (selected: boolean) => void;
}) => {
    return (
        <TableHeaderTooltip
            title={
                <Box sx={{ py: 1.5, fontSize: '0.7rem', color: '#666666' }}>
                    {selected ? 'tout déselectionner' : 'tout sélectionner'}
                </Box>
            }
            element={
                <IconButton
                    id={'select_button'}
                    onClick={() => {
                        setSelected(!selected);
                    }}
                    sx={{
                        ml: '-6px',
                        borderRadius: '4px',
                        p: '3px'
                        // color: green[300]
                    }}>
                    {selected ? (
                        <CheckBoxIcon fontSize="small" />
                    ) : (
                        <CheckBoxOutlineBlankIcon fontSize="small" />
                    )}
                </IconButton>
            }
        />
    );
};

export const ToggleSeeSelectedButton = ({
    selected,
    setSelected
}: {
    selected: boolean;
    setSelected: (selected: boolean) => void;
}) => {
    return (
        <TableHeaderTooltip
            title={
                <Box sx={{ py: 1.5, fontSize: '0.7rem', color: '#666666' }}>
                    {selected ? 'tout voir' : 'voir uniquement la sélection'}
                </Box>
            }
            element={
                <IconButton
                    id={'select_button'}
                    onClick={() => {
                        setSelected(!selected);
                    }}
                    sx={{
                        ml: 0.5,
                        p: 0
                        // color: green[300]
                    }}>
                    {selected ? (
                        <VisibilityOffIcon fontSize="small" />
                    ) : (
                        <VisibilityIcon fontSize="small" />
                    )}
                </IconButton>
            }
        />
    );
};

export const SortColumn = ({
    order,
    setOrder,
    target,
    defaultOrder,
    defaultOrderCol
}: {
    target: string;
    order: string[];
    setOrder: (order: string[]) => void;
    defaultOrder: string;
    defaultOrderCol: boolean;
}) => {
    const iconSx = { width: '16px', height: '16px' };
    const color = '#666666';

    const getIndex = (target: string) => {
        const targetArray = !defaultOrderCol ? order.filter((el) => el !== defaultOrder) : order;
        const index = targetArray.indexOf(target);
        return index + 1;
    };

    const orderData = order.includes(target)
        ? {
              order: 'croissant',
              nextOrder: 'décroissant',
              currentIcon: <ArrowUpwardIcon sx={iconSx} />,
              nextIcon: <ArrowDownwardIcon sx={iconSx} />,
              index: getIndex(target)
          }
        : order.includes(`-${target}`)
          ? {
                order: 'décroissant',
                nextOrder: 'aucun tri',
                currentIcon: <ArrowDownwardIcon sx={iconSx} />,
                nextIcon: <SortIcon sx={iconSx} />,
                index: getIndex(`-${target}`)
            }
          : {
                order: 'aucun tri',
                nextOrder: 'croissant',
                currentIcon: <SortIcon sx={iconSx} />,
                nextIcon: <ArrowUpwardIcon sx={iconSx} />
            };

    return (
        <TableHeaderTooltip
            title={
                <Box
                    className="d-flex align-items-center"
                    gap={2}
                    sx={{ py: 1.5, fontSize: '0.7rem', fontWeight: 400, color }}>
                    <Box className="d-flex flex-column justify-content-center" gap={1}>
                        {/* <Box fontWeight={500}>Tri actuel</Box> */}
                        <Box className="d-flex align-items-center" gap={0.5}>
                            {orderData.order}
                            {orderData.currentIcon}
                        </Box>
                    </Box>
                    <EastIcon fontSize="small" />
                    <Box className="d-flex flex-column justify-content-center" gap={1}>
                        {/* <Box fontWeight={500}>Prochain tri</Box> */}
                        <Box className="d-flex align-items-center" gap={0.5}>
                            {orderData.nextOrder}
                            {orderData.nextIcon}
                        </Box>
                    </Box>
                </Box>
            }
            element={
                <Box position={'relative'} ml={1} display={'flex'}>
                    <Button
                        size="small"
                        id={'sort_column' + target}
                        onClick={() => {
                            const getNewOrder = () => {
                                const isTargetInOrder = order.includes(target);
                                const isReverseTargetInOrder = order.includes(`-${target}`);

                                if (!isTargetInOrder && !isReverseTargetInOrder) {
                                    // Ajouter le tri croissant si la colonne n'est pas déjà triée
                                    return [...order, target];
                                } else if (isTargetInOrder) {
                                    // Remplacer le tri croissant par un tri décroissant
                                    return order.map((col) =>
                                        col === target ? `-${target}` : col
                                    );
                                } else if (isReverseTargetInOrder) {
                                    // Supprimer le tri si la colonne est déjà en tri décroissant
                                    return order.filter((col) => col !== `-${target}`);
                                }
                                return order; // Fallback (ne devrait pas arriver)
                            };
                            setOrder(getNewOrder());
                        }}
                        sx={{
                            p: 0,
                            minWidth: '20px',
                            width: '20px',
                            height: '20px',
                            color
                        }}>
                        {orderData.currentIcon}
                    </Button>
                    {orderData.index && (
                        <Box
                            sx={{
                                position: 'absolute',
                                top: -5,
                                right: -5,
                                backgroundColor: blue[300],
                                borderRadius: '50%',
                                fontSize: '0.6rem',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '12px',
                                height: '12px',
                                color: 'white'
                            }}>
                            {orderData.index}
                        </Box>
                    )}
                </Box>
            }
        />
    );
};

export const HeaderCell = ({
    element,
    order,
    setOrder,
    defaultOrder,
    defaultOrderCol
}: {
    element: ArrayFieldType;
    order: string[];
    setOrder: (order: string[]) => void;
    defaultOrder: string;
    defaultOrderCol: boolean;
}) => {
    const color = '#666666';
    const icon = (
        <Box
            sx={{
                mr: 0.75,
                ml: -0.5,
                my: 0,
                width: '20px',
                height: '20px'
            }}>
            {element.labelIcon}
        </Box>
    );
    return (
        <Box
            className="d-flex align-items-center"
            sx={{
                maxHeight: '25px',
                color
            }}>
            {element.shortLabel ? (
                <TableHeaderTooltip
                    title={
                        <Box
                            className="d-flex align-items-center"
                            gap={3}
                            sx={{ py: 1.5, fontSize: '0.7rem', color }}>
                            {capitalizeFirstLetter(element.label)}
                        </Box>
                    }
                    element={
                        <Box
                            className="d-flex align-items-center"
                            sx={{
                                maxHeight: '25px',
                                color
                            }}>
                            {element.labelIcon && icon}
                            <Box sx={{ whiteSpace: 'nowrap', cursor: 'default' }}>
                                {element.shortLabel === 'iconOnly' ? '' : capitalizeFirstLetter(element.shortLabel)}
                            </Box>
                        </Box>
                    }
                />
            ) : (
                <Box
                    className="d-flex align-items-center"
                    sx={{
                        maxHeight: '25px',
                        color
                    }}>
                    {element.labelIcon && icon}
                    <Box sx={{ whiteSpace: 'nowrap' }}>{capitalizeFirstLetter(element.label)}</Box>
                </Box>
            )}
            {element.orderable && (
                <SortColumn
                    target={element.name}
                    order={order}
                    setOrder={setOrder}
                    defaultOrder={defaultOrder}
                    defaultOrderCol={defaultOrderCol}
                />
            )}
        </Box>
    );
};

const TableHeaderTooltip = ({
    title,
    element,
    offsetY
}: {
    title: ReactNode;
    element: ReactElement<any, any>;
    offsetY?: number;
}) => (
    <LightTooltip
        enterDelay={300}
        customBorderRadius={6}
        customOffsetY={offsetY || -5}
        customShadow="0px 3px 5px -1px rgba(0,0,0,0.1), 0px 6px 10px 0px rgba(0,0,0,0.03), 0px 1px 18px 0px rgba(0,0,0,0.06)"
        title={title}
        placement="top">
        {element}
    </LightTooltip>
);

export const ExportButtonWrapper = ({
    exportButton,
    endpoint
}: {
    exportButton: ExportButtonType;
    endpoint: string;
}) => (
    <TableHeaderTooltip
        title={<Box sx={{ py: 1, fontSize: '0.7rem', color: '#666666' }}>{exportButton.name}</Box>}
        element={
            <Box>
                <ExportButton exportButton={exportButton} endpoint={endpoint} />
            </Box>
        }
    />
);

export const ClearSortButton = ({
    order,
    setOrder
}: {
    order: string[];
    setOrder: (order: string[]) => void;
}) => {
    return (
        <TableHeaderTooltip
            title={
                <Box sx={{ py: 1, fontSize: '0.7rem', color: '#666666' }}>Supprimer les tris</Box>
            }
            element={
                <Box>
                    <IconButton
                        // size="small"
                        sx={{
                            borderRadius: '4px',
                            // p: '3px',
                            color: blue[300]
                        }}
                        id={'clear_sort_button_'}
                        onClick={() => {
                            setOrder([]);
                        }}
                        disabled={order.length === 0}>
                        {<FilterListOffIcon />}
                    </IconButton>
                </Box>
            }
        />
    );
};
