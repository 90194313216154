import React, { useEffect, useState } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import axios from 'axios';
import Select from 'react-select';
import './ContractExclusionList.scss';
import { Trans } from 'react-i18next';
import {
    Box,
    Button,
    CircularProgress,
    Divider,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from '@mui/material';
import {
    BirdzNotif,
    BirdzTitle,
    StyledTableBodyRow,
    StyledTableHeaderRow,
    useNotif
} from '@applications-terrains/birdz-react-library';
import { Close } from '@mui/icons-material';
import PageSizeSelector, { PaginationType } from '../../Tools/PageSizeSelector';
import PaginationComponent from '../../Tools/PaginationComponent';
import PagePaper from '../../Tools/Pages/PagePaper';
import { getResultString } from '../../Tools/Table/const';
import { useSafeTimeout } from '../../../hooks/useSafeTimeout';

export const ContractExclusionList = () => {
    const [data, setData] = useState<any[]>([]);
    const [loadInProgress, setLoadInProgress] = useState<boolean>(false);
    const { notif, notifOptions } = useNotif();

    const [pagination, setPagination] = useState<PaginationType>({
        pageSize: 25,
        currentPage: 1,
        totalItems: 0
    });
    const [forceReload, setForceReload] = useState<boolean>(false);
    const exclusionTypes = [
        { value: 'PRIMO', label: 'Primo raccordement' },
        { value: 'RERAC', label: 'Re-raccordement' },
        { value: 'RENFO', label: 'Renforcement' },
        { value: 'NET', label: 'Nettoyage' },
        { value: 'OPTIM', label: 'Optimisation' }
    ];

    const exclusion = {
        types: exclusionTypes
    };

    useEffect(() => {
        setLoadInProgress(true);
        axios
            .get(
                '/api/racsup/contract-exclusions/?size=' +
                    pagination.pageSize +
                    '&page=' +
                    pagination.currentPage
            )
            .then((response: any) => {
                setLoadInProgress(false);
                if (response.data && response.data?.results) {
                    const results = response.data?.results;
                    results.map((result: any) => {
                        result.modulesCount = 0;
                        result.eligibleCount = 0;
                        result.repeatersCount = 0;

                        if (result.types) {
                            result.types.forEach((type: any) => {
                                result.modulesCount += type.modules_count;
                                result.eligibleCount += type.eligible_count;
                                result.repeatersCount += type.repeaters_count;
                            });
                        }

                        return result;
                    });
                    setPagination({ ...pagination, totalItems: response.data?.count });
                    setData(results);
                }
            });
        // eslint-disable-next-line
    }, [forceReload, pagination.currentPage, pagination.pageSize]);

    const includeAll = (contract: string) => {
        axios.post('/api/racsup/contract-exclusions/', { contract, type_names: [] }).then(() => {
            refreshData();
        });
    };

    const include = (contract: string, types: string[], typeIsExcluded: any) => {
        const excludedTypes = typeIsExcluded.filter((type: any) => {
            return type !== types[0];
        });
        axios
            .post('/api/racsup/contract-exclusions/', {
                contract,
                type_names: excludedTypes
            })
            .then(() => {
                refreshData();
            });
    };

    const addExclusion = (values: any) => {
        if (values.contract && values.types.length) {
            const data = {
                contract: values.contract,
                type_names: values.types.map((type: any) => type.value)
            };
            axios
                .get('/api/racsup/contract-exclusions/' + values.contract + '/')
                .then((response: any) => {
                    if (response.data && response.data[0]?.type_names) {
                        data.type_names = Array.from(
                            new Set([...response.data[0].type_names, ...data.type_names])
                        );
                    }

                    axios.post('/api/racsup/contract-exclusions/', data).then(
                        () => {
                            refreshData();

                            notif({
                                content:
                                    "L'exclusion a bien été ajoutée pour le contrat " +
                                    values.contract,
                                type: 'success'
                            });
                        },
                        (error: any) => {
                            if (error?.response?.data) {
                                const errorsList: string[] = Object.keys(error.response.data);
                                if (errorsList.length) {
                                    notif({
                                        type: 'error',
                                        content: (
                                            <div>
                                                <>
                                                    {
                                                        'Veuillez corriger les erreurs sur le formulaire :'
                                                    }
                                                </>
                                                <ul>
                                                    {errorsList.map((fieldError) => (
                                                        <li key={fieldError}>
                                                            {error.response.data[fieldError]}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )
                                    });
                                }
                            }
                        }
                    );
                });
        }
    };

    const setSafeTimeout = useSafeTimeout();


    const refreshData = () => {
        setForceReload(!!forceReload);
        setSafeTimeout(() => {
            setForceReload(!forceReload);
        }, 0);
    };

    const [collapsedRows, setCollapsedRows] = useState<string[]>([]);
    const collapseRow = (rowId: string) => {
        if (collapsedRows?.includes(rowId)) {
            setCollapsedRows(collapsedRows.filter((collapsedRow) => collapsedRow !== rowId));
        } else {
            setCollapsedRows([...collapsedRows, rowId]);
        }
    };
    const isCollapsed = (rowId: string) => {
        return collapsedRows.includes(rowId);
    };

    return (
        <PagePaper title={<BirdzTitle className="m-0">Ajouter une exclusion</BirdzTitle>}>
            <Box
                sx={{
                    mb: 2,
                    border: '1px solid',
                    borderColor: 'divider',
                    borderRadius: '4px',
                    p: 2
                }}>
                <Formik
                    initialValues={exclusion}
                    enableReinitialize={true}
                    //eslint-disable-next-line
                    onSubmit={(values, { setSubmitting }: FormikHelpers<any>) => {
                        addExclusion(values);
                    }}>
                    {({
                        values,
                        getFieldProps,
                        // isSubmitting,
                        // touched,
                        // errors,
                        setFieldValue
                    }) => (
                        <Form>
                            <Grid container>
                                <Grid item xs={4}>
                                    Contrat
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        id={'racsupExclusion-contract-input'}
                                        type={'text'}
                                        size="small"
                                        fullWidth
                                        {...getFieldProps('contract')}
                                        value={values?.contract || ''}
                                        onChange={(e: any) => {
                                            setFieldValue('contract', e.target.value);
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={4} sx={{ mt: 2 }}>
                                    Type
                                </Grid>
                                <Grid item xs={8} sx={{ mt: 2 }}>
                                    <Select
                                        id={'racsupExclusion-type-select'}
                                        options={exclusionTypes}
                                        {...getFieldProps('types')}
                                        onChange={(values: any) => {
                                            setFieldValue('types', values);
                                        }}
                                        isMulti={true}
                                        loadingMessage={() => 'Chargement en cours...'}
                                        placeholder={''}
                                    />
                                </Grid>
                            </Grid>

                            <Box sx={{ textAlign: 'center', mt: 2 }}>
                                <Button
                                    id={'racsupExclusion-add-button'}
                                    variant="contained"
                                    type="submit">
                                    Ajouter
                                </Button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
            <Paper
                sx={{
                    position: 'relative',
                    border: '1px solid rgba(224, 224, 224, 1)',
                    boxSizing: 'border-box',
                    p: 2,
                    height: '100%'
                }}
                className="d-flex flex-column table-container"
                elevation={0}>
                {loadInProgress && (
                    <Box
                        className="d-flex align-items-center justify-content-center"
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(255, 255, 255, 0.7)',
                            zIndex: 1
                        }}>
                        <CircularProgress color="inherit" />
                    </Box>
                )}
                <Box
                    className="w-100 d-flex align-items-center justify-content-between transitioned"
                    sx={{
                        pl: 4,
                        pr: 2,
                        color: '#666666',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        boxSizing: 'border-box',
                        fontSize: '0.9rem',
                        height: '48px'
                    }}>
                    {getResultString(pagination)}
                </Box>

                <TableContainer
                    className="transitioned"
                    sx={{
                        mt: 4,
                        borderTop: '1px solid rgba(224, 224, 224, 1)',
                        boxSizing: 'border-box'
                    }}>
                    <Table size="small" className="contractExclusionList" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Contrat</TableCell>
                                <TableCell>Nb total de raccordement(s)</TableCell>
                                <TableCell>Nb total de module(s)</TableCell>
                                <TableCell>Nb total de répéteur(s)</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data &&
                                !loadInProgress &&
                                data.map((item: any) => (
                                    <React.Fragment key={item.contract}>
                                        <TableRow
                                            key={item.contract + '-list'}
                                            onClick={() => {
                                                collapseRow(item.contract);
                                            }}
                                            className="clickable">
                                            <TableCell>{item.contract}</TableCell>
                                            <TableCell>{item?.modulesCount || '-'}</TableCell>
                                            <TableCell>{item?.eligibleCount || '-'}</TableCell>
                                            <TableCell>{item?.repeatersCount || '-'}</TableCell>
                                            <TableCell
                                                className="link"
                                                onClick={(e: React.MouseEvent) => {
                                                    includeAll(item.contract);
                                                    e.stopPropagation();
                                                }}>
                                                <Button
                                                    id={'racsupExclusion-include-button'}
                                                    variant="outlined"
                                                    size="small">
                                                    <Close fontSize="small" sx={{ mr: 1 }} />{' '}
                                                    Inclure
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                        {/* <Collapse in={isCollapsed(item.contract)}> */}
                                        {isCollapsed(item.contract) && (
                                            <StyledTableBodyRow key={item.contract + '-detail'}>
                                                <TableCell colSpan={5}>
                                                    <TableContainer component={Paper}>
                                                        <Table size="small">
                                                            <TableHead>
                                                                <StyledTableHeaderRow>
                                                                    <TableCell>Origine</TableCell>
                                                                    <TableCell>
                                                                        Nb raccordement(s)
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        Nb module(s)
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        Nb de répéteur(s)
                                                                    </TableCell>
                                                                    <TableCell>Action</TableCell>
                                                                </StyledTableHeaderRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {item.types.map((type: any) => {
                                                                    return (
                                                                        <StyledTableBodyRow
                                                                            key={type.type}>
                                                                            <TableCell>
                                                                                {type.type}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {type?.modules_count ||
                                                                                    '-'}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {type?.eligible_count ||
                                                                                    '-'}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {type?.repeaters_count ||
                                                                                    '-'}
                                                                            </TableCell>
                                                                            <TableCell
                                                                                className="link"
                                                                                onClick={() =>
                                                                                    include(
                                                                                        item.contract,
                                                                                        item.types
                                                                                            .map(
                                                                                                (
                                                                                                    type: any
                                                                                                ) =>
                                                                                                    type.type
                                                                                            )
                                                                                            .splice(
                                                                                                item.types
                                                                                                    .map(
                                                                                                        (
                                                                                                            type: any
                                                                                                        ) =>
                                                                                                            type.type
                                                                                                    )
                                                                                                    .indexOf(
                                                                                                        type.type
                                                                                                    ),
                                                                                                1
                                                                                            ),
                                                                                        item.types.map(
                                                                                            (
                                                                                                type: any
                                                                                            ) =>
                                                                                                type.type
                                                                                        )
                                                                                    )
                                                                                }>
                                                                                <Button
                                                                                    id={
                                                                                        'racsupExclusion-include-button'
                                                                                    }
                                                                                    variant="outlined"
                                                                                    size="small">
                                                                                    <Close
                                                                                        fontSize="small"
                                                                                        sx={{
                                                                                            mr: 1
                                                                                        }}
                                                                                    />{' '}
                                                                                    Inclure
                                                                                </Button>
                                                                            </TableCell>
                                                                        </StyledTableBodyRow>
                                                                    );
                                                                })}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </TableCell>
                                            </StyledTableBodyRow>
                                        )}
                                        {/* </Collapse> */}
                                    </React.Fragment>
                                ))}
                            {(!data || !data.length || loadInProgress) && (
                                <TableRow>
                                    <TableCell colSpan={5}>
                                        {loadInProgress === true ? (
                                            <Trans>Chargement en cours...</Trans>
                                        ) : (
                                            <Trans>Il n'y a aucun résultat à afficher</Trans>
                                        )}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Divider sx={{ mb: 2 }} />
                <Box className="d-flex justify-content-between align-items-center">
                    <PaginationComponent pagination={pagination} setPagination={setPagination} />
                    <PageSizeSelector pagination={pagination} setPagination={setPagination} />
                </Box>
            </Paper>
            <BirdzNotif options={notifOptions} />
        </PagePaper>
    );
};

export default ContractExclusionList;
