import React, { useEffect, useState } from 'react';
import { Autocomplete, Box } from '@mui/material';
import useReinitializeForm from '../../../hooks/useReinitializeForm';
import { SelectOptionType } from './types';
import ContractsCustomTextField from './ContractsCustomTextField';
import useDisableItemButton from './hooks/useDisableItemButton';

const SingleValueSelectDropdown = ({
    options,
    onChange,
    defaultValue = null,
    required,
    onSearch,
    searchable
}: {
    options: SelectOptionType[];
    onChange: (data: SelectOptionType | null) => void;
    onSearch: (data: string) => void;
    defaultValue: SelectOptionType | null;
    required?: boolean;
    searchable: boolean;
}) => {
    const [value, setValue] = useState<SelectOptionType | null>(defaultValue);
    const [hasBeenClicked, setHasBeenClicked] = useState(false);
    const reset = useReinitializeForm();

    const { hasError, setHasError } = useDisableItemButton({
        required: required === true,
        hasValue: value !== null
    });

    useEffect(() => {
        reset && setValue(null);
    }, [reset]);

    useEffect(() => {
        const isError = !value && hasBeenClicked && required ? true : false;
        setHasError && setHasError(isError);
    }, [value, hasBeenClicked, required, setHasError]);

    return (
        <Box onClick={() => setHasBeenClicked(true)}>
            <Autocomplete
                className="w-100"
                value={value}
                onChange={(_, newValue) => {
                    setValue(newValue);
                    onChange(newValue);
                    if (newValue) {
                        setHasError(false);
                    }
                }}
                options={options}
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => {
                    return (
                        <li {...props} key={option.label}>
                            {option.optionLabel}
                        </li>
                    );
                }}
                isOptionEqualToValue={(option: any, value: any) => {
                    return option.value === value.value;
                }}
                renderInput={(params) => (
                    <ContractsCustomTextField
                        {...params}
                        onSearch={onSearch}
                        size="small"
                        error={hasError === true}
                        placeholder={searchable ? 'minimum 3 caractères' : ''}
                    />
                )}
            />
            {hasError && (
                <Box
                    sx={{
                        fontWeight: 400,
                        fontSize: '0.75rem',
                        margin: '3px 14px 0',
                        color: '#d32f2f',
                        lineHeight: 1.66,
                        letterSpacing: '0.03333em'
                    }}>
                    Merci de renseigner le contrat / client
                </Box>
            )}
        </Box>
    );
};

export default SingleValueSelectDropdown;
