import { Autocomplete, Box, IconButton, TextField } from '@mui/material';
import { ClearIcon, DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { FieldType } from '../types';
import AutoFetchSelect from './Searchable/AutoFetchSelect';

const BSearchField = ({ field, value, setFieldValue }: FieldType) => {
    let element = (
        <Box className="d-flex align-items-center" sx={{ position: 'relative' }}>
            <TextField
                id={field.name}
                name={field.name}
                label={field.label}
                value={value || ''}
                onChange={(e) => setFieldValue(field.name, e.target.value)}
                variant="outlined"
                fullWidth
                size="small"
            />

            {!field.name.includes('__in') && value && (
                <IconButton
                    size="small"
                    onClick={() => setFieldValue(field.name, '')}
                    sx={{
                        position: 'absolute',
                        right: field.options ? 28 : 8
                    }}>
                    <ClearIcon fontSize="small" />
                </IconButton>
            )}
        </Box>
    );

    if (field.options?.values) {
        element = (
            <Autocomplete
                size="small"
                value={value || []}
                options={field.options.values}
                disableCloseOnSelect={field.name.includes('__in')}
                onChange={(_, newValue) => {
                    const value =
                        newValue && field?.options?.returnLabel === true
                            ? field.name.includes('__in')
                                ? newValue.map((el: { label: string; value: any }) => ({
                                      ...el,
                                      value: el.label
                                  }))
                                : { ...newValue, value: newValue.label }
                            : newValue;
                    setFieldValue(field.name, value);
                }}
                renderInput={(params) => <TextField {...params} label={field.label} />}
                isOptionEqualToValue={(option, value) => {
                    return option.label === value?.label;
                }}
                getOptionLabel={(option) => option.label || ''}
                multiple={field.name.includes('__in')}
                fullWidth
                freeSolo
            />
        );
    }

    if (field.options?.source) {
        element = (
            <AutoFetchSelect
                field={field}
                endpoint={field.options?.source}
                value={value}
                setFieldValue={setFieldValue}
            />
        );
    }

    if (field.type === 'datepicker') {
        element = (
            <DatePicker
                className="w-100"
                onChange={(value: any) => {
                    setFieldValue(
                        field.name,
                        value ? moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD') : null
                    );
                }}
                label={field.label}
                value={value && typeof value === 'string' ? new Date(value) : null}
                slotProps={{
                    field: { clearable: true },
                    textField: { variant: 'outlined', size: 'small' }
                }}
            />
        );
    }
    return element;
};

export default BSearchField;
