import React, { useEffect, useState } from 'react';
import moment from 'moment';
import dataService from '../../../Common/Services/dataService';
import { Box } from '@mui/material';
import './CampaignsList.scss';
import {
    Description,
    Language,
    Person,
    Ballot,
    CalendarToday,
    QueryStats,
    Grid3x3,
    Functions
} from '@mui/icons-material';
import DisplayStatus from './DisplayStatus';
import { CampaignListItem, CampaignsListProps } from './types';
import ExtendableChip from './ExtendableChip';
import DisplayActions from './DisplayActions';
import { useSearchCampaignsAndPurposesContext } from '../SearchCampaignsAndPurposesContext';
import BTable from '../../../Tools/Table/BTable';
import { useLocation, useNavigate } from 'react-router-dom';
import { BirdzNotif, useNotif } from '@applications-terrains/birdz-react-library';
import { NotifObjType } from '../../../Tools/NotifWithCountdown';

const FONT_SIZE = '0.9rem';

// const DisplayCampaign = ({ campaign }: { campaign: CampaignListItem }) => {
//     const operator = dataService.getOperator(campaign.operator);
//     return (
//         <Box
//             sx={{
//                 minHeight: '80px',
//                 maxWidth: '550px',
//                 display: 'flex',
//                 flexDirection: 'column',
//                 alignItems: 'start',
//                 justifyContent: 'center'
//             }}>
//             <div className="d-flex">
//                 <ExtendableChip
//                     label={campaign.name}
//                     title={'name'}
//                     icon={<Ballot color="primary" />}
//                     style={{
//                         position: 'relative',
//                         border: 'none',
//                         backgroundColor: 'white'
//                     }}
//                     maxNbOfChar={50}
//                 />
//                 {operator?.name && (
//                     <ExtendableChip
//                         label={operator?.name}
//                         title="Opérateur utilisé"
//                         icon={<Language style={{ fontSize: '16px', color: '#253053' }} />}
//                         maxNbOfChar={campaign.name.length > 40 ? 8 : 100}
//                         style={{
//                             marginLeft: '0.5rem',
//                             color: '#253053',
//                             border: '1px solid #253053',
//                             backgroundColor: 'white'
//                         }}
//                     />
//                 )}
//             </div>

//             <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '0.5rem' }}>
//                 {campaign.template_name && (
//                     <ExtendableChip
//                         label={campaign.template_name}
//                         title="Modèle utilisé"
//                         icon={<Description style={{ fontSize: '16px', color: '#616161' }} />}
//                         maxNbOfChar={50}
//                     />
//                 )}
//                 <Chip
//                     label={campaign.created_by__name}
//                     title="Créateur de la campagne"
//                     size="small"
//                     icon={<Person />}
//                     style={{ fontSize: FONT_SIZE, border: 'none' }}
//                     sx={{ ml: 1 }}
//                     variant="outlined"
//                 />
//             </Box>
//         </Box>
//     );
// };

export const CampaignsList = ({ archived = false }: CampaignsListProps) => {
    const [refresh, setRefresh] = useState<boolean>(false);
    const { campaignsFiltersString: filters } = useSearchCampaignsAndPurposesContext();
    const { search } = useLocation();
    const navigate = useNavigate();
    const { notif, notifOptions } = useNotif();

    const campaignActions = [
        {
            name: 'actions',
            render: (campaign: any) => {
                return <DisplayActions campaign={campaign} refreshComponent={refreshComponent} />;
            }
        }
    ];

    const refreshComponent = (notifObj: NotifObjType) => {
        setRefresh(true);
        notif(notifObj);
    };

    useEffect(() => {
        refresh && setRefresh(false);
    }, [refresh]);

    useEffect(() => {
        if (search.includes('&refresh=true')) {
            setRefresh(true);
            navigate('/sendings/sent-list?tab=campaigns');
        }
    }, [search, navigate]);

    return (
        <>
            <BTable
                refresh={refresh}
                model="campaigns"
                fields={[
                    // {
                    //     name: 'custom',
                    //     label: 'Campagne',
                    //     // options: { width: '100px' },
                    //     transform: (_: any, campaign: any) => {
                    //         return <DisplayCampaign campaign={campaign} />;
                    //     }
                    // },
                    {
                        name: 'id',
                        label: 'Id',
                        labelIcon: <Grid3x3 fontSize="small" />,
                        className: 'text-center',
                        options: { width: '50px' }
                    },
                    {
                        name: 'created_at',
                        label: 'Date',
                        labelIcon: <CalendarToday fontSize="small" />,
                        options: { width: '50px' },
                        transform: (value: any) => {
                            let date = value && moment.unix(value).format('DD/MM/YYYY à HH:mm:ss');
                            return date ? <Box style={{ fontSize: FONT_SIZE }}>{date}</Box> : '-';
                        },
                        orderable: true
                    },
                    {
                        name: 'name',
                        label: 'campagne',
                        labelIcon: <Ballot fontSize="small" />,
                        transform: (name?: string) => {
                            return name ? (
                                <ExtendableChip
                                    label={name}
                                    title={'nom de la campagne'}
                                    // icon={<Ballot color="primary" />}
                                    style={{
                                        position: 'relative',
                                        border: '1px solid rgba(0, 0, 0, 0.12)',
                                        backgroundColor: 'white'
                                    }}
                                    maxNbOfChar={25}
                                />
                            ) : (
                                '-'
                            );
                        }
                    },
                    {
                        name: 'template_name',
                        label: 'Template',
                        labelIcon: <Description fontSize="small" />,
                        transform: (template_name?: string) => {
                            return template_name ? (
                                <ExtendableChip
                                    label={template_name}
                                    title="Modèle utilisé"
                                    // icon={
                                    //     <Description
                                    //         style={{ fontSize: '16px', color: '#616161' }}
                                    //     />
                                    // }
                                    maxNbOfChar={25}
                                />
                            ) : (
                                '-'
                            );
                        }
                    },
                    {
                        name: 'created_by__name',
                        label: 'Créateur',
                        labelIcon: <Person fontSize="small" />
                    },
                    {
                        name: 'operator',
                        label: 'Opérateur',
                        labelIcon: <Language fontSize="small" />,
                        transform: (operatorNb: number) => {
                            const operator = dataService.getOperator(operatorNb);
                            return operator?.name || '-';
                        }
                    },

                    {
                        name: 'state',
                        label: 'Statut',
                        labelIcon: <QueryStats fontSize="small" />,
                        orderable: false,
                        transform: (_, campaign: CampaignListItem) => {
                            return <DisplayStatus campaign={campaign} />;
                        },
                        options: {
                            width: '180px'
                        }
                    },
                    {
                        name: 'total',
                        label: "Nombre total d'intentions",
                        labelIcon: <Functions fontSize="small" />,
                        shortLabel: 'iconOnly',
                        transform: (_, campaign: CampaignListItem) => {
                            return campaign.purposes_count || '-';
                        }
                    }
                ]}
                endpoint={
                    archived
                        ? '/api/campaigns/?archived=true'
                        : '/api/campaigns/' + filters.replace('&', '?')
                }
                actions={archived === false ? campaignActions : []}
            />
            <BirdzNotif options={notifOptions} />
        </>
    );
};

export default CampaignsList;
