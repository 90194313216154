import Home from './components/Home';
import Login from './components/Login/Login';
import DashboardLayout from './components/DashboardLayout';
import RawCampaign from './components/Campaigns/Raw/RawCampaign';
import CustomCampaign from './components/Campaigns/Custom/CustomCampaign';
import ConfigurationCampaign from './components/Campaigns/Configuration/ConfigurationCampaign';
import GutermannCampaign from './components/Campaigns/Gutermann/GutermannCampaign';
import BroadcastCampaign from './components/Campaigns/Broadcast/BroadcastCampaign';
import ClockSynchroCampaign from './components/Campaigns/ClockSynchro/ClockSynchroCampaign';
import AffiliationCampaign from './components/Campaigns/Affiliation/AffiliationCampaign';
import SentDashboard from './components/Sent/Dashboard/SentDashboard';
import SentList from './components/Sent/SentList';
import Protocol from './components/Models/Protocol';
import DeviceManufacturers from './components/Models/DeviceManufacturers';
import DeviceGroups from './components/Models/DeviceGroups';
import DeviceRanges from './components/Models/DeviceRanges';
import DeviceTypes from './components/Models/DeviceTypes';
import Contracts from './components/Admin/Contracts';
import Customers from './components/Admin/Customers';
import Messages from './components/Admin/Messages';
import Logs from './components/Admin/Logs';
import Webhooks from './components/Admin/Webhooks';
import Sections from './components/Admin/Sections';
import Groups from './components/Admin/Groups';
import Users from './components/Admin/Users';
import OperatorsForm from './components/Admin/OperatorsForm';
import Statuses from './components/Statuses/Statuses';
import ModelForm from './components/Models/ModelForm';
import Models from './components/Models/Models';
import Operators from './components/Admin/Operators';
// import Dashboard from './components/Racsup/Dashboard';
import DashboardDetails from './components/Racsup/DashboardDetails';
import ModuleExclusionList from './components/Racsup/ModuleExclusionList/ModuleExclusionList';
import ContractExclusionList from './components/Racsup/ContractExclusionList/ContractExclusionList';
import RacsupConfiguration from './components/Racsup/Configuration/Configuration';
import ConfigurationCr from './components/ConfigurationCr/ConfigurationCr';
import ConfigurationCrForm from './components/ConfigurationCr/ConfigurationCrForm';
import { Sandbox } from './components/Sandbox';
import { CallbackLogin } from '@applications-terrains/birdz-react-library';
import Optimisation from './components/Optimisation/Optimisation';
import Configuration from './components/Optimisation/Configuration';
import OptimisationContractExclusionList from './components/Optimisation/ContractExclusionList';
import CreatedOptimisations from './components/Optimisation/CreatedOptimisations';
import ArchivedCampaigns from './components/Sent/ArchivedCampaigns/ArchivedCampaigns';
import { TokenForm } from './components/Admin/Tokens/TokenContactForm';
import Tokens from './components/Admin/Tokens/Tokens';
import Campaigns from './components/Campaigns/Campaigns';
import AppContextProvider from './contexts/AppContextProvider';
import SpreadSendingsDashboard from './components/SpreadSendings/SpreadSendingsDashboard';
import ConcentratorStatuses from './components/SpreadSendings/ConcentratorStatuses';
import HrSendings from './components/SpreadSendings/HrSendings';
import CampaignDetails from './components/Sent/Lists/Campaigns/CampaignDetails';
import Sendings from './components/Sent/Sendings';
import Racsup from './components/Racsup/Racsup';
import { Outlet } from 'react-router-dom';
import ContractsSelection from './components/Racsup/Link/ContratsSelection';
import LinksFollowUp from './components/Racsup/Link/LinksFollowUp';

interface RouteObjectProps {
    path: string;
    element?: any;
    label?: string;
    permissions?: string[];
    children?: RouteObjectProps[];
}

const routes: RouteObjectProps[] = [
    {
        path: '/callback',
        element: <CallbackLogin />
    },
    {
        path: '/auth',
        element: <Login />
    },
    {
        path: '/',
        element: (
            <DashboardLayout
                component={
                    <AppContextProvider>
                        <Outlet />
                    </AppContextProvider>
                }
            />
        ),
        children: [
            {
                path: '',
                element: <Home />,
                label: 'Accueil'
            },
            {
                path: 'campaigns',
                element: <Campaigns />,
                label: 'Campagnes',
                children: [
                    {
                        path: 'affiliation',
                        element: <AffiliationCampaign />,
                        label: 'Affiliation',
                        permissions: ['PURPOSE_AFFILIATION']
                    },
                    {
                        path: 'clock_synchro',
                        element: <ClockSynchroCampaign />,
                        label: "Synchro d'horloge",
                        permissions: ['PURPOSE_CLOCK_SYNCHRO']
                    },
                    {
                        path: 'gutermann',
                        element: <GutermannCampaign />,
                        label: 'Gutermann',
                        permissions: ['PURPOSE_GUTERMANN']
                    },
                    {
                        path: 'configuration',
                        element: <ConfigurationCampaign />,
                        label: 'Configuration',
                        permissions: ['PURPOSE_CONFIGURATION']
                    },
                    {
                        path: 'custom',
                        element: <CustomCampaign />,
                        label: 'Custom',
                        permissions: ['PURPOSE_CUSTOM']
                    },
                    {
                        path: 'raw',
                        element: <RawCampaign />,
                        label: 'Raw',
                        permissions: ['PURPOSE_RAW']
                    },
                    {
                        path: 'broadcast',
                        element: <BroadcastCampaign />,
                        label: 'Broadcast',
                        permissions: ['PURPOSE_BROADCAST']
                    }
                ]
            },
            {
                path: 'sendings',
                label: 'Envois',
                element: <Sendings />,
                permissions: ['SENTLIST_MENU'],
                children: [
                    {
                        path: 'sent-list',
                        element: <SentList />,
                        label: 'Listes',
                        permissions: ['SENTLIST_MENU']
                    },
                    {
                        path: 'sent-list/campaign-details/:id',
                        element: <CampaignDetails />,
                        label: 'Détails de la campagne',
                        permissions: ['SENTLIST_MENU']
                    },
                    {
                        path: 'archived-list',
                        element: <ArchivedCampaigns />,
                        label: 'Campagnes archivées'
                    },
                    {
                        path: 'sent-dashboard',
                        element: <SentDashboard />,
                        label: 'Dashboard envois'
                    }
                ]
            },
            {
                path: 'spread-sendings',
                element: <SpreadSendingsDashboard />,
                label: 'Envois étalés',
                permissions: ['SPREAD_SENDINGS_MENU'],
                children: [
                    {
                        path: 'concentrator-statuses',
                        element: <ConcentratorStatuses />,
                        label: 'Statuts des concentrateurs'
                    },
                    {
                        path: 'hr-sendings',
                        element: <HrSendings />,
                        label: 'Envois HR'
                    }
                ]
            },
            {
                path: 'models',
                label: 'Modèles',
                children: [
                    {
                        path: 'parameters',
                        label: 'Paramètres',
                        children: [
                            {
                                path: 'device-types',
                                children: [
                                    {
                                        path: '',
                                        element: <DeviceTypes action="list" />,
                                        label: 'Device types'
                                    },
                                    {
                                        path: 'add',
                                        element: <DeviceTypes action="add" />,
                                        label: 'Ajouter'
                                    },
                                    {
                                        path: 'edit/:id',
                                        element: <DeviceTypes action="edit" />,
                                        label: 'Modifier'
                                    }
                                ]
                            },
                            {
                                path: 'device-ranges',
                                children: [
                                    {
                                        path: '',
                                        element: <DeviceRanges action="list" />,
                                        label: 'Device ranges'
                                    },
                                    {
                                        path: 'add',
                                        element: <DeviceRanges action="add" />,
                                        label: 'Ajouter'
                                    },
                                    {
                                        path: 'edit/:id',
                                        element: <DeviceRanges action="edit" />,
                                        label: 'Modifier'
                                    },
                                    {
                                        path: 'duplicate/:id',
                                        element: <DeviceRanges action="duplicate" />,
                                        label: 'Dupliquer'
                                    }
                                ]
                            },
                            {
                                path: 'device-groups',
                                children: [
                                    {
                                        path: '',
                                        element: <DeviceGroups action="list" />,
                                        label: 'Device groups'
                                    },
                                    {
                                        path: 'add',
                                        element: <DeviceGroups action="add" />,
                                        label: 'Ajouter'
                                    },
                                    {
                                        path: 'edit/:id',
                                        element: <DeviceGroups action="edit" />,
                                        label: 'Modifier'
                                    }
                                ]
                            },
                            {
                                path: 'device-manufacturers',
                                children: [
                                    {
                                        path: '',
                                        element: <DeviceManufacturers action="list" />,
                                        label: 'Device manufacturers'
                                    },
                                    {
                                        path: 'add',
                                        element: <DeviceManufacturers action="add" />,
                                        label: 'Ajouter'
                                    },
                                    {
                                        path: 'edit/:id',
                                        element: <DeviceManufacturers action="edit" />,
                                        label: 'Modifier'
                                    }
                                ]
                            },
                            {
                                path: 'protocols',
                                children: [
                                    {
                                        path: '',
                                        element: <Protocol action="list" />,
                                        label: 'Protocoles'
                                    },
                                    {
                                        path: 'add',
                                        element: <Protocol action="add" />,
                                        label: 'Ajouter'
                                    },
                                    {
                                        path: 'edit/:id',
                                        element: <Protocol action="edit" />,
                                        label: 'Modifier'
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: 'list/add',
                        label: 'Ajouter',
                        element: <ModelForm action="add" />
                    },
                    {
                        path: 'list/edit/:id',
                        label: 'Modifier',
                        element: <ModelForm action="edit" />
                    },
                    {
                        path: 'list',
                        label: 'Liste',
                        element: <Models />
                    }
                ],
                permissions: ['TEMPLATE_MENU']
            },
            {
                path: 'statuses',
                element: <Statuses />,
                label: 'Statut des bases'
            },
            {
                path: 'admin',
                label: 'Administration',
                children: [
                    {
                        path: 'users',
                        label: 'Utilisateurs',
                        children: [
                            {
                                path: '',
                                label: 'Liste',
                                element: <Users action="list" />
                            },
                            {
                                path: 'add',
                                element: <Users action="add" />,
                                label: 'Ajouter'
                            },
                            {
                                path: 'edit/:id',
                                element: <Users action="edit" />,
                                label: 'Modifier'
                            }
                        ]
                    },
                    {
                        path: 'groups',
                        label: 'Groupes',
                        children: [
                            {
                                path: '',
                                label: 'Liste',
                                element: <Groups action="list" />
                            },
                            {
                                path: 'add',
                                element: <Groups action="add" />,
                                label: 'Ajouter'
                            },
                            {
                                path: 'edit/:id',
                                element: <Groups action="edit" />,
                                label: 'Modifier'
                            }
                        ]
                    },
                    {
                        path: 'sections',
                        label: 'Sections',
                        children: [
                            {
                                path: '',
                                label: 'Liste',
                                element: <Sections action="list" />
                            },
                            {
                                path: 'add',
                                element: <Sections action="add" />,
                                label: 'Ajouter'
                            },
                            {
                                path: 'edit/:id',
                                element: <Sections action="edit" />,
                                label: 'Modifier'
                            }
                        ]
                    },
                    {
                        path: 'webhooks',
                        label: 'Webhook',
                        children: [
                            {
                                path: '',
                                label: 'Liste',
                                element: <Webhooks action="list" />
                            },
                            {
                                path: 'add',
                                element: <Webhooks action="add" />,
                                label: 'Ajouter'
                            },
                            {
                                path: 'edit/:id',
                                element: <Webhooks action="edit" />,
                                label: 'Modifier'
                            }
                        ]
                    },
                    {
                        path: 'logs',
                        label: 'Logs',
                        children: [
                            {
                                path: '',
                                label: 'Liste',
                                element: <Logs action="list" />
                            },
                            {
                                path: 'details/:id',
                                element: <Logs action="details" />,
                                label: 'Détail'
                            }
                        ]
                    },
                    {
                        path: 'messages',
                        label: 'Messages',
                        children: [
                            {
                                path: '',
                                label: 'Liste',
                                element: <Messages action="list" />
                            },
                            {
                                path: 'add',
                                element: <Messages action="add" />,
                                label: 'Ajouter'
                            },
                            {
                                path: 'edit/:id',
                                element: <Messages action="edit" />,
                                label: 'Modifier'
                            }
                        ]
                    },
                    {
                        path: 'tokens',
                        label: 'Tokens',
                        children: [
                            {
                                path: '',
                                label: 'Liste',
                                element: <Tokens />
                            },
                            {
                                path: 'add',
                                element: <TokenForm action="add" />,
                                label: 'Ajouter'
                            },
                            {
                                path: 'edit/:id',
                                element: <TokenForm action="edit" />,
                                label: 'Modifier'
                            }
                        ],
                        permissions: ['TOKEN_MENU']
                    },
                    {
                        path: 'customers',
                        label: 'Clients',
                        element: <Customers />
                    },
                    {
                        path: 'contracts',
                        label: 'Contrats',
                        element: <Contracts />
                    },
                    {
                        path: 'operators',
                        label: 'Opérateurs',
                        children: [
                            {
                                path: '',
                                element: <Operators />,
                                label: 'Liste'
                            },
                            {
                                path: 'add',
                                element: <OperatorsForm action="add" />,
                                label: 'Ajouter'
                            },
                            {
                                path: 'edit/:id',
                                element: <OperatorsForm action="edit" />,
                                label: 'Modifier'
                            }
                        ]
                    }
                ],
                permissions: ['ADMIN_MENU']
            },
            {
                path: 'configuration-cr',
                label: 'Configuration CR',
                children: [
                    {
                        path: '',
                        element: <ConfigurationCr />,
                        label: 'Liste des configurations CR'
                    },
                    {
                        path: 'new-config',
                        element: <ConfigurationCrForm />,
                        label: 'Créer une nouvelle configuration'
                    }
                ],
                permissions: ['CONFIG_CR_MENU']
            },
            {
                path: 'racsup',
                label: 'RACSUP',
                element: <Racsup />,
                children: [
                    // {
                    //     path: '',
                    //     element: <Dashboard />,
                    //     label: 'RACSUP'
                    // },
                    {
                        path: 'primo/list',
                        element: <ContractsSelection type={'primo'} />,
                        label: 'Primo-raccordement'
                    },
                    {
                        path: 'primo/list/create/:contractsParam',
                        element: <ContractsSelection type={'primo'} />,
                        label: 'Création de chantiers primo-raccordement'
                    },
                    {
                        path: 'primo/follow-up',
                        element: <LinksFollowUp type="primo" />,
                        label: 'Suivi des chantiers primo-raccordement'
                    },
                    {
                        path: 'change/list',
                        element: <ContractsSelection type={'rerac'} />,
                        label: 'Re-raccordement'
                    },
                    {
                        path: 'change/list/create/:contractsParam',
                        element: <ContractsSelection type={'rerac'} />,
                        label: 'Création de chantiers re-raccordement'
                    },
                    {
                        path: 'change/follow-up',
                        element: <LinksFollowUp type="rerac" />,
                        label: 'Suivi des chantiers re-raccordement'
                    },
                    {
                        path: 'append/list',
                        element: <ContractsSelection type={'renfo'} />,
                        label: 'Renforcement'
                    },
                    {
                        path: 'append/list/create/:contractsParam',
                        element: <ContractsSelection type={'renfo'} />,
                        label: 'Création de chantiers renforcement'
                    },
                    {
                        path: 'append/follow-up',
                        element: <LinksFollowUp type="renfo" />,
                        label: 'Suivi des chantiers renforcement'
                    },
                    {
                        path: 'delete/list',
                        element: <ContractsSelection type={'net'} />,
                        label: 'Nettoyages'
                    },
                    {
                        path: 'delete/list/create/:contractsParam',
                        element: <ContractsSelection type={'net'} />,
                        label: 'Création de chantiers nettoyage'
                    },
                    {
                        path: 'delete/follow-up',
                        element: <LinksFollowUp type="net" />,
                        label: 'Suivi des chantiers nettoyage'
                    },
                    {
                        path: 'evicted',
                        element: <LinksFollowUp type="evict" />,
                        label: 'Suvi des évictions'
                    },
                    {
                        path: 'evicted/modules-exclusion-list',
                        element: <ModuleExclusionList />,
                        label: 'Exclusion de modules'
                    },
                    {
                        path: 'configuration/add',
                        element: <RacsupConfiguration action="add" />,
                        label: 'Ajouter une configuration'
                    },
                    {
                        path: 'configuration/edit/:id',
                        element: <RacsupConfiguration action="edit" />,
                        label: 'Modifier la configuration'
                    },
                    {
                        path: 'configuration',
                        element: <RacsupConfiguration action="list" />,
                        label: 'Configuration instructions'
                    },
                    {
                        path: 'contract-exclusion-list',
                        element: <ContractExclusionList />,
                        label: 'Ajouter une exclusion'
                    },

                    {
                        path: 'dashboard-details',
                        element: <DashboardDetails />,
                        label: 'Dashboard détail'
                    }
                ],
                permissions: ['RACSUP_MENU']
            },
            {
                path: 'optimisation',
                label: 'Optimisation',
                children: [
                    {
                        path: 'list',
                        element: <Optimisation />,
                        label: "liste des chantiers d'optimisation"
                    },
                    {
                        path: 'list/create/:contractsParam',
                        element: <Optimisation />,
                        label: "Créer un chantier d'optimisation"
                    },
                    {
                        path: 'follow-up',
                        element: <CreatedOptimisations />,
                        label: "Suivi des chantiers d'optimisation"
                    },
                    {
                        path: 'configuration/add',
                        element: <Configuration action="add" />,
                        label: 'Ajouter une configuration'
                    },
                    {
                        path: 'configuration/edit/:id',
                        element: <Configuration action="edit" />,
                        label: 'Modifier la configuration'
                    },
                    {
                        path: 'configuration',
                        element: <Configuration action="list" />,
                        label: 'Configuration instructions'
                    },
                    {
                        path: 'contract-exclusion-list',
                        element: <OptimisationContractExclusionList />,
                        label: 'Ajouter une exclusion'
                    }
                ],
                permissions: ['OPTIMISATION_MENU']
            }
        ]
    },
    {
        path: '/sandbox',
        element: <DashboardLayout component={<Sandbox />} />
    }
];

export default routes;
