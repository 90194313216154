import { StyledTitle } from '@applications-terrains/birdz-react-library';
import React from 'react';
import moment from 'moment';
import dataService from '../../Common/Services/dataService';
import ChartSuccessFail from '../Chart/ChartSuccessFail';
import ChartFailedInstructions from '../Chart/ChartFailedInstructions';
import { ninetyDaysAgo } from '../Chart/ChartUtils';
import { Operator } from '../../types';
import { useNavigate } from 'react-router-dom';
import { PiFileXls } from 'react-icons/pi';
import { Search } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import PagePaper from '../../Tools/Pages/PagePaper';
import BTable from '../../Tools/Table/BTable';
import { RacsupTypes } from './types';
import { contractsConfig } from './config';

export const LinksFollowUp = ({ type }: { type: RacsupTypes | 'evict' }) => {
    const navigate = useNavigate();
    const target = type.toLocaleUpperCase();

    const config = { ...contractsConfig, evict: {
        label: "d'éviction",
    } };

    return (
        <PagePaper
            title={
                <StyledTitle className="m-0">
                    {`Statut des commandes ${config[type].label}(s) sur les 90 derniers jours`}
                </StyledTitle>
            }>
            <Box
                sx={{
                    mb: 2,
                    border: '1px solid',
                    borderColor: 'divider',
                    borderRadius: '4px',
                    p: 2
                }}>
                <h5 className="text-center">Évolution des succès et des échecs</h5>
                <ChartSuccessFail type={target} height={150} />
            </Box>
            <Box
                sx={{
                    mb: 2,
                    border: '1px solid',
                    borderColor: 'divider',
                    borderRadius: '4px',
                    p: 2
                }}>
                <h5 className="text-center">Répartition des statuts en échec</h5>
                <ChartFailedInstructions type={target} height={150} />
            </Box>

            <BTable
                model={`racsup-instructions-${target}`}
                endpoint={
                    `/api/racsup/instructions/?type=${target}&created_at_after=` + ninetyDaysAgo
                }
                fields={[
                    { name: 'contract', label: 'Contrat', orderable: true },
                    { name: 'name', label: 'Chantier', orderable: true },
                    {
                        name: 'operator',
                        label: 'Opérateur',
                        transform: (value: any) => {
                            let operator = (dataService.getData('operators') as Operator[]).find(
                                (operator: any) => operator.id === value
                            );
                            return operator?.name || value;
                        }
                    },
                    {
                        name: 'scheduled_date',
                        label: 'Date prévue',
                        orderable: true,
                        transform: (value: any) => {
                            let date = value && moment(value).format('DD/MM/YYYY à HH:mm:ss');
                            return date || '';
                        }
                    },
                    { name: 'description', label: 'Description', orderable: true }
                ]}
                exportButtons={[
                    {
                        name: 'Exporter en xlsx',
                        fileName: `Suivi des commandes ${config[type].label}(s).xlsx`,
                        type: 'xlsx',
                        icon: <PiFileXls />,
                        id: `${target}-export-button`
                    }
                ]}
                pageSize={100}
                actions={[
                    {
                        name: 'see-campaign',
                        label: 'Récap des envois',
                        render: (value: any) => (
                            <IconButton
                                id={`${target}-see-button`}
                                onClick={() => {
                                    navigate('/sendings/sent-list/campaign-details/' + value.campaign);
                                }}>
                                <Search fontSize="small" />
                            </IconButton>
                        ),
                        isVisible: (value: any) => {
                            return value?.campaign;
                        }
                    }
                ]}
            />
        </PagePaper>
    );
};

export default LinksFollowUp;
