import React from 'react';
import moment from 'moment';
import BTable from '../../Tools/Table/BTable';
import PagePaper from '../../Tools/Pages/PagePaper';
import { StyledTitle } from '@applications-terrains/birdz-react-library';

export const ModuleExclusionList = () => {
    return (
        <PagePaper title={<StyledTitle className="m-0">
            Liste des exclusions de modules
        </StyledTitle>}>
            <BTable
                model={'racsup-exclusions-list'}
                endpoint="/api/racsup/module-exclusions/"
                fields={[
                    {
                        name: 'created_at',
                        label: 'Date de création',
                        orderable: true,
                        transform: (value: any) => {
                            let date = value && moment.unix(value).format('DD/MM/YYYY à HH:mm:ss');
                            return date || '';
                        }
                    },
                    { name: 'module_address', label: 'Module', orderable: true },
                    {
                        name: 'repeater',
                        label: 'Répéteur (lu à partir de la commande)',
                        orderable: true
                    }
                ]}
            />
        </PagePaper>
    );
};

export default ModuleExclusionList;
