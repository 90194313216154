import React, { useEffect, useState } from 'react';
import { authService } from '../..';
import { AutoFixHigh, SvgIconComponent } from '@mui/icons-material';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import SendIcon from '@mui/icons-material/Send';
import DescriptionIcon from '@mui/icons-material/Description';
import PowerIcon from '@mui/icons-material/Power';
import SettingsIcon from '@mui/icons-material/Settings';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import BuildIcon from '@mui/icons-material/Build';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';

export type SidebarItemType = {
    key: string;
    title: string;
    icon: SvgIconComponent | null;
    children?: SidebarItemType[];
    activeRoutes?: string[];
};

const useGetSidebarItems = () => {
    const [sidebarItems, setSidebarItems] = useState<SidebarItemType[]>([]);

    useEffect(() => {
        if (authService) {
            const items: SidebarItemType[] = [
                authService.canAccess('PURPOSE_MENU') && {
                    key: '/campaigns',
                    icon: <UnarchiveIcon fontSize="small" />,
                    title: 'Intentions',
                    children: [
                        authService.canAccess('PURPOSE_CONFIGURATION') && {
                            key: '/configuration',
                            title: 'Configuration'
                        },
                        authService.canAccess('PURPOSE_AFFILIATION') && {
                            key: '/affiliation',
                            title: 'Affiliation'
                        },
                        authService.canAccess('PURPOSE_CLOCK_SYNCHRO') && {
                            key: '/clock_synchro',
                            title: 'Synchro horloge'
                        },
                        authService.canAccess('PURPOSE_GUTERMANN') && {
                            key: '/gutermann',
                            title: 'Gutermann'
                        },
                        authService.canAccess('PURPOSE_CUSTOM') && {
                            key: '/custom',
                            title: 'Custom'
                        },
                        authService.canAccess('PURPOSE_BROADCAST') && {
                            key: '/broadcast',
                            title: 'Broadcast'
                        },
                        authService.canAccess('PURPOSE_RAW') && {
                            key: '/raw',
                            title: 'Raw'
                        }
                    ]
                },
                authService.canAccess('SENTLIST_MENU') && {
                    key: '/sendings',
                    title: 'Envois',
                    icon: <SendIcon fontSize="small" />,
                    children: [
                        {
                            key: '/sent-dashboard',
                            title: 'Tableau de bord'
                        },
                        {
                            key: '/sent-list',
                            title: 'Récapitulatif',
                            activeRoutes: [
                                '/sent-list?tab=campaigns',
                                '/sent-list?tab=purposes',
                                '/campaign-details'
                            ]
                        },
                        {
                            key: '/archived-list',
                            title: 'Archivés'
                        }
                    ]
                },
                {
                    key: '/spread-sendings',
                    icon: <ScheduleSendIcon fontSize="small" />,
                    title: 'Envois étalés',
                    children: [
                        {
                            key: '/concentrator-statuses',
                            title: 'Statuts des concentrateurs'
                        },
                        {
                            key: '/hr-sendings',
                            title: 'Envois HR'
                        }
                    ]
                },
                authService.canAccess('TEMPLATE_MENU') && {
                    key: '/models',
                    title: 'Modèles',
                    icon: <DescriptionIcon fontSize="small" />,
                    children: authService.canAccess('TEMPLATE_PARAMETERS_MENU') && [
                        {
                            key: '/list',
                            title: 'Liste'
                        },
                        {
                            title: 'Paramètres',
                            key: '/parameters',
                            children: [
                                {
                                    key: '/device-types',
                                    title: 'Device types'
                                },
                                {
                                    key: '/device-ranges',
                                    title: 'Device ranges'
                                },
                                {
                                    key: '/device-groups',
                                    title: 'Device groups'
                                },
                                {
                                    key: '/protocols',
                                    title: 'Protocoles'
                                },
                                {
                                    key: '/device-manufacturers',
                                    title: 'Device manufacturers'
                                }
                            ]
                        }
                    ]
                },
                authService.canAccess('OPTIMISATION_MENU') && {
                    title: 'Optimisation',
                    key: '/optimisation',
                    icon: <AutoFixHigh fontSize="small" />,
                    children: [
                        {
                            key: '/list',
                            title: 'Paramétrer'
                        },
                        {
                            key: '/follow-up/',
                            title: 'Suivi des chantiers'
                        },
                        {
                            key: '/configuration',
                            title: 'Configuration'
                        },
                        {
                            key: '/contract-exclusion-list',
                            title: 'Gestion des exclusions de contrat'
                        }
                    ]
                },
                authService.canAccess('RACSUP_MENU') && {
                    icon: <PowerIcon fontSize="small" />,
                    title: 'RACSUP',
                    key: '/racsup',
                    children: [
                        {
                            key: '/primo',
                            title: 'Primo raccordement',
                            children: [
                                {
                                    key: '/list',
                                    title: 'Paramétrer'
                                },
                                {
                                    key: '/follow-up/',
                                    title: 'Suivi'
                                }
                            ]
                        },
                        {
                            key: '/change',
                            title: 'Re-raccordement',
                            children: [
                                {
                                    key: '/list',
                                    title: 'Paramétrer'
                                },
                                {
                                    key: '/follow-up/',
                                    title: 'Suivi'
                                }
                            ]
                        },
                        {
                            key: '/append',
                            title: 'Renforcement',
                            children: [
                                {
                                    key: '/list',
                                    title: 'Paramétrer'
                                },
                                {
                                    key: '/follow-up/',
                                    title: 'Suivi'
                                }
                            ]
                        },
                        {
                            key: '/delete',
                            title: 'Nettoyage',
                            children: [
                                {
                                    key: '/list',
                                    title: 'Paramétrer'
                                },
                                {
                                    key: '/follow-up/',
                                    title: 'Suivi'
                                }
                            ]
                        },
                        {
                            key: '/evicted',
                            title: 'Evictions',
                            children: [
                                {
                                    key: '/',
                                    title: 'Suivi'
                                },
                                {
                                    key: '/modules-exclusion-list',
                                    title: 'Liste des exclusions de module'
                                }
                            ]
                        },
                        {
                            key: '/configuration',
                            title: 'Configuration'
                        },
                        {
                            key: '/contract-exclusion-list',
                            title: 'Gestion des exclusions de contrat'
                        }
                    ]
                },
                authService.canAccess('CONFIG_CR_MENU') && {
                    key: '/configuration-cr',
                    title: 'Configuration CR',
                    icon: <BuildIcon fontSize="small" />
                },
                {
                    icon: <MonitorHeartIcon fontSize="small" />,
                    title: 'Statuts bases',
                    key: '/statuses'
                },
                authService.canAccess('ADMIN_MENU') && {
                    icon: <SettingsIcon fontSize="small" />,
                    key: '/admin',
                    title: 'Administration',
                    children: [
                        {
                            key: '/users',
                            title: 'Utilisateurs'
                        },
                        {
                            key: '/groups',
                            title: 'Groupes'
                        },
                        {
                            key: '/sections',
                            title: 'Sections'
                        },
                        {
                            key: '/webhooks',
                            title: 'Webhooks'
                        },
                        {
                            key: '/customers',
                            title: 'Clients'
                        },
                        {
                            key: '/contracts',
                            title: 'Contrats'
                        },
                        authService.canAccess('OPERATOR_MENU') && {
                            key: '/operators',
                            title: 'Opérateurs'
                        },
                        {
                            key: '/messages',
                            title: 'Messages'
                        },
                        {
                            key: '/logs',
                            title: 'Logs'
                        },
                        authService.canAccess('TOKEN_MENU') && {
                            key: '/tokens',
                            title: 'Tokens'
                        }
                    ]
                }
            ];

            setSidebarItems(items);
        }
    }, []);

    return sidebarItems;
};

export default useGetSidebarItems;
