import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import Select from 'react-select';
import dataService from '../Common/Services/dataService';
import axios from 'axios';
import { Formik, Form } from 'formik';
import { useParams, useNavigate } from 'react-router-dom';
import './ModelForm.scss';
import moment from 'moment';
import * as Yup from 'yup';
import Parameters from '../Parameters/Parameters';
import { CampaignHeaderConfig } from '../Campaigns/CampaignHeaderConfig';
import { tooltipTechno, tooltipOperator, modelTypes } from './ModelFormHelpers';
import {
    AppPaper,
    AutocompleteField,
    BirdzDialog,
    BirdzNotif,
    StyledTableBodyRow,
    StyledTableHeaderRow,
    useDialog,
    useNotif
} from '@applications-terrains/birdz-react-library';
import { authService } from '../..';
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    Paper,
    Radio,
    RadioGroup,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import Tabs from '@mui/material/Tabs';
import HelpIcon from '@mui/icons-material/Help';
import useEncoderService from '../Common/Services/useEncoderService';
import { formatErrorInNotif } from '../../utils';
import { styled } from '@mui/material/styles';
import LightTooltip from '../Tools/LightTooltip';
import { useSafeTimeout } from '../../hooks/useSafeTimeout';

type ModelFormParams = {
    id: string;
};

type ModelFormProps = {
    action: string;
};

const ModelForm = ({ action }: ModelFormProps) => {
    const endpoint = '/api/frame-templates/';
    const { id } = useParams<ModelFormParams>();
    const { encoderService } = useEncoderService();
    const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
    const [model, setModel] = useState<any>({});
    const [showForm, setShowForm] = useState<boolean>(true);
    const [valueTab, setValueTab] = React.useState(0);
    const [errorsState, setErrorsState] = React.useState<{
        [key: number]: boolean;
    }>({});

    const { notif, notifOptions } = useNotif();
    const { confirmDialog, closeDialog, dialogOptions } = useDialog();

    const handleChangeTab = (event: any, newValueTab: number) => {
        setValueTab(newValueTab);
    };

    const navigate = useNavigate();

    const onLoad = (data: any) => {
        const groups = dataService
            .getData('groups')
            .filter((group: any) => data.groups.includes(group.id))
            .map((group: any) => {
                return { value: group.id, label: group.name };
            });

        const deviceTypes = data.device_types?.map((device_type: number) => {
            const deviceTypeFromDataservice = dataService
                .getData('deviceTypes')
                .filter((deviceType: any) => deviceType.id === device_type)[0];
            return {
                label: deviceTypeFromDataservice.name,
                value: deviceTypeFromDataservice.id
            };
        });

        let protocol = dataService
            .getData('protocols')
            .find((protocol: any) => protocol.id === data.protocol);
        protocol = protocol ? { value: protocol.id, label: protocol.name } : null;

        let combinedTemplateHR = null;
        let combinedTemplateLRW = null;
        if (data.combined_template_lrw || data.combined_template_lrw) {
            const templates = dataService.getData('frameTemplates');
            const getTemplateObj = (id: number) => {
                const template = templates.find((frameTemplate: any) => frameTemplate.id === id);
                return template ? { value: template.id, label: template.name } : null;
            };
            if (data.combined_template_lrw) {
                combinedTemplateLRW = getTemplateObj(data.combined_template_lrw);
            }
            if (data.combined_template_hr) {
                combinedTemplateHR = getTemplateObj(data.combined_template_hr);
            }
        }

        const getFormattedPayload = () => {
            const arrToObj = (arr: any[]) =>
                arr.reduce((acc, obj) => {
                    return { ...acc, ...obj };
                }, {});

            return arrToObj(
                Object.entries(data.payload_clear)
                    .map(([codecId, codecProperties]) => {
                        const codecName = data.codecs_path[codecId];
                        const properties = codecProperties as { [key: string]: unknown };
                        if (
                            encoderService?.getEncoderSpecsPaths()?.[codecName]?.properties &&
                            properties
                        ) {
                            type InputObject = {
                                [key: string]: any;
                            };

                            function flattenProperties(
                                property: InputObject,
                                templateValue: string | number | null | InputObject
                            ) {
                                if (
                                    property.hasOwnProperty('properties') &&
                                    typeof property.properties === 'object'
                                ) {
                                    const flatResult: InputObject = {};
                                    for (const key in property.properties) {
                                        const value = property.properties[key];

                                        // Si une sous-propriété possède également des "properties", on continue la récursion
                                        if (
                                            value.hasOwnProperty('properties') &&
                                            typeof value.properties === 'object'
                                        ) {
                                            flatResult[key] = flattenProperties(
                                                value,
                                                (templateValue as InputObject)?.[key] || null
                                            );
                                        } else {
                                            // Sinon, on attribue la bonne valeur issue de templateValue ou null si non définie
                                            flatResult[key] =
                                                (templateValue as InputObject)?.[key] ?? null;
                                        }
                                    }
                                    return flatResult;
                                }
                                // Si la propriété ne contient pas de clé "properties", retourner templateValue directement
                                return templateValue ?? null;
                            }

                            const formattedCodecProperties = arrToObj(
                                Object.entries(
                                    encoderService?.getEncoderSpecsPaths()?.[codecName]?.properties
                                ).map(([key, encoderConfig]) => {
                                    const value = flattenProperties(
                                        encoderConfig,
                                        properties[key] ?? null
                                    );
                                    return {
                                        [key]: value ?? null
                                    };
                                })
                            );

                            return {
                                [codecId]: formattedCodecProperties
                            };
                        }
                        return null;
                    })
                    .filter(Boolean)
            );
        };

        const formattedPayload = getFormattedPayload();

        const newModel = {
            id: data?.id || null,
            name: data.name || '',
            comment: data.comment || '',
            deviceTypes,
            protocol,
            codecs_path: data.codecs_path,
            codecs_path_ack: data.codecs_path_ack,
            groups,
            type: modelTypes.find((modelType: any) => modelType.value === data.type),
            payload: formattedPayload,
            is_active: data.is_active,
            isTechnologyChange: data.is_technology_change,
            technologyChangeOnTimeout: data.technology_change_on_timeout,
            isOperatorChange: data.is_operator_change,
            operatorChangeOnTimeout: data.operator_change_on_timeout,
            max_days: data?.max_days,
            new_technology: data.new_technology || '',
            new_operator: data.new_operator || '',
            port: data?.port,
            isMultiAntenna: data.is_multi_antenna,
            isSequenceNumberCollisionSuccess: data.is_sequence_number_collision_success,
            header_config: data?.header_config,
            combined_template_hr: combinedTemplateHR,
            combined_template_lrw: combinedTemplateLRW,
            updated_at: data?.updated_at
        };

        setModel(newModel);
    };

    useEffect(() => {
        if (id) {
            axios.get(endpoint + id + '/').then((response) => {
                onLoad(response.data);
            });
        }
        //eslint-disable-next-line
    }, [id]);

    const setSafeTimeout = useSafeTimeout();

    const saveModel = (formValues: any) => {
        if (saveInProgress) {
            return;
        }

        const model: any = {
            codecs_version: encoderService?.getCommit() || null,
            name: formValues['name'],
            comment: formValues['comment'],
            device_types:
                (formValues['deviceTypes'] &&
                    formValues['deviceTypes']?.map((group: any) => {
                        return group.value;
                    })) ||
                [],
            codecs_path: formValues['codecs_path'] || null,
            codecs_path_ack: formValues['codecs_path_ack'] || null,
            groups:
                (formValues['groups'] &&
                    formValues['groups']?.map((group: any) => {
                        return group.value;
                    })) ||
                [],

            type: formValues['type'].value,
            is_technology_change: formValues['isTechnologyChange'],
            technology_change_on_timeout: formValues['technologyChangeOnTimeout'],
            is_operator_change: formValues['isOperatorChange'],
            operator_change_on_timeout: formValues['operatorChangeOnTimeout'],
            protocol: (formValues['protocol'] && formValues['protocol'].value) || null,
            payload_clear: formValues['payload'],
            is_active: formValues['is_active'],
            port: formValues['port'] || undefined,
            max_days: formValues['max_days'] || undefined,
            is_multi_antenna: formValues['isMultiAntenna'],
            is_sequence_number_collision_success: formValues['isSequenceNumberCollisionSuccess'],
            header_config: formValues['header_config'],
            combined_template_lrw:
                (formValues['combined_template_lrw'] &&
                    formValues['combined_template_lrw']?.value) ||
                null,
            combined_template_hr:
                (formValues['combined_template_hr'] && formValues['combined_template_hr']?.value) ||
                null,
            updated_at: formValues['updated_at'] || undefined
        };

        // Manage header config field
        if (!model.header_config) {
            const headerConfigField = encoderService?.getHeaderConfigField();
            if (headerConfigField) {
                if (model.payload_clear[headerConfigField]) {
                    model.header_config = { ...model.payload_clear[headerConfigField] };
                    delete model.payload_clear[headerConfigField];
                }
            }
        }

        if (model.is_technology_change) {
            model.new_technology = formValues['new_technology'];
        }

        if (model.is_operator_change) {
            model.new_operator = formValues['new_operator'];
        }

        setSaveInProgress(true);
        axios({
            url: id ? endpoint + id + '/' : endpoint,
            method: id ? 'PUT' : 'POST',
            data: model
        })
            .then(
                (response) => {
                    const action = id ? 'modifiée' : 'créée';

                    notif({
                        content: 'La fiche modèle a été ' + action + ' avec succès!',
                        type: 'success'
                    });
                    onLoad(response.data);

                    setSafeTimeout(() => {
                        navigate('/models');
                    }, 3000);
                },
                (error: any) => {
                    const errors = error?.response?.data;
                    const errorMessages: string[] = [];
                    if (errors) {
                        Object.keys(errors).forEach((errorField: string) => {
                            errorMessages.push(`${errorField} : ${errors[errorField]}`);
                        });
                    }
                    notif(
                        formatErrorInNotif({
                            message: 'Veuillez corriger les erreurs sur le formulaire :',
                            errors: errorMessages
                        })
                    );
                }
            )
            .finally(() => {
                setSaveInProgress(false);
            });
    };

    const getCodecValue = (
        codecs: { [deviceType: number]: any },
        deviceType: number,
        type: 'encoder' | 'ack'
    ) => {
        if (codecs && codecs[deviceType]) {
            const arr = encoderService?.getEncoders ? encoderService?.getEncoders({ type }) : [];
            const codec = arr.find((codecItem: any) => codecItem.id === codecs[deviceType]);

            if (codec) {
                return {
                    value: codec.id,
                    label: codec.label
                };
            }
        }

        return null;
    };

    let payloadError: any = [];
    const groups = dataService.getData('groups', { is_active: true }).map((group: any) => {
        return { value: group.id, label: group.name };
    });

    const deviceTypes = dataService
        .getData('deviceTypes', { is_active: true })
        ?.map((group: any) => {
            return { value: group.id, label: group.name };
        });

    const getCodecsPathsOptions = (
        codecType: 'encoder' | 'ack',
        protocolId: number,
        deviceTypeId: number
    ) => {
        const protocolLabel = getProtocolLabel(protocolId);
        const deviceTypeLabel = getDeviceTypeLabel(deviceTypeId);
        const codecsPaths = encoderService?.getEncoders
            ? encoderService
                  ?.getEncoders({
                      type: codecType,
                      protocol: protocolLabel,
                      device_type: deviceTypeLabel
                  })
                  ?.map((codec: any) => {
                      return { value: codec.id, label: codec.label };
                  })
            : [];
        return codecsPaths;
    };

    const getProtocolLabel = (protocolId: number) => {
        const protocol = dataService
            .getData('protocols')
            .find((protocol: any) => protocol.id === protocolId);
        return protocol ? protocol.name : '';
    };

    const getDeviceTypeLabel = (deviceTypeId: number) => {
        const deviceType = dataService
            .getData('deviceTypes')
            .find((deviceType: any) => deviceType.id === deviceTypeId);
        return deviceType ? deviceType.name : '';
    };

    const dbIds = {
        HR: 1,
        LRW: 3
    };

    const StyledTab = styled((props: { hasError: boolean; label: string; codecPath: string }) => (
        <LightTooltip
            enterDelay={300}
            customShadowElevation={0}
            border={'1px solid rgba(0, 0, 0, 0.12)'}
            customPaddingY={2}
            customOffsetY={-15}
            title={props.codecPath}
            placement="top"
            arrow>
            <Tab disableRipple {...props} />
        </LightTooltip>
    ))(({ hasError }) => ({
        color: hasError ? '#dc3545' : 'rgba(0, 0, 0, 0.6)',
        '&.Mui-selected': {
            color: hasError ? '#dc3545' : '#253053'
        }
    }));

    return showForm ? (
        <>
            <Formik
                initialValues={model}
                enableReinitialize={true}
                onSubmit={(values, { setSubmitting }) => {
                    saveModel(values);
                    setSubmitting(false);
                }}
                validationSchema={Yup.object().shape({
                    name: Yup.string().required('Vous devez renseigner le nom'),
                    type: Yup.object()
                        .shape({
                            label: Yup.string(),
                            value: Yup.string()
                        })
                        .default(undefined)
                        .required('Vous devez choisir un type'),
                    groups: Yup.mixed().required('Vous devez choisir au moins un groupe'),
                    deviceTypes: Yup.array()
                        .typeError('Vous devez choisir au moins un device type')
                        .required('Vous devez choisir au moins un device type'),
                    new_technology: Yup.string().when(
                        'isTechnologyChange',
                        (isTechnologyChange, schema) => {
                            return isTechnologyChange[0]
                                ? Yup.string()
                                      .max(
                                          2,
                                          'Le champ nouvelle technologie ne doit pas excéder 2 caractères'
                                      )
                                      .required('Vous devez renseigner la nouvelle technologie')
                                : schema;
                        }
                    ),
                    new_operator: Yup.string().when(
                        'isOperatorChange',
                        (isOperatorChange, schema) => {
                            return isOperatorChange[0]
                                ? Yup.string().required('Vous devez renseigner le nouvel opérateur')
                                : schema;
                        }
                    )
                })}
                initialTouched={{
                    name: true,
                    groups: true,
                    deviceTypes: true,
                    type: true
                }}
                validate={() =>
                    //values
                    {
                        const errors: any = {};
                        if (payloadError.length) {
                            errors.payload = "Le payload n'est pas correctement renseigné";
                        }
                        return errors;
                    }
                }>
                {({
                    values,
                    getFieldProps,
                    isSubmitting,
                    setFieldValue,
                    resetForm,
                    errors,
                    // setFieldError,
                    validateForm,
                    touched
                    // isValid,
                    // dirty
                }: any) => {
                    const selectedProtocol =
                        values && values['protocol']?.value ? values['protocol']?.value : null;

                    const codecs_path_array =
                        values && values['codecs_path'] ? Object.keys(values['codecs_path']) : [];
                    const codecsPathArray = codecs_path_array?.filter((stringId: string) =>
                        values?.deviceTypes?.find(
                            (deviceType: { label: string; value: number }) =>
                                deviceType.value === parseInt(stringId)
                        )
                    ) as string[];

                    // let selectedDeviceTypes =
                    //   values && values["deviceTypes"] &&
                    //   values["deviceTypes"].map((deviceType: any) => {
                    //     return deviceType.label;
                    //   });

                    return (
                        <Form noValidate className="modelForm">
                            <AppPaper>
                                <h2>
                                    <Trans>
                                        {action === 'add'
                                            ? 'Ajouter'
                                            : action === 'edit'
                                              ? 'Modifier'
                                              : 'Copier'}{' '}
                                        une fiche modèle
                                    </Trans>
                                </h2>
                                <AppPaper>
                                    <h3>Informations générales</h3>
                                    <Grid container spacing={1} alignItems={'center'}>
                                        <Grid item xs={2}>
                                            Nom *
                                        </Grid>
                                        <Grid item xs={10}>
                                            <TextField
                                                id={'modelForm-name-input'}
                                                type={'text'}
                                                size="small"
                                                fullWidth
                                                {...getFieldProps('name')}
                                            />
                                            {(touched.name || isSubmitting) &&
                                                typeof errors.name !== 'undefined' && (
                                                    <Typography sx={{ color: 'red' }}>
                                                        {errors.name}
                                                    </Typography>
                                                )}
                                        </Grid>
                                        <Grid item xs={2}>
                                            Commentaire
                                        </Grid>
                                        <Grid item xs={10}>
                                            <TextField
                                                id={'modelForm-comment-input'}
                                                type={'textarea'}
                                                multiline
                                                rows={4}
                                                fullWidth
                                                {...getFieldProps('comment')}
                                            />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <Grid container spacing={1} alignItems={'center'}>
                                                <Grid item xs={4}>
                                                    Type d'équipement *
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <div
                                                        className={
                                                            (touched.deviceTypes || isSubmitting) &&
                                                            errors.deviceTypes
                                                                ? 'select-container is-invalid'
                                                                : 'select-container'
                                                        }>
                                                        <Select
                                                            id={'modelForm-deviceType-select'}
                                                            options={deviceTypes}
                                                            {...getFieldProps('deviceTypes')}
                                                            onChange={(deviceTypes: any) => {
                                                                setFieldValue(
                                                                    'deviceTypes',
                                                                    deviceTypes
                                                                );

                                                                // Remove if necessary unless codecs if deviceType is removed
                                                                const devicesTypesIds =
                                                                    deviceTypes?.map(
                                                                        (deviceType: {
                                                                            label: string;
                                                                            value: number;
                                                                        }) => deviceType.value
                                                                    );
                                                                const codecs: {
                                                                    [key: string]: string;
                                                                } = values['codecs_path'];
                                                                const newCodecs: {
                                                                    [key: string]: string;
                                                                } = {};
                                                                codecs &&
                                                                    Object.keys(codecs)?.forEach(
                                                                        (deviceTypeId: string) => {
                                                                            if (
                                                                                devicesTypesIds?.includes(
                                                                                    parseInt(
                                                                                        deviceTypeId
                                                                                    )
                                                                                )
                                                                            ) {
                                                                                newCodecs[
                                                                                    deviceTypeId
                                                                                ] =
                                                                                    codecs[
                                                                                        deviceTypeId
                                                                                    ];
                                                                            }
                                                                        }
                                                                    );
                                                                setFieldValue(
                                                                    'codecs_path',
                                                                    newCodecs
                                                                );
                                                            }}
                                                            isMulti={true}
                                                            loadingMessage={() =>
                                                                'Chargement en cours...'
                                                            }
                                                            noOptionsMessage={() =>
                                                                'Aucune valeur à sélectionner'
                                                            }
                                                            placeholder=""
                                                        />
                                                    </div>
                                                    {(touched.deviceTypes || isSubmitting) &&
                                                        typeof errors?.deviceTypes !==
                                                            'undefined' && (
                                                            <Typography sx={{ color: 'red' }}>
                                                                {errors?.deviceTypes}
                                                            </Typography>
                                                        )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Grid container spacing={1} alignItems={'center'}>
                                                <Grid item xs={4}>
                                                    Groupes *
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <div
                                                        className={
                                                            (touched.groups || isSubmitting) &&
                                                            errors.groups
                                                                ? 'select-container is-invalid'
                                                                : 'select-container'
                                                        }>
                                                        <Select
                                                            id={'modelForm-groups-select'}
                                                            options={groups}
                                                            {...getFieldProps('groups')}
                                                            onChange={(values: any) =>
                                                                setFieldValue('groups', values)
                                                            }
                                                            isMulti={true}
                                                            loadingMessage={() =>
                                                                'Chargement en cours...'
                                                            }
                                                            noOptionsMessage={() =>
                                                                'Aucune valeur à sélectionner'
                                                            }
                                                            placeholder=""
                                                        />
                                                    </div>
                                                    {(touched.groups || isSubmitting) &&
                                                        typeof errors?.groups !== 'undefined' && (
                                                            <Typography sx={{ color: 'red' }}>
                                                                {errors?.groups}
                                                            </Typography>
                                                        )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Grid container spacing={1} alignItems={'center'}>
                                                <Grid item xs={4}>
                                                    Protocole
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <AutocompleteField
                                                        field={{
                                                            options: {
                                                                source: '/api/lists/protocols/?ordering=name&',
                                                                searchIsMulti: false,
                                                                isActive: true
                                                            },
                                                            name: 'protocol'
                                                        }}
                                                        {...getFieldProps('protocol')}
                                                        onSelect={(values: any) => {
                                                            setFieldValue('protocol', values);
                                                            setFieldValue('codecs_path', null);
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}></Grid>

                                        <Grid item xs={2}>
                                            Codec
                                        </Grid>
                                        <Grid item xs={10} sx={{ mb: 1 }}>
                                            {(!values?.deviceTypes ||
                                                !values?.protocol ||
                                                Object.keys(values.deviceTypes).length === 0 ||
                                                Object.keys(values.protocol).length === 0) && (
                                                <>
                                                    Vous devez choisir un protocole et au moins un
                                                    deviceType pour pouvoir définir les codecs par
                                                    deviceType
                                                </>
                                            )}
                                            {values['deviceTypes'] &&
                                                values['deviceTypes'].length > 0 && (
                                                    <TableContainer component={Paper}>
                                                        <Table size="small">
                                                            <TableHead>
                                                                <StyledTableHeaderRow>
                                                                    <TableCell>
                                                                        Device Type
                                                                    </TableCell>
                                                                    <TableCell
                                                                        style={{ width: '350px' }}>
                                                                        Codec
                                                                    </TableCell>
                                                                    <TableCell
                                                                        style={{ width: '350px' }}>
                                                                        Codec accusé réception
                                                                    </TableCell>
                                                                </StyledTableHeaderRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {values['deviceTypes']?.map(
                                                                    (deviceType: any) => (
                                                                        <StyledTableBodyRow
                                                                            key={deviceType.value}>
                                                                            <TableCell>
                                                                                {deviceType.label}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <Select
                                                                                    id={
                                                                                        'modelForm-codecs-select'
                                                                                    }
                                                                                    options={[
                                                                                        {
                                                                                            value: '',
                                                                                            label: ''
                                                                                        },
                                                                                        ...getCodecsPathsOptions(
                                                                                            'encoder',
                                                                                            selectedProtocol,
                                                                                            deviceType.value
                                                                                        )
                                                                                    ]}
                                                                                    onChange={(
                                                                                        selectedValue: any
                                                                                    ) => {
                                                                                        setFieldValue(
                                                                                            'codecs_path',
                                                                                            Object.assign(
                                                                                                {},
                                                                                                values[
                                                                                                    'codecs_path'
                                                                                                ],
                                                                                                {
                                                                                                    [deviceType.value]:
                                                                                                        selectedValue.value
                                                                                                }
                                                                                            )
                                                                                        );
                                                                                    }}
                                                                                    value={getCodecValue(
                                                                                        values[
                                                                                            'codecs_path'
                                                                                        ],
                                                                                        deviceType.value,
                                                                                        'encoder'
                                                                                    )}
                                                                                    isMulti={false}
                                                                                    loadingMessage={() =>
                                                                                        'Chargement en cours...'
                                                                                    }
                                                                                    noOptionsMessage={() =>
                                                                                        "Aucun codec disponible pour le protocole et les types d'équipement choisis"
                                                                                    }
                                                                                    placeholder=""
                                                                                    menuPortalTarget={
                                                                                        document.body
                                                                                    }
                                                                                />
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <Select
                                                                                    id={
                                                                                        'modelForm-codecs_path_ack-select'
                                                                                    }
                                                                                    options={[
                                                                                        {
                                                                                            value: '',
                                                                                            label: ''
                                                                                        },
                                                                                        ...getCodecsPathsOptions(
                                                                                            'ack',
                                                                                            selectedProtocol,
                                                                                            deviceType.value
                                                                                        )
                                                                                    ]}
                                                                                    value={getCodecValue(
                                                                                        values[
                                                                                            'codecs_path_ack'
                                                                                        ],
                                                                                        deviceType.value,
                                                                                        'ack'
                                                                                    )}
                                                                                    onChange={(
                                                                                        selectedValue: any
                                                                                    ) => {
                                                                                        setFieldValue(
                                                                                            'codecs_path_ack',
                                                                                            Object.assign(
                                                                                                {},
                                                                                                values[
                                                                                                    'codecs_path_ack'
                                                                                                ],
                                                                                                {
                                                                                                    [deviceType.value]:
                                                                                                        selectedValue.value
                                                                                                }
                                                                                            )
                                                                                        );
                                                                                    }}
                                                                                    isMulti={false}
                                                                                    loadingMessage={() =>
                                                                                        'Chargement en cours...'
                                                                                    }
                                                                                    noOptionsMessage={() =>
                                                                                        'Aucune valeur à sélectionner'
                                                                                    }
                                                                                    placeholder=""
                                                                                    menuPortalTarget={
                                                                                        document.body
                                                                                    }
                                                                                />
                                                                            </TableCell>
                                                                        </StyledTableBodyRow>
                                                                    )
                                                                )}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                )}
                                        </Grid>

                                        <Grid item xs={6}>
                                            <Grid container alignItems={'center'}>
                                                <Grid item xs={4}>
                                                    Type *
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <div
                                                        className={
                                                            (touched.type || isSubmitting) &&
                                                            errors.type
                                                                ? 'select-container is-invalid'
                                                                : 'select-container'
                                                        }>
                                                        <Select
                                                            id={'modelForm-type-select'}
                                                            name="type"
                                                            options={modelTypes}
                                                            {...getFieldProps('type')}
                                                            onChange={(values: any) => {
                                                                setFieldValue('type', values);
                                                            }}
                                                            isMulti={false}
                                                            loadingMessage={() =>
                                                                'Chargement en cours...'
                                                            }
                                                            noOptionsMessage={() =>
                                                                'Aucune valeur à sélectionner'
                                                            }
                                                            placeholder=""
                                                        />
                                                    </div>
                                                    {(touched.type || isSubmitting) &&
                                                        typeof errors?.type !== 'undefined' && (
                                                            <Typography sx={{ color: 'red' }}>
                                                                {errors?.type}
                                                            </Typography>
                                                        )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}></Grid>
                                        <Grid item xs={6}>
                                            <Grid container alignItems={'center'}>
                                                <Grid item xs={4}>
                                                    Changement techno
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Checkbox
                                                        id={'modelForm-techno_change-checkbox'}
                                                        checked={
                                                            values &&
                                                            values['isTechnologyChange'] === true
                                                        }
                                                        onChange={(e: any) => {
                                                            setFieldValue(
                                                                'isTechnologyChange',
                                                                e.target.checked
                                                            );

                                                            if (!e.target.checked) {
                                                                setFieldValue('new_technology', '');
                                                                setFieldValue(
                                                                    'technologyChangeOnTimeout',
                                                                    false
                                                                );
                                                            }
                                                        }}
                                                        // {...getFieldProps('isTechnologyChange')}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                        // disabled={
                                                        //     values &&
                                                        //     values?.isOperatorChange === true
                                                        // }
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {values['isTechnologyChange'] === true && (
                                                        <AppPaper>
                                                            <Grid container alignItems={'center'}>
                                                                <Grid item xs={8}>
                                                                    Activation du changement de
                                                                    technologie au timeout
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <Checkbox
                                                                        id={
                                                                            'modelForm-techno_change_on_timeout-checkbox'
                                                                        }
                                                                        checked={
                                                                            values[
                                                                                'technologyChangeOnTimeout'
                                                                            ] === true
                                                                        }
                                                                        {...getFieldProps(
                                                                            'technologyChangeOnTimeout'
                                                                        )}
                                                                        onChange={(e: any) => {
                                                                            setFieldValue(
                                                                                'technologyChangeOnTimeout',
                                                                                e.target.checked
                                                                            );
                                                                        }}
                                                                        inputProps={{
                                                                            'aria-label':
                                                                                'controlled'
                                                                        }}
                                                                        // disabled={
                                                                        //     values &&
                                                                        //     values?.isOperatorChange ===
                                                                        //         true
                                                                        // }
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    Nouvelle technologie
                                                                    <Tooltip title={tooltipTechno}>
                                                                        <IconButton
                                                                            id={
                                                                                'modelForm-new_techno-button'
                                                                            }>
                                                                            <HelpIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Grid>
                                                                <Grid item xs={8}>
                                                                    <TextField
                                                                        id={
                                                                            'modelForm-new_technology-input'
                                                                        }
                                                                        type={'text'}
                                                                        size="small"
                                                                        fullWidth
                                                                        {...getFieldProps(
                                                                            'new_technology'
                                                                        )}
                                                                    />
                                                                    {(touched.new_technology ||
                                                                        isSubmitting) &&
                                                                        typeof errors.new_technology !==
                                                                            'undefined' && (
                                                                            <Typography
                                                                                sx={{
                                                                                    color: 'red'
                                                                                }}>
                                                                                {
                                                                                    errors.new_technology
                                                                                }
                                                                            </Typography>
                                                                        )}
                                                                </Grid>
                                                            </Grid>
                                                        </AppPaper>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={6} alignSelf={'self-start'}>
                                            <Grid container alignItems={'center'}>
                                                <Grid item xs={4}>
                                                    Changement opérateur
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Checkbox
                                                        id={'modelForm-operator_change-checkbox'}
                                                        checked={
                                                            values['isOperatorChange'] === true
                                                        }
                                                        {...getFieldProps('isOperatorChange')}
                                                        onChange={(e: any) => {
                                                            setFieldValue(
                                                                'isOperatorChange',
                                                                e.target.checked
                                                            );

                                                            if (!e.target.checked) {
                                                                setFieldValue('new_technology', '');
                                                                setFieldValue(
                                                                    'technologyChangeOnTimeout',
                                                                    false
                                                                );
                                                            } else {
                                                                setFieldValue(
                                                                    'isTechnologyChange',
                                                                    true
                                                                );
                                                            }
                                                        }}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                    />
                                                </Grid>
                                                {values['isOperatorChange'] === true && (
                                                    <Grid item xs={12}>
                                                        <AppPaper>
                                                            <Grid container alignItems={'center'}>
                                                                <Grid item xs={8}>
                                                                    Activation du changement
                                                                    d'opérateur au timeout
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <Checkbox
                                                                        id={
                                                                            'modelForm-operator_change_on_timeout-checkbox'
                                                                        }
                                                                        checked={
                                                                            values[
                                                                                'operatorChangeOnTimeout'
                                                                            ] === true
                                                                        }
                                                                        {...getFieldProps(
                                                                            'operatorChangeOnTimeout'
                                                                        )}
                                                                        onChange={(e: any) => {
                                                                            setFieldValue(
                                                                                'operatorChangeOnTimeout',
                                                                                e.target.checked
                                                                            );
                                                                        }}
                                                                        inputProps={{
                                                                            'aria-label':
                                                                                'controlled'
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    Nouvel opérateur
                                                                    <Tooltip
                                                                        title={tooltipOperator}
                                                                        componentsProps={{
                                                                            tooltip: {
                                                                                sx: {
                                                                                    width: '500px',
                                                                                    maxWidth:
                                                                                        '500px'
                                                                                }
                                                                            }
                                                                        }}>
                                                                        <IconButton
                                                                            id={
                                                                                'modelForm-new_operator_help-button'
                                                                            }>
                                                                            <HelpIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Grid>
                                                                <Grid item xs={8}>
                                                                    <TextField
                                                                        id={
                                                                            'modelForm-new_operator-input'
                                                                        }
                                                                        type={'text'}
                                                                        size="small"
                                                                        fullWidth
                                                                        {...getFieldProps(
                                                                            'new_operator'
                                                                        )}
                                                                    />
                                                                    {(touched.new_operator ||
                                                                        isSubmitting) &&
                                                                        typeof errors.new_operator !==
                                                                            'undefined' && (
                                                                            <Typography
                                                                                sx={{
                                                                                    color: 'red'
                                                                                }}>
                                                                                {
                                                                                    errors.new_operator
                                                                                }
                                                                            </Typography>
                                                                        )}
                                                                </Grid>
                                                            </Grid>
                                                        </AppPaper>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <Grid container alignItems={'center'}>
                                                <Grid item xs={4}>
                                                    Port
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <TextField
                                                        id={'modelForm-port-input'}
                                                        type={'number'}
                                                        size="small"
                                                        fullWidth
                                                        {...getFieldProps('port')}
                                                    />
                                                    {(touched.port || isSubmitting) &&
                                                        typeof errors.port !== 'undefined' && (
                                                            <Typography sx={{ color: 'red' }}>
                                                                {errors.port}
                                                            </Typography>
                                                        )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}></Grid>

                                        <Grid item xs={6}>
                                            <Grid container alignItems={'center'}>
                                                <Grid item xs={4}>
                                                    Délai d'expiration
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <TextField
                                                        id={'modelForm-max_days-input'}
                                                        type={'number'}
                                                        size="small"
                                                        fullWidth
                                                        {...getFieldProps('max_days')}
                                                    />
                                                    <small>
                                                        (facultatif, à 7 jours par défaut)
                                                    </small>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}></Grid>

                                        <Grid item xs={6}>
                                            <Grid container alignItems={'center'}>
                                                <Grid item xs={4}>
                                                    Envoi sur 2 antennes
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Checkbox
                                                        id={'modelForm-multi_antenna-checkbox'}
                                                        checked={values['isMultiAntenna'] === true}
                                                        {...getFieldProps('isMultiAntenna')}
                                                        onChange={(e: any) => {
                                                            setFieldValue(
                                                                'isMultiAntenna',
                                                                e.target.checked
                                                            );
                                                        }}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}></Grid>

                                        <Grid item xs={6}>
                                            <Grid container alignItems={'center'}>
                                                <Grid item xs={4}>
                                                    Collision numéro de séquence en succès
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Checkbox
                                                        id={
                                                            'modelForm-sequence_number_collision-checkbox'
                                                        }
                                                        checked={
                                                            values[
                                                                'isSequenceNumberCollisionSuccess'
                                                            ] === true
                                                        }
                                                        {...getFieldProps(
                                                            'isSequenceNumberCollisionSuccess'
                                                        )}
                                                        onChange={(e: any) => {
                                                            setFieldValue(
                                                                'isSequenceNumberCollisionSuccess',
                                                                e.target.checked
                                                            );
                                                        }}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}></Grid>
                                        <Grid item xs={6}>
                                            <Grid container alignItems={'center'}>
                                                <Grid item xs={4}>
                                                    Modèle combiné LRW
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <AutocompleteField
                                                        field={{
                                                            options: {
                                                                source: `/api/lists/frame-templates/?protocol__in=${dbIds['LRW']}&ordering=name&`,
                                                                searchIsMulti: false,
                                                                isActive: true
                                                            },
                                                            name: 'combined_template_lrw'
                                                        }}
                                                        {...getFieldProps('combined_template_lrw')}
                                                        onSelect={(values: any) => {
                                                            setFieldValue(
                                                                'combined_template_lrw',
                                                                values
                                                            );
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}></Grid>
                                        <Grid item xs={6}>
                                            <Grid container alignItems={'center'}>
                                                <Grid item xs={4}>
                                                    Modèle combiné HR
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <AutocompleteField
                                                        field={{
                                                            options: {
                                                                source: `/api/lists/frame-templates/?protocol__in=${dbIds['HR']}&ordering=name&`,
                                                                searchIsMulti: false,
                                                                isActive: true
                                                            },
                                                            name: 'combined_template_hr'
                                                        }}
                                                        {...getFieldProps('combined_template_hr')}
                                                        onSelect={(values: any) => {
                                                            setFieldValue(
                                                                'combined_template_hr',
                                                                values
                                                            );
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}></Grid>
                                    </Grid>
                                </AppPaper>

                                {values['codecs_path'] && codecsPathArray?.length > 0 && (
                                    <>
                                        {values?.protocol?.label === 'Homerider' && (
                                            <CampaignHeaderConfig
                                                label="Header"
                                                value={values['header_config']}
                                                onChange={(headerConfig: any) => {
                                                    setFieldValue('header_config', headerConfig);
                                                }}
                                            />
                                        )}
                                        <Tabs
                                            sx={{
                                                mt: 3,
                                                mb: 1,
                                                borderBottom: 1,
                                                borderColor: 'divider'
                                            }}
                                            value={valueTab}
                                            onChange={handleChangeTab}
                                            variant="scrollable"
                                            scrollButtons
                                            allowScrollButtonsMobile
                                            TabIndicatorProps={{
                                                style: {
                                                    background:
                                                        errorsState[valueTab] === true
                                                            ? '#dc3545'
                                                            : '#253053'
                                                }
                                            }}>
                                            {codecsPathArray?.map((deviceTypeId, index) => {
                                                const name = values['deviceTypes'].find(
                                                    (el: { label: string; value: number }) =>
                                                        el.value.toString() === deviceTypeId
                                                )?.label;
                                                const codecPath =
                                                    values['codecs_path'][deviceTypeId];
                                                deviceTypeId = deviceTypeId.toString();
                                                return (
                                                    <StyledTab
                                                        key={deviceTypeId}
                                                        label={name}
                                                        codecPath={codecPath}
                                                        hasError={errorsState[index] === true}
                                                    />
                                                );
                                            })}
                                        </Tabs>
                                        <Box sx={{ mb: 2 }}>
                                            {codecsPathArray?.map((deviceTypeId, tabIndex) => {
                                                const codecPath =
                                                    values['codecs_path'][deviceTypeId];
                                                deviceTypeId = deviceTypeId.toString();
                                                return (
                                                    <Box
                                                        key={deviceTypeId + tabIndex}
                                                        sx={{
                                                            display:
                                                                tabIndex === valueTab
                                                                    ? 'block'
                                                                    : 'none'
                                                        }}>
                                                        {
                                                            <Parameters
                                                                id={deviceTypeId}
                                                                codec={codecPath}
                                                                values={
                                                                    values?.payload &&
                                                                    values?.payload[deviceTypeId]
                                                                        ? values?.payload[
                                                                              deviceTypeId
                                                                          ]
                                                                        : {}
                                                                }
                                                                onChange={(parameters: any) => {
                                                                    setFieldValue(
                                                                        `payload.${deviceTypeId}`,
                                                                        parameters
                                                                    );
                                                                }}
                                                                onErrors={(tabErrors: any) => {
                                                                    payloadError = tabErrors;
                                                                }}
                                                                setHasError={(
                                                                    hasError: boolean
                                                                ) => {
                                                                    if (
                                                                        !(
                                                                            tabIndex in errorsState
                                                                        ) ||
                                                                        errorsState[tabIndex] !==
                                                                            hasError
                                                                    ) {
                                                                        const obj = {
                                                                            ...errorsState,
                                                                            [tabIndex]: hasError
                                                                        };
                                                                        setErrorsState(obj);
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                    </Box>
                                                );
                                            })}
                                        </Box>
                                    </>
                                )}

                                {authService.canAccess('IS_ACTIVE') && (
                                    <Grid
                                        container
                                        spacing={1}
                                        alignItems={'center'}
                                        sx={{ ml: 0 }}>
                                        <Grid item xs={2}>
                                            État
                                        </Grid>
                                        <Grid item xs={10}>
                                            <div
                                                className={
                                                    (touched.is_active || isSubmitting) &&
                                                    errors.is_active
                                                        ? 'is-invalid'
                                                        : ''
                                                }>
                                                <RadioGroup
                                                    row
                                                    {...getFieldProps('is_active')}
                                                    onChange={(e) => {
                                                        setFieldValue(
                                                            'is_active',
                                                            e.target.value === 'true'
                                                        );
                                                    }}
                                                    value={values['is_active']}>
                                                    <FormControlLabel
                                                        value="true"
                                                        control={
                                                            <Radio
                                                                checked={
                                                                    values['is_active'] === true
                                                                }
                                                                id={
                                                                    'modelForm-is_active_true-radio'
                                                                }
                                                            />
                                                        }
                                                        label="Actif"
                                                        sx={{
                                                            '& .MuiSvgIcon-root': { fontSize: 18 },
                                                            '& .MuiFormControlLabel-label': {
                                                                fontSize: '15px'
                                                            }
                                                        }}
                                                    />
                                                    <FormControlLabel
                                                        value="false"
                                                        control={
                                                            <Radio
                                                                checked={
                                                                    values['is_active'] === false
                                                                }
                                                                id={
                                                                    'modelForm-is_active_false-radio'
                                                                }
                                                            />
                                                        }
                                                        label="Inactif"
                                                        sx={{
                                                            '& .MuiSvgIcon-root': { fontSize: 18 },
                                                            '& .MuiFormControlLabel-label': {
                                                                fontSize: '15px'
                                                            }
                                                        }}
                                                    />
                                                </RadioGroup>
                                            </div>
                                            {(touched.is_active || isSubmitting) &&
                                                typeof errors.is_active !== 'undefined' && (
                                                    <Typography sx={{ color: 'red' }}>
                                                        {errors.is_active}
                                                    </Typography>
                                                )}
                                        </Grid>

                                        <Grid item xs={2}>
                                            Dernière modification
                                        </Grid>
                                        <Grid item xs={10}>
                                            {model?.updated_at
                                                ? `le ${moment.unix(model.updated_at).format('DD/MM/YYYY à HH:mm:ss')}`
                                                : '-'}
                                        </Grid>
                                    </Grid>
                                )}
                            </AppPaper>

                            <Box sx={{ textAlign: 'center', mt: 2, pb: 2 }}>
                                <Button
                                    id={'modelForm-cancel-button'}
                                    variant="outlined"
                                    onClick={() => {
                                        confirmDialog({
                                            title: 'Souhaitez-vous vraiment annuler vos saisies?',
                                            onValidate: () => {
                                                // Reinitialize parameters component
                                                setShowForm(false);
                                                setSafeTimeout(() => {
                                                    setShowForm(true);
                                                }, 0);
                                                resetForm();
                                                closeDialog();
                                            },
                                            onCancel: () => {
                                                closeDialog();
                                            }
                                        });
                                    }}>
                                    Annuler
                                </Button>
                                <Button
                                    id={'modelForm-submit-button'}
                                    sx={{ ml: 1 }}
                                    variant="contained"
                                    type="submit"
                                    disabled={Object.values(errorsState).includes(true)}
                                    onClick={() =>
                                        validateForm().then((errors: any) => {
                                            const errorsList: string[] = Object.values(errors);
                                            if (errorsList.length) {
                                                notif(
                                                    formatErrorInNotif({
                                                        message:
                                                            'Veuillez corriger les erreurs sur le formulaire :',
                                                        errors: errorsList
                                                    })
                                                );
                                            }
                                        })
                                    }>
                                    Enregistrer
                                </Button>
                            </Box>
                        </Form>
                    );
                }}
            </Formik>
            <BirdzNotif options={notifOptions} />
            <BirdzDialog options={dialogOptions} />
        </>
    ) : (
        <></>
    );
};

export default ModelForm;
