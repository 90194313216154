import { green, red } from '@mui/material/colors';
import { Box } from '@mui/material';
import CheckIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const greenColor = green[500];
const redColor = red[500];

const iconSize = '16px';
const icons = {
    true: <CheckIcon style={{ color: greenColor, fontSize: iconSize }}  />,
    false: <CancelIcon style={{ color: redColor, fontSize: iconSize }}  />
};

const TrueFalse = ({ value }: { value: boolean | null | undefined }) => (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>{icons[value ? 'true' : 'false']}</Box>
);

export default TrueFalse;
