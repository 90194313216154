const baseFields = [
    {
        name: 'contract',
        label: 'Contrat',
        orderable: true
    },
    {
        name: 'contract_label',
        label: 'Libellé',
        orderable: true
    },
    {
        name: 'modules_count',
        label: 'Nb de module(s) raccordable(s)',
        shortLabel: 'Raccordables',
        orderable: true
    },
    {
        name: 'excluded_count',
        label: 'Nb de module(s) exclu(s)',
        shortLabel: 'Exclus',
        orderable: true
    },
    {
        name: 'repeaters_count',
        label: 'Nb de répéteur(s)',
        shortLabel: 'Répéteurs',
        orderable: true
    },
    {
        name: 'concentrators_count',
        label: 'Nb de concentrateur(s)',
        shortLabel: 'Concentrateurs',
        orderable: true
    },
    {
        name: 'eligible_count',
        label: 'Nb total de module(s)',
        shortLabel: 'Total',
        orderable: true
    }
];

export const contractsConfig = {
    primo: {
        endpoint: '/api/racsup/primo/',
        model: 'racsup-primo',
        onActionLink: '/racsup/primo/list/create/',
        label: 'primo-raccordement',
        fields: baseFields,
        searchKey: 'contract_label',
    },
    rerac: {
        endpoint: '/api/racsup/rerac/',
        model: 'racsup-rerac',
        onActionLink: '/racsup/change/list/create/',
        label: 're-raccordement',
        fields: baseFields,
        searchKey: 'contract_label',
    },
    renfo: {
        endpoint: '/api/racsup/renfo/',
        model: 'racsup-append',
        onActionLink: '/racsup/append/list/create/',
        label: 'renforcement',
        fields: baseFields,
        searchKey: 'contract',
    },
    net: {
        endpoint: '/api/racsup/net/',
        model: 'racsup-delete',
        onActionLink: '/racsup/delete/list/create/',
        label: 'nettoyage',
        fields: baseFields,
        searchKey: 'contract',
    }
};
