import { useEffect, useState } from 'react';

const useDisableItemButton = ({ required, hasValue }: { required: boolean; hasValue: boolean }) => {
    const [hasError, setHasError] = useState<boolean>(false);

    useEffect(() => {
        const button = document.getElementById('ItemForm_submit_button') as HTMLButtonElement;
        const handleClick = () => {
            if (required && !hasError && !hasValue) setHasError(true);
        };

        if (button) {
            button.addEventListener('click', handleClick);

            // add disabled class if error
            if (required) {
                if (hasValue || !hasError) {
                    setHasError(false);
                    button.className = button.className.replace('Mui-disabled', '');
                } else {
                    setHasError(true);
                    button.className = button.className + ' Mui-disabled';
                }
            }
        }

        return () => {
            button && button.removeEventListener('click', handleClick);
        };
    }, [hasError, hasValue, required]);

    return {
        hasError,
        setHasError
    };
};

export default useDisableItemButton;
