import React, { useState } from 'react';
import {
    BirdzDialog,
    useDialog,
    StyledTitle,
    Item,
    FormField,
    ListField,
    BirdzNotif,
    useNotif
} from '@applications-terrains/birdz-react-library';
import { Link } from 'react-router-dom';
import moment from 'moment';
import dataService from '../Common/Services/dataService';
import * as Yup from 'yup';
import Axios from 'axios';
import { Button, Grid, IconButton } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Delete, Edit, Group, Settings } from '@mui/icons-material';
import PagePaper from '../Tools/Pages/PagePaper';
import BSearchForm from '../Tools/Search/BSearchForm';
import BTable from '../Tools/Table/BTable';
import TrueFalse from '../Tools/Table/components/TrueFalse';

const Groups = (params: any) => {
    const endpoint = '/api/groups/';
    const model = 'groups-admin';

    const [filters, setFilters] = useState<string>('');

    const { confirmDialog, closeDialog, dialogOptions } = useDialog();
    const { notif, notifOptions } = useNotif();

    let formFields: FormField[] = [
        {
            name: 'name',
            type: 'text',
            label: 'Nom',
            validation: Yup.string().required('Vous devez renseigner le nom du groupe')
        },
        {
            name: 'is_active',
            type: 'radio',
            label: 'Etat',
            options: {
                values: [
                    { value: true, label: 'Actif' },
                    { value: false, label: 'Inactif' }
                ]
            },
            defaultValue: true,
            permissions: ['IS_ACTIVE']
        },
        {
            name: 'sections',
            type: 'select-multiple',
            label: 'Sections',
            options: {
                values: dataService.getSelectData(
                    'sections',
                    { is_active: true },
                    { label: 'code' }
                )
            },
            validation: Yup.array().required('Vous devez choisir au moins une section')
        },
        {
            name: 'updated_at',
            type: 'readonly',
            label: 'Dernière modification',
            transform: (value: any) => {
                let date = value && moment.unix(value).format('DD/MM/YYYY à HH:mm:ss');
                return date || '';
            }
        }
    ];
    let listFields: ListField[] = [
        { name: 'name', label: 'Name', orderable: true },
        {
            name: 'is_active',
            label: 'Actif',
            className: 'text-center',
            transform: (value: boolean) => {
                return <TrueFalse value={value} />;
            },
            orderable: true
        },
        {
            name: 'users_list',
            label: 'Liste des utilisateurs',
            className: 'text-center',
            transform: (value: any, item: any) => {
                return (
                    <IconButton
                        id={'users-list-button'}
                        component={Link}
                        to={`/admin/users/?groups=${item.id}&groups_name=${item.name}`}>
                        <Group fontSize="small" />
                    </IconButton>
                );
            },
            options: { width: '170px' }
        },
        {
            name: 'sections_list',
            label: 'Liste des sections',
            className: 'text-center',
            transform: (value: any, item: any) => {
                return (
                    <IconButton
                        id={'sections-list-button'}
                        component={Link}
                        to={`/admin/sections/?group=${item.id}&group_name=${item.name}`}>
                        <Settings fontSize="small" />
                    </IconButton>
                );
            },
            options: { width: '150px' }
        },
        {
            name: 'updated_at',
            label: 'Date de modification',
            options: { width: '215px' },
            orderable: true,
            transform: (value: any) => {
                return (value && moment.unix(value).format('DD/MM/YYYY à HH:mm:ss')) || '';
            }
        }
    ];
    let actions = [
        {
            name: 'edit',
            render: (value: any) => {
                return (
                    <IconButton
                        component={Link}
                        to={`/admin/groups/edit/${value.id}`}
                        id={'edit-group-button'}>
                        <Edit fontSize="small" />
                    </IconButton>
                );
            }
        },
        {
            name: 'delete-group',
            render: (model: any) => {
                return (
                    <IconButton
                        id={'delete-group-button'}
                        onClick={() => {
                            confirmDialog({
                                title: 'Supprimer le groupe',
                                content: 'Êtes-vous sûr de vouloir supprimer ce groupe?',
                                onValidate: () => {
                                    Axios.delete(`${endpoint}${model.id}/`).then(
                                        () => {
                                            notif({
                                                content: 'Le groupe a été supprimé avec succès',
                                                type: 'success'
                                            });
                                            document.location.reload();
                                        },
                                        () => {
                                            notif({
                                                content:
                                                    'Une erreur est survenue lors de la suppression',
                                                type: 'error'
                                            });
                                        }
                                    );
                                    closeDialog();
                                },
                                onCancel: () => {
                                    closeDialog();
                                }
                            });
                        }}>
                        <Delete fontSize="small" />
                    </IconButton>
                );
            }
        }
    ];

    let searchFields = [
        {
            name: 'name',
            label: 'Nom groupe',
            options: {
                identifier: 'name',
                source: '/api/groups/',
                label: 'name',
                formatResponse: (group: { name: string }) => {
                    return {
                        label: group.name,
                        value: group.name
                    };
                }
            },
            returnLabel: true
        }
    ];

    return (
        <PagePaper
            title={
                <Grid justifyContent="space-between" alignItems={'center'} container>
                    <Grid item>
                        <StyledTitle className="m-0">
                            {params.action === 'list'
                                ? 'Liste des groupes'
                                : `${params.action === 'add' ? 'Ajouter un' : 'Modifier le'} groupe`}
                        </StyledTitle>
                    </Grid>
                    {params.action === 'list' && (
                        <Grid item>
                            <Link to="/admin/groups/add">
                                <Button variant="contained" id={'add-group-button'}>
                                    <AddCircleIcon sx={{ mr: 1 }} /> Ajouter un groupe
                                </Button>
                            </Link>
                        </Grid>
                    )}
                </Grid>
            }>
            {params.action === 'list' && (
                <>
                    <BSearchForm columns={1} fields={searchFields} setSearchString={setFilters} />
                    <BTable
                        model={model}
                        endpoint={endpoint + filters}
                        fields={listFields}
                        actions={actions}
                        defaultOrder="name"
                    />
                </>
            )}
            <BirdzDialog options={dialogOptions} />
            <BirdzNotif options={notifOptions} />
            {params.action === 'add' && (
                <Item action="add" endpoint={endpoint} fields={formFields} />
            )}
            {params.action === 'edit' && (
                <Item action="edit" endpoint={endpoint} fields={formFields} />
            )}
        </PagePaper>
    );
};

export default Groups;
