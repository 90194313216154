import React from 'react';
import { Outlet } from 'react-router-dom';
import useAutoRedirect from '../../hooks/useAutoRedirect';

const Racsup = () => {
    useAutoRedirect({
        baseUrl: '/racsup',
        targetUrl: '/racsup/primo/list'
    });
    return <Outlet />;
};

export default Racsup;
