import React, { useState } from 'react';
import {
    BirdzDialog,
    useDialog,
    StyledTitle,
    Item,
    ListField,
    FormField,
    useNotif,
    BirdzNotif
} from '@applications-terrains/birdz-react-library';
import { Link } from 'react-router-dom';
import moment from 'moment';
import * as Yup from 'yup';
import Axios from 'axios';
import { Button, Grid, IconButton } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Delete, Edit } from '@mui/icons-material';
import PagePaper from '../Tools/Pages/PagePaper';
import BSearchForm from '../Tools/Search/BSearchForm';
import BTable from '../Tools/Table/BTable';
import TrueFalse from '../Tools/Table/components/TrueFalse';

const Webhooks = (params: any) => {
    const [filters, setFilters] = useState<string>('');

    const { confirmDialog, closeDialog, dialogOptions } = useDialog();
    const { notif, notifOptions } = useNotif();

    const transformItemOnSave = (webhookItem: any) => {
        if (webhookItem) {
            if (webhookItem.attached_user) {
                webhookItem.attached_user = webhookItem.attached_user.value;
            }
        }
        return webhookItem;
    };

    const transformItemOnLoad = (webhookItem: any) => {
        webhookItem.attached_user = {
            id: webhookItem.attached_user,
            name: webhookItem.attached_user__name
        };
        return webhookItem;
    };

    const endpoint = '/api/webhooks/';
    const model = 'webhooks-admin';

    let formFields: FormField[] = [
        {
            name: 'wh_url',
            type: 'url',
            label: 'URL',
            validation: Yup.string()
                .url('Vous devez saisir une url valide')
                .required("Vous devez renseigner l'url du webhook")
        },
        {
            name: 'attached_user',
            type: 'autocomplete',
            label: 'Utilisateur rattaché',
            validation: Yup.object()
                .required("Vous devez renseigner l'utilisateur")
                .typeError("Vous devez renseigner l'utilisateur"),
            options: {
                source: '/api/lists/users/?ordering=name&',
                searchIsMulti: false
            }
        },
        {
            name: 'wh_user',
            type: 'text',
            label: 'Utilisateur',
            validation: Yup.string().required("Vous devez renseigner l'utilisateur")
        },
        {
            name: 'wh_password',
            type: 'password',
            label: 'Password',
            validation: Yup.string().required('Vous devez renseigner le mot de passe')
        },
        {
            name: 'wh_token',
            type: 'text',
            label: 'Token',
            validation: Yup.string().required('Vous devez renseigner le token')
        },
        { name: 'comment', type: 'textarea', label: 'Commentaire' },
        {
            name: 'is_active',
            type: 'radio',
            label: 'Etat',
            options: {
                values: [
                    { value: true, label: 'Actif' },
                    { value: false, label: 'Inactif' }
                ]
            },
            defaultValue: true,
            permissions: ['IS_ACTIVE']
        },
        {
            name: 'updated_at',
            type: 'readonly',
            label: 'Dernière modification',
            transform: (value: any) => {
                let date = value && moment.unix(value).format('DD/MM/YYYY à HH:mm:ss');
                return date || '';
            }
        }
    ];
    let listFields: ListField[] = [
        { name: 'attached_user__name', label: 'Utilisateur', orderable: true },
        { name: 'wh_url', label: 'Url', orderable: true },
        {
            name: 'is_active',
            label: 'Actif',
            className: 'text-center',
            transform: (value: boolean) => {
                return <TrueFalse value={value} />;
            },
            orderable: true
        },
        {
            name: 'updated_at',
            label: 'Date de modification',
            options: { width: '220px' },
            orderable: true,
            transform: (value: any) => {
                return (value && moment.unix(value).format('DD/MM/YYYY à HH:mm:ss')) || '';
            }
        }
    ];
    let actions = [
        {
            name: 'edit',
            render: (value: any) => {
                return (
                    <IconButton
                        id={'edit-webhook-button'}
                        component={Link}
                        to={`/admin/webhooks/edit/${value.id}`}>
                        <Edit fontSize="small" />
                    </IconButton>
                );
            }
        },
        {
            name: 'delete-webhook',
            render: (model: any) => {
                return (
                    <IconButton
                        id={'delete-webhook-button'}
                        onClick={() => {
                            confirmDialog({
                                title: 'Supprimer le webhook',
                                content: 'Êtes-vous sûr de vouloir supprimer ce webhook?',
                                onValidate: () => {
                                    Axios.delete(`${endpoint}${model.id}/`).then(
                                        () => {
                                            notif({
                                                content: 'Le webhook a été supprimé avec succès',
                                                type: 'success'
                                            });
                                            document.location.reload();
                                        },
                                        () => {
                                            notif({
                                                content:
                                                    'Une erreur est survenue lors de la suppression',
                                                type: 'error'
                                            });
                                        }
                                    );
                                    closeDialog();
                                },
                                onCancel: () => {
                                    closeDialog();
                                }
                            });
                        }}>
                        <Delete fontSize="small" />
                    </IconButton>
                );
            }
        }
    ];
    let searchFields = [
        {
            name: 'attached_user',
            label: 'Utilisateur rattaché',
            options: {
                identifier: 'id',
                source: '/api/users/?ordering=name&',
                label: 'name'
            }
        },
        {
            name: 'wh_url',
            label: 'URL',
            options: {
                identifier: 'wh_url',
                source: '/api/webhooks/',
                label: 'wh_url',
                formatResponse: (url: { wh_url: string }) => {
                    return { label: url.wh_url, value: url.wh_url };
                }
            }
        }
    ];

    return (
        <PagePaper
            title={
                <Grid justifyContent="space-between" alignItems={'center'} container>
                    <Grid item>
                        <StyledTitle className="m-0">
                            {params.action === 'list'
                                ? 'Liste des webhooks'
                                : `${params.action === 'add' ? 'Ajouter un ' : 'Modifier le'} webhook`}
                        </StyledTitle>
                    </Grid>
                    {params.action === 'list' && (
                        <Grid item>
                            <Link to="/admin/webhooks/add">
                                <Button id={'add-webhook-button'} variant="contained">
                                    <AddCircleIcon sx={{ mr: 1 }} /> Ajouter un webhook
                                </Button>
                            </Link>
                        </Grid>
                    )}
                </Grid>
            }>
            {params.action === 'list' && (
                <>
                    <BSearchForm columns={2} fields={searchFields} setSearchString={setFilters} />
                    <BTable
                        model={model}
                        endpoint={endpoint + filters}
                        fields={listFields}
                        actions={actions}
                    />
                </>
            )}
            <BirdzDialog options={dialogOptions} />
            <BirdzNotif options={notifOptions} />
            {params.action === 'add' && (
                <Item
                    action="add"
                    endpoint={endpoint}
                    fields={formFields}
                    transformItemOnSave={transformItemOnSave}
                    transformItemOnLoad={transformItemOnLoad}
                />
            )}
            {params.action === 'edit' && (
                <Item
                    action="edit"
                    endpoint={endpoint}
                    fields={formFields}
                    transformItemOnSave={transformItemOnSave}
                    transformItemOnLoad={transformItemOnLoad}
                />
            )}
        </PagePaper>
    );
};

export default Webhooks;
