import React, { useEffect, useState } from 'react';
import { SearchField } from '@applications-terrains/birdz-react-library';
import CampaignsList from './CampaignsList';
import { getAvailableSearchFilters, searchFields } from '../search.config';
import { useTranslation } from 'react-i18next';
import './CampaignsList.scss';
import { useSearchCampaignsAndPurposesContext } from '../SearchCampaignsAndPurposesContext';
import BSearchForm from '../../../Tools/Search/BSearchForm';

const Campaigns = () => {
    const [campaignsSearchFields, setCampaignsSearchFields] = useState<SearchField[]>([]);
    const { t } = useTranslation();

    const { setCampaignsFiltersString, setCampaignsValues, campaignsValues } =
        useSearchCampaignsAndPurposesContext();

    // 'campaign' property set by default in search.config.ts, but 'id' is necessary here (campaign is for searchPurposes)
    const formatFilterString = (str: string) => str.replace('&campaignId=', '&id=');

    useEffect(() => {
        if (campaignsSearchFields.length === 0) {
            let campaignsSearchFields = [...searchFields].map((searchField) => {
                let name = searchField.name;
                if (name.startsWith('campaign__')) {
                    name = searchField.name.replace('campaign__', '');
                }
                return { ...searchField, name };
            });
            const availableSearchFilters = getAvailableSearchFilters(t);

            // Add specific purposes search fields
            const createdByFilterPosition = campaignsSearchFields
                .map((searchField) => searchField.name)
                .indexOf('created_by__in');
            campaignsSearchFields.splice(
                createdByFilterPosition + 1,
                0,
                availableSearchFilters['campaignState']
            );
            setCampaignsSearchFields(campaignsSearchFields);
        }
    }, [campaignsSearchFields, t]);

    return (
        <>
            <BSearchForm
                fields={campaignsSearchFields}
                defaultValues={campaignsValues['formValue']}
                setSearchString={(filterString) => {
                    setCampaignsFiltersString(formatFilterString(filterString));
                }}
                setSearchValues={setCampaignsValues}
                columns={4}
                className='pt-24'
            />
            <CampaignsList />
        </>
    );
};

export default Campaigns;
