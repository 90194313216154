import React from 'react';
import { Box, Divider, IconButton, Popover, Tooltip } from '@mui/material';
import PaletteIcon from '@mui/icons-material/Palette';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import GradientIcon from '@mui/icons-material/Gradient';
import { SidebarBackgroundColor, SidebarTheme } from './types';
import { backgroundColors, themeColors } from './const';
import { omit } from 'lodash';

const ThemeSelector = ({
    theme,
    setTheme,
    sidebarIsExpanded,
    bgColor,
    setBgColor
}: {
    bgColor: SidebarBackgroundColor;
    setBgColor: (value: SidebarBackgroundColor) => void;
    theme: SidebarTheme;
    setTheme: (value: SidebarTheme) => void;
    sidebarIsExpanded: boolean;
}) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'colors-popover' : undefined;

    const fixedHighlightColor = themeColors['base'];

    return (
        <Box
            className="theme-selector"
            sx={{ mb: sidebarIsExpanded ? 2 : 7, ml: sidebarIsExpanded ? 6 : 1, pt: 1 }}>
            <Tooltip title="choix du thème" enterDelay={200}>
                <IconButton onClick={handleClick}>
                    <PaletteIcon fontSize="small" sx={{ color: themeColors[theme] }} />
                </IconButton>
            </Tooltip>
            <Popover
                elevation={10}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right'
                }}
                sx={{
                    '& .MuiPaper-root': {
                        backgroundColor: backgroundColors[bgColor]
                    }
                }}>
                <Box p={2} className="d-flex flex-column justify-content-center">
                    <Box className="d-flex align-items-center" sx={{ gap: '0.5rem' }}>
                        <GradientIcon
                            fontSize="small"
                            sx={{
                                color: fixedHighlightColor,
                                fontWeight: 'bold',
                                mr: 1
                            }}
                        />
                        {Object.entries(backgroundColors).map(([key, value]) => (
                            <Box
                                key={key}
                                sx={{
                                    width: '15px',
                                    height: '15px',
                                    backgroundColor: value,
                                    borderRadius: '50%',
                                    cursor: 'pointer',
                                    border: `2px solid ${bgColor === key ? 'white' : 'transparent'}`,
                                    transition:
                                        'background-color 0.5s ease-in-out, border 0.5s ease-in-out',
                                    '&:hover': {
                                        filter: 'brightness(1.2)'
                                    }
                                }}
                                onClick={() => {
                                    setBgColor(key as SidebarBackgroundColor);
                                    if (theme === 'base') {
                                        setTheme('base-darker');
                                    }
                                    else if (theme === 'base-darker') {
                                        setTheme('base');
                                    }
                                }}
                            />
                        ))}
                    </Box>
                    <Divider sx={{ my: 2, backgroundColor: fixedHighlightColor }} />
                    <Box className="d-flex align-items-center" sx={{ gap: '0.5rem' }}>
                        <HighlightAltIcon
                            fontSize="small"
                            sx={{ color: fixedHighlightColor, fontWeight: 'bold', mr: 1 }}
                        />
                        {Object.entries(
                            omit(themeColors, [bgColor === 'base' ? 'base-darker' : 'base'])
                        ).map(([key, value]) => {
                            return (
                                <Box
                                    key={key}
                                    sx={{
                                        width: '15px',
                                        height: '15px',
                                        backgroundColor: value,
                                        borderRadius: '50%',
                                        cursor: 'pointer',
                                        border: `2px solid ${theme === key ? 'white' : 'transparent'}`,
                                        transition:
                                            'background-color 0.5s ease-in-out, border 0.5s ease-in-out',
                                        '&:hover': {
                                            filter: 'brightness(1.2)'
                                        }
                                    }}
                                    onClick={() => {
                                        setTheme(key as SidebarTheme);
                                    }}
                                />
                            );
                        })}
                    </Box>
                </Box>
            </Popover>
        </Box>
    );
};

export default ThemeSelector;
