import {
    ExportButton as ExportButtonType,
    ListField
} from '@applications-terrains/birdz-react-library';
import {
    Box,
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import useFetchData from '../../../hooks/useFetchData';
import PaginationComponent from '../PaginationComponent';
import { ReactNode, useEffect, useState } from 'react';
import PageSizeSelector, { PaginationType } from '../PageSizeSelector';
import EmptyPage from '../Pages/EmptyPage';
import { useFetchContext } from '../../../contexts/FetchContext';
import './b-table.scss';
import { isEqual, omit } from 'lodash';
import { getPaginatedData, getResultString, normalizeUrl } from './const';
import {
    BTableRow,
    ClearSortButton,
    ExportButtonWrapper,
    HeaderCell,
    SelectAllButton,
    ToggleSeeSelectedButton
} from './components/TableComponents';
import { v4 as uuidv4 } from 'uuid';
import { useSafeTimeout } from '../../../hooks/useSafeTimeout';
import { useGlobalContext } from '../../../contexts/GlobalContext';
import { themeColors } from '../../Sidebar/const';

// WeakMap pour stocker les IDs de manière persistante
const rowIdMap = new WeakMap<any, string>();

export type TableRowType = { internalIdInTable: string; [key: string]: any };
export type ArrayFieldType = ListField & { shortLabel?: string; labelIcon?: ReactNode };

type TableProps = {
    fields: ArrayFieldType[];
    actions?: any[];
    endpoint: string;
    model: string;
    exportButtons?: (ExportButtonType & { id: string })[];
    refresh?: boolean;
    pageSize?: number;
    defaultOrder?: string;
    headerInfo?: string;
    extraComponent?: ReactNode;
    sx?: { [key: string]: any };
    onSelectItems?: (selectedItems: TableRowType[]) => void;
    selectedItems?: TableRowType[];
    unpaginatedEndpoint?: boolean;
    // [key: string]: any;
};

const BTable = ({
    fields,
    actions,
    endpoint,
    model,
    exportButtons,
    refresh = false,
    pageSize,
    defaultOrder = '-created_at',
    headerInfo,
    sx,
    extraComponent,
    onSelectItems,
    selectedItems,
    unpaginatedEndpoint
}: TableProps) => {
    const [internalLoading, setInternalLoading] = useState(true);
    const [seeSelectedOnly, setSeeSelectedOnly] = useState(false);
    const [order, setOrder] = useState<string[]>([defaultOrder]);
    const [pagination, setPagination] = useState<PaginationType>({
        pageSize: pageSize || 25, // Default value
        currentPage: 1,
        totalItems: null
    });

    const { theme } = useGlobalContext();

    useEffect(() => {
        if (!pageSize) {
            const savedPageSize = localStorage.getItem('pageSize');
            savedPageSize &&
                pagination.pageSize !== parseInt(savedPageSize) &&
                setPagination((prev) => ({ ...prev, pageSize: parseInt(savedPageSize) }));
        }
    }, [pagination, pageSize]);

    const { dataRefs, setDataRefs } = useFetchContext();

    const fullEndpoint = normalizeUrl(endpoint, pagination, order, unpaginatedEndpoint);

    const enableFetch =
        !(model in dataRefs) || (model in dataRefs && dataRefs[model].endpoint !== fullEndpoint);

    const { isLoading, error, refetch, isFetched } = useFetchData({
        key: model,
        endpoint: fullEndpoint,
        enableFetch
    });

    useEffect(() => {
        selectedItems?.length === 0 && seeSelectedOnly && setSeeSelectedOnly(false);
    }, [seeSelectedOnly, selectedItems]);

    const setSafeTimeout = useSafeTimeout();

    //ensure a loader is always shown on page change, and cancels it 300ms after data has arrived
    useEffect(() => {
        (pagination.totalItems !== null || isFetched) &&
            setSafeTimeout(() => {
                setInternalLoading(false);
            }, 300);
    }, [pagination, isFetched, setSafeTimeout]);

    useEffect(() => {
        if (refresh) {
            refetch();
            setInternalLoading(true);
            setPagination((pagination) => ({ ...pagination, currentPage: 1, totalItems: null }));
            model in dataRefs && setDataRefs(omit(dataRefs, [model]));
        }
    }, [refresh, refetch, model, dataRefs, setDataRefs]);

    useEffect(() => {
        const pageSizeLocalStorage = localStorage.getItem('pageSize');
        const size = pageSize
            ? pageSize
            : pageSizeLocalStorage
              ? parseInt(pageSizeLocalStorage)
              : pagination.pageSize;
        if (
            model in dataRefs &&
            dataRefs[model]?.count &&
            dataRefs[model]?.count !== pagination.totalItems
        ) {
            setPagination({ ...pagination, pageSize: size, totalItems: dataRefs[model]?.count });
        }
    }, [dataRefs, pagination, model, pageSize]);

    const dataWithIds: TableRowType[] = dataRefs[model]?.results?.map((row: any) => {
        // Rechercher dans selectedItems un objet correspondant
        const matchingItem = selectedItems?.find((selectedRow: any) =>
            isEqual(omit(selectedRow, ['internalIdInTable']), row)
        );

        // Si un objet correspondant est trouvé, réutiliser son internalIdInTable
        if (matchingItem) {
            rowIdMap.set(row, matchingItem.internalIdInTable); // Mettre à jour la WeakMap
            return {
                ...row,
                internalIdInTable: matchingItem.internalIdInTable
            };
        }

        // Si l'ID existe déjà dans la WeakMap, on le réutilise
        if (rowIdMap.has(row)) {
            return {
                ...row,
                internalIdInTable: rowIdMap.get(row)
            };
        }

        // Sinon, on génère un nouvel ID et on le stocke dans la WeakMap
        const newId = row.id ?? uuidv4();
        rowIdMap.set(row, newId);

        return {
            ...row,
            internalIdInTable: newId
        } as TableRowType;
    });

    const rows = seeSelectedOnly
        ? getPaginatedData(selectedItems as any[], pagination)
        : dataWithIds
          ? dataWithIds.length > pagination.pageSize
              ? getPaginatedData(dataWithIds, pagination)
              : dataWithIds
          : [];

    const getDefaultOrderCol = fields.find((el) => {
        return el.name === defaultOrder || `-${el.name}` === defaultOrder;
    });

    return (
        <Paper
            sx={{
                position: 'relative',
                border: '1px solid rgba(224, 224, 224, 1)',
                boxSizing: 'border-box',
                p: 2,
                height: '100%',
                ...sx
            }}
            className="d-flex flex-column table-container"
            elevation={0}>
            {(isLoading || internalLoading) && (
                <Box
                    className="d-flex align-items-center justify-content-center"
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                        zIndex: 1
                    }}>
                    <CircularProgress sx={{color: themeColors[theme] || 'inherit'}} />
                </Box>
            )}
            {dataRefs[model]?.count === 0 ? (
                <EmptyPage message="Aucun résultat" className="transitioned" />
            ) : error ? (
                <EmptyPage message="Oups... Une erreur est survenue" className="transitioned" />
            ) : (
                <>
                    <Box
                        className="w-100 d-flex align-items-center justify-content-between transitioned"
                        sx={{
                            pl: 4,
                            pr: 2,
                            color: '#666666',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            boxSizing: 'border-box',
                            fontSize: '0.9rem',
                            height: '48px'
                        }}>
                        <Box className="d-flex align-items-center" gap={1}>
                            {onSelectItems && selectedItems && (
                                <SelectAllButton
                                    selected={selectedItems.length > 0}
                                    setSelected={() =>
                                        onSelectItems(selectedItems.length > 0 ? [] : dataWithIds)
                                    }
                                />
                            )}
                            {getResultString(pagination)}{' '}
                            {onSelectItems && selectedItems !== undefined && (
                                <Box>
                                    {`( ${selectedItems?.length} sélectionné${selectedItems?.length > 1 ? 's' : ''}`}
                                    {selectedItems?.length > 0 &&
                                        selectedItems?.length !== dataWithIds?.length && (
                                            <ToggleSeeSelectedButton
                                                selected={seeSelectedOnly}
                                                setSelected={setSeeSelectedOnly}
                                            />
                                        )}
                                    {' )'}
                                </Box>
                            )}
                        </Box>
                        <Box className="d-flex align-items-center" mr={1}>
                            {headerInfo && (
                                <Box className="d-flex align-items-center">
                                    <Box sx={{ whiteSpace: 'nowrap' }}>{headerInfo}</Box>
                                    <Box sx={{ ml: 1.5, mr: 0.5 }}>-</Box>
                                </Box>
                            )}
                            {exportButtons && exportButtons.length > 0 && (
                                <Box className="d-flex align-items-center">
                                    {exportButtons.map((exportButton, index) => (
                                        <ExportButtonWrapper
                                            key={exportButton.name + index}
                                            exportButton={exportButton}
                                            endpoint={exportButton.requestEndpoint || fullEndpoint}
                                        />
                                    ))}
                                </Box>
                            )}
                            {rows.length > 1 &&
                                !seeSelectedOnly &&
                                order.length > 0 &&
                                !(order.length === 1 && !getDefaultOrderCol) && (
                                    <ClearSortButton order={order} setOrder={setOrder} />
                                )}
                        </Box>
                    </Box>

                    <TableContainer
                        className="transitioned"
                        sx={{
                            mt: 4,
                            borderTop: '1px solid rgba(224, 224, 224, 1)',
                            boxSizing: 'border-box'
                        }}>
                        {extraComponent}
                        <Table
                            sx={
                                extraComponent
                                    ? {
                                          mt: 2,
                                          borderTop: '1px solid rgba(224, 224, 224, 1)',
                                          boxSizing: 'border-box'
                                      }
                                    : {}
                            }
                            size="small"
                            stickyHeader>
                            <TableHead>
                                <TableRow>
                                    {fields.map((el) => {
                                        const element =
                                            rows.length <= 1 || seeSelectedOnly
                                                ? omit(el, ['orderable'])
                                                : el;
                                        return (
                                            <TableCell
                                                sx={{ py: 2 }}
                                                className="header-cell"
                                                key={el.label + 'head'}>
                                                <HeaderCell
                                                    element={element}
                                                    order={order}
                                                    setOrder={setOrder}
                                                    defaultOrder={defaultOrder}
                                                    defaultOrderCol={
                                                        getDefaultOrderCol !== undefined
                                                    }
                                                />
                                            </TableCell>
                                        );
                                    })}
                                    {actions && actions?.length > 0 && (
                                        <TableCell sx={{ textAlign: 'right', color: '#666666' }}>
                                            Actions
                                        </TableCell>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody className="" sx={{ overflow: 'auto' }}>
                                {rows.map((row: TableRowType) => {
                                    const isSelected = selectedItems?.find((selected) => {
                                        return row.internalIdInTable === selected.internalIdInTable;
                                    });
                                    return (
                                        <BTableRow
                                            key={row.internalIdInTable}
                                            row={row}
                                            fields={fields}
                                            actions={actions}
                                            isSelected={isSelected !== undefined}
                                            onSelectItems={onSelectItems}
                                            selectedItems={selectedItems}
                                        />
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {dataRefs[model]?.count > 5 && !seeSelectedOnly && (
                        <Box className="w-100 d-flex flex-grow-1 flex-column">
                            <Box
                                className="BTable-footer d-flex justify-content-between align-items-center"
                                sx={{
                                    pt: 2,
                                    mt: 'auto'
                                }}>
                                <PaginationComponent
                                    pagination={pagination}
                                    setPagination={(val) => {
                                        setPagination(val);
                                    }}
                                />
                                {!pageSize && (
                                    <PageSizeSelector
                                        pagination={pagination}
                                        setPagination={setPagination}
                                    />
                                )}
                            </Box>
                        </Box>
                    )}
                </>
            )}
        </Paper>
    );
};

export default BTable;
