import { PaginationType } from '../PageSizeSelector';

export function normalizeUrl(
    urlWithFilters: string,
    pagination: { pageSize: number; currentPage: number },
    order: string[],
    unpaginatedEndpoint?:boolean
): string {
    let normalizedUrl = urlWithFilters;

    // S'assurer que le '?' est présent et correctement placé
    if (!normalizedUrl.includes('?')) {
        // Si l'URL contient des filtres sans '?', remplacer le premier '&' par '?'
        const firstAmpIndex = normalizedUrl.indexOf('&');
        if (firstAmpIndex !== -1) {
            normalizedUrl =
                normalizedUrl.slice(0, firstAmpIndex) +
                '?' +
                normalizedUrl.slice(firstAmpIndex + 1);
        } else {
            // Si aucun '&' n'est trouvé, ajouter '?' à la fin
            normalizedUrl += '?';
        }
    }

    // Supprimer tout '&' qui suit directement '?'
    normalizedUrl = normalizedUrl.replace(/\?&/, '?');

    // Ajouter les paramètres 'ordering' s'ils existent
    if (order?.length) {
        const separator = normalizedUrl.includes('?') && !normalizedUrl.endsWith('?') ? '&' : '';
        normalizedUrl += `${separator}ordering=${order.join(',')}`;
    }

    // Ajouter les paramètres de pagination
    const paginationParams = unpaginatedEndpoint ? `size=9999` : `${`size=${pagination.pageSize}`}&page=${pagination.currentPage}`;
    const separator = normalizedUrl.includes('?') && !normalizedUrl.endsWith('?') ? '&' : '';
    normalizedUrl += `${separator}${paginationParams}`;

    return normalizedUrl;
}

export const getPaginatedData = (data: any[], pagination: PaginationType): any[] => {
    const { pageSize, currentPage } = pagination;

    // Calculer l'index de début et de fin
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    // Extraire la portion du tableau correspondant à la page actuelle
    return data.slice(startIndex, endIndex);
};

export const getResultString = (pagination: PaginationType): string =>
    `résultat${pagination.totalItems && pagination.totalItems <= 1 ? '' : 's'} ${pagination.currentPage * pagination.pageSize - (pagination.pageSize - 1)} ${
        pagination.totalItems && pagination.totalItems > 1
            ? `- 
    ${pagination.currentPage * pagination.pageSize < pagination.totalItems ? pagination.currentPage * pagination.pageSize : pagination.totalItems}`
            : ''
    } / ${pagination.totalItems}`;
