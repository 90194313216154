import React, { useState } from 'react';
import { StyledTitle } from '@applications-terrains/birdz-react-library';
import { Grid } from '@mui/material';
import PagePaper from '../Tools/Pages/PagePaper';
import BSearchForm from '../Tools/Search/BSearchForm';
import BTable from '../Tools/Table/BTable';

const Contracts = () => {
    const [filters, setFilters] = useState<string>('');
    const model = 'contracts-admin';
    const endpoint = '/api/contracts/';

    return (
        <PagePaper
            title={
                <Grid justifyContent="space-between" alignItems={'center'} container>
                    <Grid item>
                        <StyledTitle className="m-0">Liste des contrats</StyledTitle>
                    </Grid>
                </Grid>
            }>
            <>
                <BSearchForm
                    columns={1}
                    fields={[
                        {
                            name: 'code',
                            label: 'Code contrat',
                            type: 'text'
                        }
                    ]}
                    setSearchString={setFilters}
                />
                <BTable
                    model={model}
                    endpoint={endpoint + filters}
                    fields={[
                        { name: 'code', label: 'Code' },
                        { name: 'label', label: 'Contrat' }
                    ]}
                    defaultOrder="code"
                    unpaginatedEndpoint
                />
            </>
        </PagePaper>
    );
};

export default Contracts;
