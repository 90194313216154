import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/browser';
import './index.css';
import App from './App';
import './i18n';
import axios, { AxiosRequestConfig } from 'axios';
import * as Cookies from 'es-cookie';
import '@applications-terrains/birdz-react-library/dist/esm/styles.css';
import {
    AuthService,
    birdzReactLibrary,
    WebSocketService
} from '@applications-terrains/birdz-react-library';

if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
        dsn: 'https://785c4ca34aab48d7a52c20305a398be7@sentry.birdz.com/3'
    });
}

let currentUrl = document.location.href;
let previousUrl = document.location.href;

const observeUrlChange = () => {
    let oldHref = document.location.href;
    const body = document.querySelector('body');
    const observer = new MutationObserver(() => {
        if (oldHref !== document.location.href) {
            currentUrl = document.location.href;
            previousUrl = oldHref;
            oldHref = document.location.href;
        }
    });
    observer.observe(body as any, { childList: true, subtree: true });
};

window.onload = observeUrlChange;

const pageRequests: {
    [key: string]: { controller: AbortController; url: string; message: string }[];
} = {};

const clearPreviousRequests = (url: string) => {
    if (pageRequests[url]) {
        pageRequests[url].forEach((el) => el.controller.abort(el.message));
        delete pageRequests[url];
    }
};

axios.interceptors.response.use(
    (response) => {
        if (pageRequests[previousUrl]) delete pageRequests[previousUrl];
        if (pageRequests[currentUrl]) {
            const configUrl = (response as { config: { url: string } })?.config?.url;
            const stillPending = pageRequests[currentUrl].filter(
                (el) => el.url !== configUrl && !el.controller.signal.aborted
            );
            pageRequests[currentUrl] = stillPending;
        }
        return response;
    },
    async (error) => {
        if (axios.isCancel(error)) {
            const message =
                (error as any)?.config?.signal?.reason || error.message || 'Request was canceled';
            console.log(message);
            return Promise.resolve(message);
        }

        return Promise.reject(error);
    }
);

axios.interceptors.request.use((config: AxiosRequestConfig) => {
    config.headers = {
        ...config.headers,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };

    const csrfToken = Cookies.get('csrftoken');
    if (csrfToken) {
        config.headers['X-CSRFToken'] = csrfToken;
    }

    if (!config.signal) {
        const controller = new AbortController();
        const customMessage = `Request to ${config.url} was canceled because the URL changed.`;

        if (!pageRequests[currentUrl]) {
            pageRequests[currentUrl] = [];
        }

        // Filter out any previous requests that were aborted
        pageRequests[currentUrl] = pageRequests[currentUrl].filter(
            (el) => !el.controller.signal.aborted
        );

        // Add the new request to the current URL's requests
        pageRequests[currentUrl].push({
            controller,
            url: config.url || '',
            message: customMessage
        });

        // Clear previous URL's requests if the URL has changed
        if (currentUrl !== previousUrl) {
            clearPreviousRequests(previousUrl);
        }

        return {
            ...config,
            signal: controller.signal
        };
    } else {
        return config;
    }
});

const auth: any = new AuthService();

const wsService = new WebSocketService(
    window.location.hostname === 'localhost' ? 'bidir-dev.birdz.com' : window.location.host
);
birdzReactLibrary.webSocketService = wsService;
birdzReactLibrary.authService = auth;
export const authService = auth;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);
