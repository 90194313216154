import React, { PropsWithChildren, useEffect, useState } from 'react';
import { SearchFormValue } from '@applications-terrains/birdz-react-library';
import { Box, Button, IconButton, Accordion, AccordionDetails } from '@mui/material';
import { Formik, Form } from 'formik';
import './b-search-form.scss';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { getFilterString, removeFalsyValues } from '../../../utils';
import BSearchField from './Fields/BSearchField';
import { isArray } from 'lodash';
import { BSearchFieldType } from './types';

const FormBox = (props: PropsWithChildren & { columns?: number }) => {
    return (
        <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: `repeat(${props.columns || 3}, 1fr)`,
                gridGap: 16
            }}>
            {props.children}
        </Box>
    );
};

const BSearchForm = ({
    fields,
    defaultValues,
    setSearchString,
    setSearchValues,
    columns,
    className,
    noBorder
}: {
    fields: BSearchFieldType[];
    defaultValues?: SearchFormValue['formValue'];
    setSearchString: (search: string) => void;
    setSearchValues?: (values: SearchFormValue['formValue']) => void;
    columns?: number;
    className?: string;
    noBorder?: boolean;
}) => {
    const [expanded, setExpanded] = useState(false);
    const [values, setValues] = useState(defaultValues || {});
    const [hasSeeMoreFields, setHasSeeMoreFields] = useState(false);

    // determine if form should be expanded from mount
    useEffect(() => {
        const seeMoreFields = fields.filter((field) => field.seeMore);
        setHasSeeMoreFields(seeMoreFields.length > 0);
        const fieldsWithValueInForm: string[] = [];
        seeMoreFields.forEach((field) => {
            if (field.name in values) {
                const value = values[field.name];
                if ((isArray(value) && value.length > 0) || (!isArray(value) && value)) {
                    fieldsWithValueInForm.push(field.name);
                }
            }
        });
        setExpanded(fieldsWithValueInForm.length > 0);
    }, [fields, values]);

    // Map initial values from the context's formValue
    const initialValues = {
        ...fields.reduce(
            (acc, field) => {
                acc[field.name] = values[field.name] ?? (field.options?.values ? [] : '');
                return acc;
            },
            {} as Record<string, any>
        )
    };

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={(values) => {
                setSearchString(getFilterString(removeFalsyValues(values)));
                setSearchValues &&
                    setSearchValues({
                        formValue: values,
                        filterValues: values
                    });
                setValues(values);
            }}>
            {({ values, setFieldValue }) => {
                return (
                    <Form>
                        <Box
                            className={`form-container ${className || ''}`}
                            sx={{
                                borderBottom: '1px solid',
                                borderColor: noBorder ? 'transparent' : 'divider',
                                py: 1,
                                mb: 3
                            }}>
                            <FormBox columns={columns}>
                                {fields
                                    .filter((el) => !el.seeMore)
                                    .map((field) => (
                                        <BSearchField
                                            key={field.name}
                                            field={field}
                                            value={values[field.name]}
                                            setFieldValue={setFieldValue}
                                        />
                                    ))}
                            </FormBox>
                            <Accordion
                                elevation={0}
                                expanded={expanded}
                                onChange={() => setExpanded(!expanded)}
                                sx={{
                                    '::before': {
                                        display: 'none'
                                    }
                                }}>
                                <Box></Box>
                                <AccordionDetails sx={{ p: 0 }}>
                                    <FormBox columns={columns}>
                                        {fields
                                            .filter((el) => el.seeMore)
                                            .map((field) => (
                                                <BSearchField
                                                    key={field.name}
                                                    field={field}
                                                    value={values[field.name]}
                                                    setFieldValue={setFieldValue}
                                                />
                                            ))}
                                    </FormBox>
                                </AccordionDetails>
                            </Accordion>
                            <Box
                                className="buttons-container w-100 d-flex justify-content-between align-items-center"
                                sx={{
                                    my: 2
                                }}>
                                {hasSeeMoreFields ? (
                                    <IconButton
                                        title={`${expanded ? 'moins' : 'plus'} de filtres`}
                                        onClick={() => setExpanded(!expanded)}>
                                        {expanded ? <RemoveCircleIcon /> : <AddCircleIcon />}
                                    </IconButton>
                                ) : (
                                    <Box></Box>
                                )}
                                <Box className="d-flex" sx={{ gap: 1 }}>
                                    <Button
                                        onClick={() => {
                                            setSearchString('');
                                            setSearchValues &&
                                                setSearchValues({
                                                    formValue: {},
                                                    filterValues: {}
                                                });
                                            setValues({});
                                        }}
                                        variant="outlined">
                                        Reinitialiser
                                    </Button>
                                    <Button type="submit" variant="contained">
                                        Rechercher
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default BSearchForm;
