import React from 'react';
import {
    useDialog,
    BirdzDialog,
    Item,
    FormField,
    StyledTitle,
    useNotif,
    BirdzNotif
} from '@applications-terrains/birdz-react-library';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Protocol as ProtocolType } from '../types';
import Axios from 'axios';
import { Button, Grid, IconButton } from '@mui/material';
import { AddCircle, Delete, Edit } from '@mui/icons-material';
import { authService } from '../..';
import PagePaper from '../Tools/Pages/PagePaper';
import BSearchForm from '../Tools/Search/BSearchForm';
import BTable from '../Tools/Table/BTable';
import TrueFalse from '../Tools/Table/components/TrueFalse';
import { useFetchContext } from '../../contexts/FetchContext';

interface ProtocolProps {
    action: 'list' | 'add' | 'edit';
}

const Protocol = ({ action }: ProtocolProps) => {
    const model = 'protocols';
    const endpoint = '/api/protocols/';
    const [filters, setFilters] = React.useState('');
    const { confirmDialog, closeDialog, dialogOptions } = useDialog();
    const { notif, notifOptions } = useNotif();
    const { dataRefs } = useFetchContext();

    let formFields: FormField[] = [
        { name: 'name', type: 'text', label: 'Nom' },
        {
            name: 'technical_code',
            type: 'text',
            label: 'Code technique'
        },
        {
            name: 'operator_code',
            type: 'text',
            label: 'Code opérateur'
        },
        {
            name: 'is_active',
            type: 'radio',
            label: 'Etat',
            options: {
                values: [
                    { value: true, label: 'Actif' },
                    { value: false, label: 'Inactif' }
                ]
            },
            defaultValue: true,
            permissions: ['IS_ACTIVE']
        },
        {
            name: 'updated_at',
            type: 'readonly',
            label: 'Dernière modification',
            transform: (value: any) => {
                let date = value && moment.unix(value).format('DD/MM/YYYY à HH:mm:ss');
                return date || '';
            }
        }
    ];
    let listFields = [
        { name: 'name', label: 'Nom', orderable: true },
        {
            name: 'technical_code',
            label: 'Code protocole',
            options: { width: '180px' },
            orderable: true
        },
        {
            name: 'is_active',
            label: 'Actif',
            className: 'text-center',
            options: { width: '100px' },
            transform: (value: boolean) => <TrueFalse value={value} />,
            orderable: true
        },
        {
            name: 'updated_at',
            label: 'Date de modification',
            options: { width: '220px' },
            orderable: true,
            transform: (value: any) => {
                return (value && moment.unix(value).format('DD/MM/YYYY à HH:mm:ss')) || '';
            }
        }
    ];
    let searchFields = [
        {
            name: 'name',
            label: 'Nom protocol',
            options: {
                values: dataRefs[model]?.results?.map((protocol: any) => ({
                    value: protocol.name,
                    label: protocol.name
                }))
            }
        }
    ];
    let actions = [
        {
            name: 'edit',
            label: 'Modifier le protocole',
            render: (protocol: ProtocolType) => {
                return (
                    <IconButton
                        id={'protocol-edit-button'}
                        component={Link}
                        to={`/models/parameters/protocols/edit/${protocol.id}`}>
                        <Edit fontSize="small" />
                    </IconButton>
                );
            }
        },
        {
            name: 'delete-protocol',
            render: (protocol: ProtocolType) => {
                return (
                    <IconButton
                        id={'protocol-delete-button'}
                        onClick={() => {
                            confirmDialog({
                                title: 'Supprimer le protocole',
                                content: 'Êtes-vous sûr de vouloir supprimer ce protocole?',
                                onValidate: () => {
                                    Axios.delete(`${endpoint}${protocol.id}/`).then(
                                        () => {
                                            notif({
                                                content: 'Le protocole a été supprimé avec succès',
                                                type: 'success'
                                            });
                                            document.location.reload();
                                        },
                                        () => {
                                            notif({
                                                content:
                                                    'Une erreur est survenue lors de la suppression',
                                                type: 'error'
                                            });
                                        }
                                    );
                                    closeDialog();
                                },
                                onCancel: () => {
                                    closeDialog();
                                }
                            });
                        }}>
                        <Delete fontSize="small" />
                    </IconButton>
                );
            }
        }
    ];

    return (
        <PagePaper
            title={
                <Grid justifyContent="space-between" alignItems={'center'} container>
                    <Grid item>
                        <StyledTitle className="m-0">
                            {action === 'list'
                                ? 'Liste des protocoles'
                                : `${action === 'add' ? 'Ajouter un' : 'Modifier le'} protocole`}
                        </StyledTitle>
                    </Grid>
                    {authService.canAccess('WRITE_PARAMETERS') && action === 'list' && (
                        <Grid item>
                            <Link to="/models/parameters/protocols/add">
                                <Button id={'protocol-add-button'} variant="contained">
                                    <AddCircle sx={{ mr: 1 }} /> Ajouter un protocole
                                </Button>
                            </Link>
                        </Grid>
                    )}
                </Grid>
            }>
            {action === 'list' && (
                <>
                    <BSearchForm columns={1} fields={searchFields} setSearchString={setFilters} />
                    <BTable
                        model={model}
                        endpoint={endpoint + filters}
                        fields={listFields}
                        actions={authService.canAccess('WRITE_PARAMETERS') ? actions : []}
                        defaultOrder="name"
                    />
                </>
            )}
            <BirdzDialog options={dialogOptions} />
            <BirdzNotif options={notifOptions} />
            {action === 'add' && (
                <Item action="add" endpoint={endpoint} fields={formFields} name="" />
            )}
            {action === 'edit' && (
                <Item action="edit" endpoint={endpoint} fields={formFields} name="" />
            )}
        </PagePaper>
    );
};

export default Protocol;
