import React from 'react';
import { useLocation } from 'react-router';
import Campaigns from './Lists/Campaigns/Campaigns';
import Purposes from './Lists/Purposes/Purposes';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BirdzTitle } from '@applications-terrains/birdz-react-library';
import { Tab, Tabs } from '@mui/material';
import PagePaper from '../Tools/Pages/PagePaper';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

enum TabIndex {
    CAMPAIGNS = 0,
    PURPOSES = 1
}

export const SentList = () => {
    const query = useQuery();
    const getTabQuery = () => {
        return query.get('tab');
    };
    const tabQuery = getTabQuery();
    const { pathname, search } = useLocation();
    const navigate = useNavigate();

    const [valueTab, setValueTab] = React.useState<null | number>(null);

    useEffect(() => {
        pathname === '/sendings/sent-list' && navigate('?tab=campaigns');
    }, [pathname, navigate]);

    useEffect(() => {
        const newValueTab = TabIndex[tabQuery?.toUpperCase() as keyof typeof TabIndex];
        if (valueTab !== newValueTab && tabQuery) {
            setValueTab(TabIndex[tabQuery?.toUpperCase() as keyof typeof TabIndex]);
        }
    }, [search, tabQuery, valueTab]);

    const handleChangeTab = (event: any, newValueTab: number) => {
        if (valueTab === newValueTab) {
            return;
        } else {
            const params = new URLSearchParams();
            params.set('tab', TabIndex[newValueTab].toLowerCase());
            navigate({ pathname: '', search: params.toString() });
            setValueTab(newValueTab);
        }
    };

    return (
        <PagePaper title={<BirdzTitle className='m-0'>Récapitulatif des envois</BirdzTitle>}>
            {valueTab !== null && (
                <Tabs
                    value={valueTab}
                    onChange={handleChangeTab}
                    sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tab label="Liste des campagnes" />
                    <Tab label="Liste des intentions" />
                </Tabs>
            )}
            <>
                {valueTab === TabIndex.CAMPAIGNS && <Campaigns />}
                {valueTab === TabIndex.PURPOSES && <Purposes />}
            </>
        </PagePaper>
    );
};
export default SentList;
