import React, { useEffect, useState } from 'react';
import LinkContractsList from '../Racsup/LinkContractsList';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { PiFileXls } from 'react-icons/pi';
import ContractsDropdown from '../Tools/Contracts/ContractsDropdown';
import { Box, Typography } from '@mui/material';
import BTable, { TableRowType } from '../Tools/Table/BTable';

export const OptimisationContractsSelection = () => {
    const [filterContracts, setFilterContracts] = useState<string[]>([]);
    const [selectedContracts, setSelectedContracts] = useState<TableRowType[]>([]);
    const [refresh, setRefresh] = useState<boolean>(false);

    const endpoint = '/api/optimisation/contracts/';
    const model = 'optimisation-contracts';
    const navigate = useNavigate();

    useEffect(() => {
        refresh && setRefresh(false);
    }, [refresh]);

    const codes = selectedContracts.map((contract) => contract.code);

    return (
        <>
            <Box
                sx={{
                    mb: 1,
                    border: '1px solid',
                    borderColor: 'divider',
                    borderRadius: '4px',
                    p: 2
                }}>
                <Typography variant="overline">Rechercher des contrats</Typography>
                <ContractsDropdown
                    isMulti={true}
                    onChange={(contractNb) => {
                        setFilterContracts(contractNb ? [contractNb as string] : []);
                        setRefresh(true);
                    }}
                    endpoint={endpoint}
                    searchKey="contract"
                />
            </Box>
            <LinkContractsList
                showSearch={false}
                endpoint={endpoint}
                selectedContracts={codes}
                onFilterContracts={(contracts: string[]) => {
                    setFilterContracts(contracts);
                    setRefresh(true);
                }}
                onCreateLink={() => {
                    navigate('/optimisation/list/create/' + codes.join(','));
                }}
                formatResponse={(response: any) => {
                    return {
                        label: `${response.label}`,
                        value: response.code
                    };
                }}
                isMulti={false}>
                <BTable
                    sx={{
                        mt: 2
                    }}
                    refresh={refresh}
                    model={model}
                    endpoint={`${endpoint}${filterContracts?.length ? '&contract__in=' + filterContracts.join(',') : ''}`}
                    pageSize={100}
                    fields={[
                        {
                            name: 'code',
                            label: 'Contrat',
                            orderable: true
                        },
                        {
                            name: 'label',
                            label: 'Libellé',
                            orderable: true
                        },
                        {
                            name: 'devices_count',
                            label: 'modules',
                            orderable: true
                        },
                        {
                            name: 'ok_devices_count',
                            label: 'Nombre de modules OK',
                            shortLabel: 'modules OK',
                            orderable: true
                        },
                        {
                            name: 'nok_devices_count',
                            label: 'Nombre de modules NOK',
                            shortLabel: 'modules NOK',
                            orderable: true
                        },
                        {
                            name: 'abs_devices_count',
                            label: 'Nombre de modules ABS',
                            shortLabel: 'modules ABS',
                            orderable: true
                        },
                        {
                            name: 'potential_improvement_devices_count',
                            label: 'Nombre de modules améliorables',
                            shortLabel: 'modules améliorables',
                            orderable: true
                        },
                        {
                            name: 'current_performance_average',
                            label: 'Performance actuelle',
                            shortLabel: 'Perf. actuelle',
                            transform: (perf: number) => {
                                return (perf * 100).toFixed(2) + '%';
                            },
                            orderable: true
                        },
                        {
                            name: 'potential_performance_average',
                            label: 'Performance potentielle',
                            shortLabel: 'Perf. potentielle',
                            transform: (perf: number) => {
                                return (perf * 100).toFixed(2) + '%';
                            },
                            orderable: true
                        }
                    ]}
                    headerInfo={`Données du ${moment().startOf('isoWeek').format('DD/MM/YYYY').toString()}`}
                    defaultOrder='name'
                    exportButtons={[
                        {
                            name: 'Exporter en xlsx',
                            fileName: 'Optimisations.xlsx',
                            type: 'xlsx',
                            icon: <PiFileXls />,
                            id: 'optimisation-export-button'
                        }
                    ]}
                    onSelectItems={(selectedContracts: TableRowType[]) => {
                        setSelectedContracts(selectedContracts);
                    }}
                    selectedItems={selectedContracts}
                />
            </LinkContractsList>
        </>
    );
};

export default OptimisationContractsSelection;
