import dataService from '../Common/Services/dataService';
import DisplayCampaignsName from './DisplayCampaignsName';
import BTable from '../Tools/Table/BTable';
import { useState } from 'react';
import BSearchForm from '../Tools/Search/BSearchForm';

const ConcentratorStatuses = () => {
    const [filters, setFilters] = useState<string>('');
    return (
        <>
            <BSearchForm
                columns={2}
                fields={[
                    {
                        name: 'concentrator',
                        label: 'Concentrateur',
                        type: 'text'
                    },
                    {
                        name: 'operator',
                        label: 'Opérateur',
                        // type: 'select',
                        options: {
                            values:
                                dataService.getSelectData('operators', {}, { label: 'name' }) || []
                        }
                    }
                ]}
                setSearchString={setFilters}
            />
            <BTable
                model="concentrator-statuses"
                endpoint={'/api/concentrator-statuses/' + filters}
                fields={[
                    { name: 'concentrator', label: 'Concentrateur' },
                    {
                        name: 'operator',
                        label: 'Opérateur',
                        transform: (operatorId: number) => {
                            return dataService.getOperator(operatorId)?.name;
                        }
                    },
                    {
                        name: 'stats.WAITING',
                        label: 'Intentions en attente',
                        transform: (stat: number) => {
                            return stat || '0';
                        }
                    },
                    {
                        name: 'stats.TRANSFERRED',
                        label: 'Intentions déposées',
                        transform: (stat: number) => {
                            return stat || '0';
                        }
                    },
                    {
                        name: 'stats.SENT',
                        label: 'Intentions envoyées',
                        transform: (stat: number) => {
                            return stat || '0';
                        }
                    },
                    {
                        name: 'waiting_campaigns',
                        label: 'Campagnes associées en cours',
                        transform: (row: { id: number; name: string }[]) => {
                            return <DisplayCampaignsName campaigns={row} />;
                        }
                    }
                ]}
            />
        </>
    );
};

export default ConcentratorStatuses;
