import React, { useState } from 'react';
import { CircularProgress, IconButton } from '@mui/material';
import { launchExport } from './export';
import { ExportButton as ExportButtonType } from '@applications-terrains/birdz-react-library';
import UploadIcon from '@mui/icons-material/Upload';

const ExportButton = ({
    exportButton,
    endpoint
}: {
    exportButton: ExportButtonType;
    endpoint: string;
}) => {
    const [exportInProgress, setExportInProgress] = useState(false);
    return (
        <IconButton
            sx={{ borderRadius: '4px'}}
            id={'export_button_' + exportButton.name}
            onClick={() => {
                setExportInProgress(true);
                launchExport({
                    ...exportButton,
                    type: exportButton.type,
                    onSuccess: (data) => {
                        setExportInProgress(false);
                        exportButton.onSuccess && exportButton.onSuccess(data);
                    },
                    endpoint
                });
            }}
            disabled={exportInProgress}
            key={exportButton.name}>
            {exportInProgress ? (
                <CircularProgress size={16} thickness={4} sx={{ color: '#666666' }} />
            ) : exportButton.icon ? (
                exportButton.icon
            ) : (
                <UploadIcon />
            )}
        </IconButton>
    );
};

export default ExportButton;
