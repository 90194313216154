import React from 'react';
import { Box, LinearProgress } from '@mui/material';
import styled, { keyframes } from 'styled-components';
import { green } from '@mui/material/colors';

interface StyledProgressBarProps {
    progress: number; // Progress value between 0 and 100
}

const expand = keyframes`
 0% { width: 0%; }
 100% {width: 180px; }
`;
const reveal = keyframes`
 0% { opacity: 0 }
 100% { opacity: 1 }
`;

const StyledProgress = styled.div`
    position: relative;
    margin-top: 0.5rem;
    width: 180px;
    height: 16px;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    animation-name: ${expand};
    animation-duration: 0.7s;
    background-color: #f0f0f0;
`;

const StyledDisplayPercentage = styled.div`
    position: absolute;
    z-index: 1;
    font-size: 0.7rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: rgba(0, 0, 0, 0.87);

    animation-name: ${reveal};
    animation-duration: 1.5s;
`;

const StyledProgressBar: React.FC<StyledProgressBarProps> = ({ progress }) => {
    return (
        <StyledProgress>
            <Box
                sx={{
                    position: 'relative',
                    width: progress + '%',
                    cursor: 'default'
                }}>
                <LinearProgress
                    variant="determinate"
                    value={100}
                    className="linearProgress"
                    sx={{
                        height: '24px',
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: green[500]
                        }
                    }}
                />
            </Box>
            <StyledDisplayPercentage>{progress + '%'}</StyledDisplayPercentage>
        </StyledProgress>
    );
};

export default StyledProgressBar;
