import { Link } from 'react-router-dom';
import moment from 'moment';
import * as Yup from 'yup';
import {
    useDialog,
    BirdzDialog,
    Item,
    FormField,
    StyledTitle,
    useNotif,
    BirdzNotif
} from '@applications-terrains/birdz-react-library';
import { DeviceType } from '../types';
import Axios from 'axios';
import { authService } from '../..';
import { Button, Grid, IconButton } from '@mui/material';
import { AddCircle, Delete, Edit } from '@mui/icons-material';
import { TimeWindows } from './TimeWindows';
import SortableSelectWrapper from '../Select/SortableSelectWrapper';
import { useState } from 'react';
import PagePaper from '../Tools/Pages/PagePaper';
import BSearchForm from '../Tools/Search/BSearchForm';
import BTable from '../Tools/Table/BTable';
import { useFetchContext } from '../../contexts/FetchContext';
import TrueFalse from '../Tools/Table/components/TrueFalse';

interface DeviceTypesProps {
    action: 'list' | 'add' | 'edit';
}

const DeviceTypes = ({ action }: DeviceTypesProps) => {
    const endpoint = '/api/device-types/';
    const { confirmDialog, closeDialog, dialogOptions } = useDialog();
    const { notif, notifOptions } = useNotif();
    const [filters, setFilters] = useState<string>('');
    const { dataRefs } = useFetchContext();
    const model = 'device_types';

    const formFields: FormField[] = [
        {
            name: 'name',
            type: 'text',
            label: 'Nom *',
            validation: Yup.string().required('Le nom est obligatoire')
        },
        {
            name: 'type',
            type: 'select',
            label: 'Type *',
            options: {
                values: [
                    { value: 'WA', label: 'Module' },
                    { value: 'MR', label: 'Répéteur' },
                    { value: 'CR', label: 'Concentrateur' }
                ],
                searchIsMulti: false
            },
            validation: Yup.string().required('Le type est obligatoire').nullable()
        },
        {
            name: 'technical_code',
            type: 'text',
            label: 'Code technique *',
            validation: Yup.string().required('Le code technique est obligatoire')
        },
        {
            name: 'bconnect_technical_code',
            type: 'text',
            label: 'Code technique Bconnect'
        },
        {
            name: 'bconnect_technical_code_key',
            type: 'text',
            label: 'Bconnect technical code key'
        },
        {
            name: 'CommandsSet',
            type: 'text',
            label: 'CommandsSet'
        },
        {
            name: 'TechnicalParameters',
            type: 'text',
            label: 'TechnicalParameters'
        },
        {
            name: 'RepetitionEquipmentType',
            type: 'text',
            label: 'RepetitionEquipmentType'
        },
        {
            name: 'nfc_keys',
            type: 'text',
            label: 'NFC Keys'
        },
        {
            name: 'wireless_mbus_keys',
            type: 'text',
            label: 'Wireless-Mbus Keys'
        },
        {
            name: 'hr_keys',
            type: 'custom',
            label: 'HR Keys',
            render: (values: any, onChange: any) => {
                return (
                    <SortableSelectWrapper
                        onChange={onChange}
                        values={values}
                        hideMenu={true}
                        placeholder={null}
                    />
                );
            }
        },
        {
            name: 'lorawan_keys',
            type: 'custom',
            label: 'LoRaWAN Keys',
            render: (values: any, onChange: any) => {
                return (
                    <SortableSelectWrapper
                        values={values}
                        onChange={onChange}
                        hideMenu={true}
                        placeholder={null}
                    />
                );
            }
        },
        {
            name: 'sit_keys',
            type: 'text',
            label: 'SIT Keys'
        },
        {
            name: 'encryption_keys',
            type: 'radio',
            label: 'Clés de chiffrement par KMS',
            options: {
                values: [
                    { value: true, label: 'Oui' },
                    { value: false, label: 'Non' }
                ]
            },
            defaultValue: false
        },
        {
            name: 'is_active',
            type: 'radio',
            label: 'Etat',
            options: {
                values: [
                    { value: true, label: 'Actif' },
                    { value: false, label: 'Inactif' }
                ]
            },
            defaultValue: true,
            permissions: ['IS_ACTIVE']
        },
        {
            name: 'time_windows',
            type: 'custom',
            render: (value: any, onChange: any) => {
                return <TimeWindows value={value} onChange={onChange} />;
            },
            label: "Créneaux d'écoute"
        },
        {
            name: 'DefaultChannel',
            type: 'text',
            label: 'Fréquences par défaut (séparées par des , )'
        },
        {
            name: 'updated_at',
            type: 'readonly',
            label: 'Dernière modification',
            transform: (value: any) => {
                const date = value && moment.unix(value).format('DD/MM/YYYY à HH:mm:ss');
                return date || '';
            }
        }
    ];
    const listFields = [
        { name: 'name', label: 'Nom', orderable: true },
        {
            name: 'is_active',
            label: 'Actif',
            orderable: true,
            transform: (value: boolean) => {
                return <TrueFalse value={value} />;
            }
        },
        {
            name: 'updated_at',
            label: 'Date de modification',
            orderable: true,
            transform: (value: any) => {
                return (value && moment.unix(value).format('DD/MM/YYYY à HH:mm:ss')) || '';
            }
        }
    ];
    const searchFields = [
        {
            name: 'name',
            label: 'Nom device',
            options: {
                returnLabel: true,
                values: (dataRefs[model]?.results || []).map((model: any) => ({
                    value: model.id,
                    label: model.name
                }))
            }
        }
    ];
    const actions = [
        {
            name: 'edit',
            render: (deviceType: DeviceType) => {
                return (
                    <IconButton
                        id={'deviceType-edit-button'}
                        component={Link}
                        to={`/models/parameters/device-types/edit/${deviceType.id}`}>
                        <Edit fontSize="small" />
                    </IconButton>
                );
            }
        },
        {
            name: 'delete-devicetype',
            render: (row: any) => {
                return (
                    <IconButton
                        id={'deviceType-delete-button'}
                        onClick={() => {
                            confirmDialog({
                                title: 'Supprimer le device type',
                                content: 'Êtes-vous sûr de vouloir supprimer ce device type?',
                                onValidate: () => {
                                    Axios.delete(`${endpoint}${row.id}/`).then(
                                        () => {
                                            notif({
                                                content:
                                                    'Le device type a été supprimé avec succès',
                                                type: 'success'
                                            });
                                            document.location.reload();
                                        },
                                        () => {
                                            notif({
                                                content:
                                                    'Une erreur est survenue lors de la suppression',
                                                type: 'error'
                                            });
                                        }
                                    );
                                    closeDialog();
                                },
                                onCancel: () => {
                                    closeDialog();
                                }
                            });
                        }}>
                        <Delete fontSize="small" />
                    </IconButton>
                );
            }
        }
    ];

    const transformItemOnSave = (item: any) => {
        if (item.DefaultChannel) {
            item.DefaultChannel = item.DefaultChannel.split(',').map((item: number[]) => +item);
        } else {
            item.DefaultChannel = [];
        }

        const defaultValues = {
            name: '',
            type: '',
            technical_code: '',
            bconnect_technical_code: '',
            bconnect_technical_code_key: '',
            CommandsSet: '',
            TechnicalParameters: '',
            RepetitionEquipmentType: '',
            nfc_keys: '',
            wireless_mbus_keys: '',
            hr_keys: [],
            lorawan_keys: [],
            sit_keys: '',
            encryption_keys: false,
            is_active: true,
            time_windows: [],
            DefaultChannel: []
        };
        const itemWithDefaultValues: { [key: string]: unknown } = {};
        Object.entries(item).forEach((el) => {
            const [key, value] = el;
            itemWithDefaultValues[key] = value
                ? value
                : defaultValues[key as keyof typeof defaultValues];
        });

        return itemWithDefaultValues;
    };

    const transformItemOnLoad = (item: any) => {
        if (item.DefaultChannel) {
            item.DefaultChannel = item.DefaultChannel.join(',');
        }
        return item;
    };

    return (
        <PagePaper
            title={
                <Grid justifyContent="space-between" alignItems={'center'} container>
                    <Grid item>
                        <StyledTitle className="m-0">
                            {action === 'list'
                                ? 'Liste des device types'
                                : `${action === 'add' ? 'Ajouter un' : 'Modifier le'} device type`}
                        </StyledTitle>
                    </Grid>
                    {authService.canAccess('WRITE_PARAMETERS') && action === 'list' && (
                        <Grid item>
                            <Link to="/models/parameters/device-types/add">
                                <Button id={'deviceType-add-button'} variant="contained">
                                    <AddCircle sx={{ mr: 1 }} /> Ajouter un device type
                                </Button>
                            </Link>
                        </Grid>
                    )}
                </Grid>
            }>
            {action === 'list' && (
                <>
                    <BSearchForm columns={1} fields={searchFields} setSearchString={setFilters} />
                    <BTable
                        model={model}
                        endpoint={endpoint + filters}
                        fields={listFields}
                        actions={authService.canAccess('WRITE_TEMPLATE') ? actions : []}
                        defaultOrder="name"
                    />
                </>
            )}
            <BirdzDialog options={dialogOptions} />
            <BirdzNotif options={notifOptions} />
            {action === 'add' && (
                <Item
                    action="add"
                    endpoint={endpoint}
                    fields={formFields}
                    name=""
                    transformItemOnSave={transformItemOnSave}
                    transformItemOnLoad={transformItemOnLoad}
                />
            )}
            {action === 'edit' && (
                <Item
                    action="edit"
                    endpoint={endpoint}
                    fields={formFields}
                    name=""
                    transformItemOnSave={transformItemOnSave}
                    transformItemOnLoad={transformItemOnLoad}
                />
            )}
        </PagePaper>
    );
};
export default DeviceTypes;
