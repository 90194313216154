import React from 'react';
import { StyledTitle } from '@applications-terrains/birdz-react-library';
import { Outlet, useLocation } from 'react-router-dom';
import PagePaper from '../Tools/Pages/PagePaper';

const SpreadSendingsDashboard = () => {
    const { pathname } = useLocation();
    const page = pathname.split('/').pop();
    let title = '';
    switch (page) {
        case 'concentrator-statuses':
            title = ' (statuts des concentrateurs)';
            break;
        case 'hr-sendings':
            title = ' (envois HR)';
            break;
        default:
            break;
    }

    return (
        <PagePaper title={<StyledTitle className='m-0'>Récapitulatif des envois étalés {title}</StyledTitle>}>
            <Outlet />
        </PagePaper>
    );
};

export default SpreadSendingsDashboard;
