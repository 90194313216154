import React, { useState } from 'react';
import { StyledTitle } from '@applications-terrains/birdz-react-library';
import moment from 'moment';
import { LogsDetail } from './LogDetail';
import { Grid, IconButton } from '@mui/material';
import { Search } from '@mui/icons-material';
import PagePaper from '../Tools/Pages/PagePaper';
import BSearchForm from '../Tools/Search/BSearchForm';
import BTable from '../Tools/Table/BTable';

type Log = {
    id: number;
    date: any;
    ip: string;
    content: string;
    type: number;
    type_label: string;
    user_agent: string;
    user__name: string;
};

const endpoint = '/api/activity-logs/';

const Logs = (params: any) => {
    const [logDetail, setLogDetail] = useState<any>();
    const [filters, setFilters] = useState<string>('');
    const model = 'logs-admin';

    let listFields = [
        { name: 'id', label: '#', options: { width: '30px' } },
        {
            name: 'date',
            label: 'Date',
            orderable: true,
            transform: (value: any) => {
                return (value && moment(value).format('DD/MM/YYYY à HH:mm:ss')) || '';
            },
            options: { width: '150px' }
        },
        {
            name: 'user__name',
            label: 'Utilisateur',
            className: 'text-center',
            options: { width: '150px' },
            orderable: true
        },
        {
            name: 'type_label',
            label: 'Type',
            className: 'text-center',
            options: { width: '150px' }
        },
        {
            name: 'message',
            label: 'Message',
            className: 'text-center'
        }
    ];

    let searchFields = [
        {
            name: 'user',
            label: 'Utilisateur',
            options: {
                identifier: 'id',
                source: '/api/users/?ordering=name&',
                label: 'name',
                searchIsMulti: false
            }
        },
        {
            name: 'type',
            label: 'Type',
            type: 'select-multiple',
            options: {
                values: [
                    { value: 1, label: 'Connexion' },
                    { value: 2, label: 'INFO' },
                    { value: 3, label: 'WARNING' },
                    { value: 4, label: 'ERROR' }
                ]
            }
        },
        {
            name: 'date_after',
            label: 'Entre le ',
            type: 'datepicker'
        },
        {
            name: 'date_before',
            label: 'et le',
            type: 'datepicker'
        }
    ];

    let actions = [
        {
            name: 'detail',
            label: 'Détail',
            render: (logDetail: Log) => {
                return (
                    <IconButton
                        id={'log-detail-button'}
                        onClick={() => {
                            setLogDetail(logDetail);
                        }}>
                        <Search fontSize="small" />
                    </IconButton>
                );
            }
        }
    ];

    return (
        <PagePaper
            title={
                <Grid justifyContent="space-between" alignItems={'center'} container>
                    <Grid item>
                        <StyledTitle className="m-0">{'Liste des logs'}</StyledTitle>
                    </Grid>
                </Grid>
            }>
            {params.action === 'list' && (
                <>
                    <BSearchForm columns={2} fields={searchFields} setSearchString={setFilters} />
                    <BTable
                        model={model}
                        endpoint={endpoint + filters}
                        fields={listFields}
                        actions={actions}
                        defaultOrder="-date"
                    />
                </>
            )}
            {logDetail && (
                <LogsDetail
                    logItem={logDetail}
                    onClose={() => {
                        setLogDetail(null);
                    }}
                />
            )}
        </PagePaper>
    );
};
export default Logs;
