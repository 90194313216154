import React, { useRef, useState } from 'react';
import axios from 'axios';
import {
    BirdzDialog,
    useDialog,
    StyledTitle,
    FormField,
    Item,
    BirdzNotif,
    useNotif
} from '@applications-terrains/birdz-react-library';
import './Users.scss';
import { Link, useNavigate } from 'react-router-dom';
import { User } from '@sentry/browser';
import moment from 'moment';
import dataService from '../Common/Services/dataService';
import * as Yup from 'yup';
import { Group } from '../types';
import Axios from 'axios';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Alert, Button, Grid, IconButton, Snackbar } from '@mui/material';
import { Delete, Edit, ManageAccounts } from '@mui/icons-material';
import { authService } from '../..';
import PagePaper from '../Tools/Pages/PagePaper';
import BTable from '../Tools/Table/BTable';
import BSearchForm from '../Tools/Search/BSearchForm';
import TrueFalse from '../Tools/Table/components/TrueFalse';
import StyledProgressBar from '../Tools/StyledProgressBar';
import { useSafeTimeout } from '../../hooks/useSafeTimeout';

const Users = (params: any) => {
    const endpoint = '/api/users/';
    const model = 'users-admin';
    const currentUserId = authService?._user?.id;

    const [filters, setFilters] = useState<string>('');
    const [passwordError, setPasswordError] = useState<boolean>(false);
    const redirectToLoginRef = useRef(false); 

    const { confirmDialog, closeDialog, dialogOptions } = useDialog();
    const { notif, notifOptions } = useNotif();
    const navigate = useNavigate();

    const transformItemOnSave = (user: User) => {
        if (!user.password) {
            delete user.password;
        } else {
            const regex = /^(?=.*[!"#$%&'()*+,\-./:;<=>?@[\]\\^_`{|}~]).{12,}$/;
            const isValid = regex.test(user.password);
            if (!isValid) {
                setPasswordError(true);
            } else if (currentUserId === user.id) {
                // Si l'utilisateur modifie son propre mot de passe, on le redirige vers la page de connexion
                redirectToLoginRef.current = true;
            };
        }
        if (user.contracts) {
            user.contracts = user.contracts.map((contract: any) => {
                if (typeof contract !== 'string') {
                    return contract.code;
                }
                return contract;
            });
        }
        if (user.customers) {
            user.customers = user.customers.map((customer: any) => {
                if (typeof customer !== 'string') {
                    return customer.code;
                }
                return customer;
            });
        }

        user.purposes_quota = user.purposes_quota || null;
        return user;
    };

    let formFields: FormField[] = [
        {
            name: 'name',
            type: 'text',
            label: 'Nom',
            validation: Yup.string().required('Vous devez renseigner le nom')
        },
        {
            name: 'email',
            type: 'text',
            label: 'Email',
            validation: Yup.string()
                .email('Vous devez renseigner un e-mail valide')
                .required("L'email ne peut être vide")
        },
        {
            name: 'password',
            type: 'password',
            label: 'Mot de passe'
        },
        {
            name: 'is_active',
            type: 'radio',
            label: 'Etat',
            options: {
                values: [
                    { value: true, label: 'Actif' },
                    { value: false, label: 'Inactif' }
                ]
            },
            defaultValue: true,
            permissions: ['IS_ACTIVE']
        },
        { name: 'avatar', type: 'image', label: 'Photo' },

        {
            name: 'groups',
            type: 'select-multiple',
            label: 'Groupes rattachés',
            options: {
                values: dataService.getSelectData('groups', { is_active: true }, { label: 'name' })
            }
        },
        {
            name: 'customers',
            type: 'autocomplete',
            label: 'Clients rattachés',
            options: {
                source: '/api/customers/?ordering=code&',
                identifier: 'code',
                label: 'code'
            }
        },
        {
            name: 'contracts',
            type: 'autocomplete',
            label: 'Contrats rattachés',
            options: {
                source: '/api/contracts/?ordering=code&',
                identifier: 'code',
                label: 'code'
            }
        },
        {
            name: 'purposes_quota',
            type: 'number',
            label: 'Quota utilisateur'
        },
        {
            name: 'used_quota',
            type: 'readonly',
            label: 'Quota utilisé pour le mois en cours'
        },
        {
            name: 'last_login',
            type: 'readonly',
            label: 'Dernière connexion',
            transform: (value: any) => {
                let date = value && moment(value).format('DD/MM/YYYY à HH:mm:ss');
                return date || '';
            }
        }
    ];

    let listFields = [
        {
            name: 'name',
            label: 'Nom',
            orderable: true
        },
        {
            name: 'groups',
            label: 'Groupes',
            transform: (values: any) => {
                let dataServiceGroups = dataService.getData('groups') as Group[];
                let groups = values.map((value: number) => {
                    let group = dataServiceGroups.find(
                        (dataServiceGroup) => dataServiceGroup.id === value
                    );
                    return group?.name || value;
                });
                return groups.join(', ');
            }
        },
        {
            name: 'is_active',
            label: 'Actif',
            className: 'text-center',
            orderable: true,
            transform: (value: boolean) => {
                return <TrueFalse value={value} />;
            }
        },
        {
            name: 'last_login',
            label: 'Dernière connexion',
            transform: (value: any) => {
                return (value && moment(value).format('DD/MM/YYYY à HH:mm:ss')) || '';
            },
            orderable: true,
            options: { width: '210px' }
        },
        {
            name: 'used_quota',
            label: 'Quota intentions',
            transform: (value: number, object: any) => {
                let rate =
                    value && object.purposes_quota ? (+value / object.purposes_quota) * 100 : 0;
                return <StyledProgressBar progress={rate} />;
            },
            orderable: true
        }
    ];

    let searchFields = [
        {
            name: 'groups',
            label: 'Groupe',
            options: {
                source: '/api/lists/groups/',
                searchIsMulti: false
            }
        }
    ];

    let actions = [
        {
            name: 'edit',
            render: (value: any) => {
                return (
                    <IconButton
                        id={'edit-user-button'}
                        component={Link}
                        to={`/admin/users/edit/${value.id}`}>
                        <Edit fontSize="small" />
                    </IconButton>
                );
            }
        },
        {
            name: 'delete-user',
            render: (model: any) => {
                return (
                    <IconButton
                        id={'delete-user-button'}
                        onClick={() => {
                            confirmDialog({
                                title: "Supprimer l'utilisateur",
                                content: 'Êtes-vous sûr de vouloir supprimer cet utilisateur?',
                                onValidate: () => {
                                    Axios.delete(`${endpoint}${model.id}/`).then(
                                        () => {
                                            notif({
                                                content: "L'utilisateur a été supprimé avec succès",
                                                type: 'success'
                                            });
                                            document.location.reload();
                                        },
                                        () => {
                                            notif({
                                                content:
                                                    'Une erreur est survenue lors de la suppression',
                                                type: 'error'
                                            });
                                        }
                                    );
                                    closeDialog();
                                },
                                onCancel: () => {
                                    closeDialog();
                                }
                            });
                        }}>
                        <Delete fontSize="small" />
                    </IconButton>
                );
            }
        }
    ];

    const setSafeTimeout = useSafeTimeout();

    const connectAsAction: any = {
        name: 'connect-as',
        label: 'Se connecter en tant que',
        render: (user: any) => (
            <IconButton
                id={'connect-as-user-button'}
                onClick={() => {
                    axios.post('/api/auth/as/ ', { user_id: user.id }).then(
                        () => {
                            notif({
                                type: 'success',
                                content:
                                    'Vous êtes maintenant connectés en tant que ' +
                                    user.name +
                                    '. La page va maintenant se recharger'
                            });

                            setSafeTimeout(() => {
                                window.location.reload();
                            }, 2500);
                        },
                        (error) => {
                            let message = '';
                            if (error.response.status === 401) {
                                message = "Vous n'êtes pas autorisés à utiliser cette fonction";
                            }

                            notif({
                                type: 'error',
                                content: message
                            });
                        }
                    );
                }}>
                <ManageAccounts fontSize="small" />
            </IconButton>
        )
    };

    if (authService.canAccess('LOGIN_AS')) {
        actions.push(connectAsAction);
    }

    return (
        <PagePaper
            title={
                <Grid justifyContent="space-between" alignItems={'center'} container>
                    <Grid item>
                        <StyledTitle className="m-0">
                            {params.action === 'list'
                                ? 'Liste des utilisateurs'
                                : `${params.action === 'add' ? 'Ajouter un ' : "Modifier l'"}utilisateur`}
                        </StyledTitle>
                    </Grid>
                    {params.action === 'list' && (
                        <Grid item>
                            <Link to="/admin/users/add">
                                <Button id={'add-user-button'} variant="contained">
                                    <AddCircleIcon sx={{ mr: 1 }} /> Ajouter un utilisateur
                                </Button>
                            </Link>
                        </Grid>
                    )}
                </Grid>
            }>
            {params.action === 'list' && (
                <>
                    <BSearchForm columns={1} fields={searchFields} setSearchString={setFilters} />
                    <BTable
                        model={model}
                        endpoint={endpoint + filters}
                        fields={listFields}
                        actions={actions}
                        defaultOrder="name"
                    />
                </>
            )}
            <BirdzDialog options={dialogOptions} />
            <BirdzNotif options={notifOptions} />
            {params.action === 'add' && (
                <Item
                    action="add"
                    endpoint={endpoint}
                    fields={formFields}
                    transformItemOnSave={transformItemOnSave}
                />
            )}
            {params.action === 'edit' && (
                <Item
                    action="edit"
                    endpoint={endpoint}
                    fields={formFields}
                    transformItemOnSave={transformItemOnSave}
                    onSave={() => {
                        if (redirectToLoginRef.current) {
                            navigate('/auth?password=expired');
                        }
                    }}
                />
            )}
            <Snackbar
                open={passwordError}
                autoHideDuration={5000}
                onClose={() => setPasswordError(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert severity={'error'} sx={{ width: '100%' }}>
                    <>
                        Le mot de passe doit contenir au moins 12 caractères et au moins un
                        caractère spécial
                    </>
                </Alert>
            </Snackbar>
        </PagePaper>
    );
};

export default Users;
