import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LinkContractsList from '../LinkContractsList';
import { PiFileXls } from 'react-icons/pi';
import BTable, { TableRowType } from '../../Tools/Table/BTable';
import { Box, Typography } from '@mui/material';
import ContractsDropdown from '../../Tools/Contracts/ContractsDropdown';
import PagePaper from '../../Tools/Pages/PagePaper';
import { StyledTitle } from '@applications-terrains/birdz-react-library';
import LinkForm from '../LinkForm';
import { contractsConfig } from './config';
import { RacsupTypes } from './types';

const ContractsSelection = ({ type }: { type: RacsupTypes }) => {
    const [filterContracts, setFilterContracts] = useState<string[]>([]);
    const [selectedContracts, setSelectedContracts] = useState<TableRowType[]>([]);
    const [refresh, setRefresh] = useState<boolean>(false);

    const { contractsParam } = useParams();

    useEffect(() => {
        refresh && setRefresh(false);
    }, [refresh]);

    useEffect(() => {
        return () => {
            setFilterContracts([]);
            setSelectedContracts([]);
        };
    }, [type]);

    const navigate = useNavigate();

    const contracts = selectedContracts.map((contract) => contract.contract);

    return (
        <PagePaper
            title={
                <StyledTitle className="m-0">
                    {contracts.length
                        ? `Créer un chantier de ${contractsConfig[type].label} (sur ${contracts.length} contrat${contracts.length > 1 ? 's' : ''})`
                        : `Création de chantier de ${contractsConfig[type].label}`}
                </StyledTitle>
            }>
            {contracts.length && contractsParam ? (
                <LinkForm contracts={contracts} linkType={type}></LinkForm>
            ) : (
                <>
                    {
                        <Box
                            sx={{
                                mb: 1,
                                border: '1px solid',
                                borderColor: 'divider',
                                borderRadius: '4px',
                                p: 2
                            }}>
                            <Typography variant="overline">Rechercher des contrats</Typography>
                            <ContractsDropdown
                                isMulti={true}
                                onChange={(contractNb) => {
                                    setFilterContracts(contractNb ? [contractNb as string] : []);
                                    setRefresh(true);
                                    // if (!contractNb || contractNb.length === 0 ) {
                                    //     setSelectedContracts([]);
                                    // }
                                }}
                                endpoint={contractsConfig[type].endpoint}
                                searchKey={contractsConfig[type].searchKey}
                                transformer={{ label: 'contract_label', code: 'contract' }}
                            />
                        </Box>
                    }
                    <LinkContractsList
                        showSearch={false}
                        endpoint={contractsConfig[type].endpoint}
                        selectedContracts={contracts}
                        onFilterContracts={(contracts: string[]) => {
                            setFilterContracts(contracts);
                            setRefresh(true);
                        }}
                        onCreateLink={() => {
                            'onActionLink' in contractsConfig[type] &&
                                navigate(contractsConfig[type].onActionLink + contracts.join(','));
                        }}>
                        <BTable
                            sx={{ mt: 2 }}
                            refresh={refresh}
                            model={contractsConfig[type].model}
                            endpoint={`${contractsConfig[type].endpoint}${filterContracts?.length ? '&contract__in=' + filterContracts.join(',') : ''}`}
                            fields={contractsConfig[type].fields}
                            exportButtons={[
                                {
                                    name: 'Exporter en xlsx',
                                    fileName: `Export des contrats ${contractsConfig[type].label}.xlsx`,
                                    type: 'xlsx',
                                    icon: <PiFileXls />,
                                    id: 'contracts-links-export-button'
                                }
                            ]}
                            pageSize={100}
                            onSelectItems={(selectedContracts: TableRowType[]) => {
                                setSelectedContracts(selectedContracts);
                            }}
                            selectedItems={selectedContracts}
                        />
                    </LinkContractsList>
                </>
            )}
        </PagePaper>
    );
};

export default ContractsSelection;
