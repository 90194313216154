import React from 'react';
import { BirdzTitle } from '@applications-terrains/birdz-react-library';
import dataService from '../Common/Services/dataService';
import moment from 'moment';
import { Operator } from '../types';
import { IconButton } from '@mui/material';
import { Search } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { PiFileXls } from 'react-icons/pi';
import { useIsLoadingContext } from '../../contexts/IsLoadingContext';
import BTable from '../Tools/Table/BTable';
import PagePaper from '../Tools/Pages/PagePaper';

export const CreatedOptimisations = () => {
    const navigate = useNavigate();

    const { setIsLoading } = useIsLoadingContext();

    return (
        <PagePaper
            title={<BirdzTitle className="m-0">Suivi des chantiers d'optimisation</BirdzTitle>}>
            <BTable
                model={'created-optimisation-contracts'}
                endpoint={`/api/optimisation/optimisations/`}
                pageSize={100}
                fields={[
                    { name: 'contract', label: 'Contrat', orderable: true },
                    { name: 'name', label: 'Chantier', orderable: true },
                    {
                        name: 'description',
                        label: 'Description',
                        orderable: true
                    },
                    {
                        name: 'operator',
                        label: 'Opérateur',
                        transform: (value: any) => {
                            let operator = (dataService.getData('operators') as Operator[]).find(
                                (operator: any) => operator.id === value
                            );
                            return operator?.name || value;
                        },
                        orderable: true
                    },
                    {
                        name: 'scheduled_date',
                        label: "Date d'envoi",
                        orderable: true,
                        transform: (value: any) => {
                            let date = value && moment(value).format('DD/MM/YYYY à HH:mm:ss');
                            return date || '';
                        }
                    }
                ]}
                exportButtons={[
                    {
                        name: 'Exporter en xlsx',
                        fileName: 'Suivi_des_chantiers.xlsx',
                        type: 'xlsx',
                        icon: <PiFileXls />,
                        id: 'created-optimisation-export-button'
                    }
                ]}
                actions={[
                    {
                        name: 'see-campaign',
                        label: 'Récap des envois',
                        render: (value: any) => (
                            <IconButton
                                id={'created-optimisation-see-button'}
                                onClick={() => {
                                    const campaignId =
                                        value.campaign_simple || value.campaign_combined;
                                    navigate('/sendings/sent-list/campaign-details/' + campaignId);
                                    setIsLoading(true);
                                }}>
                                <Search fontSize="small" />
                            </IconButton>
                        ),
                        isVisible: (value: any) => {
                            return value.campaign_simple || value.campaign_combined;
                        }
                    }
                ]}
            />
        </PagePaper>
    );
};

export default CreatedOptimisations;
