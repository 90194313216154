import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
    BirdzModal,
    BirdzModalContent,
    BirdzModalTitle,
    StyledTitle
} from '@applications-terrains/birdz-react-library';
import './Statuses.scss';
import axios from 'axios';
import { Box, Button, Divider, Grid, IconButton, Paper, Typography } from '@mui/material';
import { Refresh, Search } from '@mui/icons-material';
import PagePaper from '../Tools/Pages/PagePaper';
import BSearchForm from '../Tools/Search/BSearchForm';
import BTable from '../Tools/Table/BTable';
import { useSafeTimeout } from '../../hooks/useSafeTimeout';

const links: any = {
    legacy_connection: 'Base commune',
    legacy_import: 'Import',
    shadow_connection: 'Shadow update',
    scheduler: 'Scheduler',
    transcoder: 'Transcoder',
    operator: 'Opérateur'
};

const statuses: any = {
    ok: 'Ok',
    error: 'Erreur'
};

export const Statuses = () => {
    const [linkStatuses, setLinkStatuses] = useState<any[]>([]);
    const [operatorStatuses, setOperatorStatuses] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [showLogDetail, setShowLogDetail] = useState<any>();
    const [filters, setFilters] = useState<string>('');

    useEffect(() => {
        setLoading(true);
        loadData();
    }, []);

const setSafeTimeout = useSafeTimeout();

    const checkStatuses = () => {
        setLoading(true);
        axios.post('/api/check-statuses/').then(() => {
            setSafeTimeout(() => {
                loadData();
            }, 2000);
        });
    };

    const loadData = () => {
        axios.get('/api/link-statuses/').then((response: any) => {
            let data = response.data;
            let links = data.results.filter((result: any) => result.type !== 'operator');
            let operators = data.results.filter((result: any) => result.type === 'operator');

            setLinkStatuses(links);
            setOperatorStatuses(operators);
            setLoading(false);
        });
    };

    const searchFields = [
        {
            name: 'date_after',
            label: 'Entre le ',
            type: 'datepicker'
        },
        {
            name: 'date_before',
            label: 'et le',
            type: 'datepicker'
        },
        {
            name: 'state',
            label: 'Etat',
            type: 'select',
            options: {
                values: [
                    { value: 'ok', label: statuses['ok'] },
                    { value: 'error', label: statuses['error'] }
                ]
            },
            className: 'col-sm-12'
        },
        {
            name: 'type',
            label: 'Type',
            type: 'select',
            options: {
                values: [
                    { value: 'FCM', label: 'FCM' },
                    { value: 'operator', label: 'Opérateur' },
                    { value: 'scheduler', label: 'Scheduler' },
                    { value: 'transcoder', label: 'Transcoder' }
                ]
            },
            className: 'col-sm-12'
        },
        {
            name: 'operator',
            label: 'Opérateur',
            options: {
                source: '/api/operators/',
                searchIsMulti: false
            }
        }
    ];

    let actions = [
        {
            name: 'detail',
            label: 'Détail',
            render: (item: any) => {
                return (
                    <IconButton
                        id={'statuses-details-button'}
                        onClick={() => {
                            setShowLogDetail(item);
                        }}>
                        <Search fontSize="small" />
                    </IconButton>
                );
            }
        }
    ];

    let listFields = [
        {
            name: 'date',
            label: 'Date',
            transform: (value: any) => {
                return (value && moment(value).format('DD/MM/YYYY à HH:mm:ss')) || '';
            },
            orderable: true
        },
        {
            name: 'type',
            label: 'Type',
            orderable: true,
            transform: (value: any, object: any) => {
                let val = links[value] || value;
                if (value === 'operator') {
                    val += ' (' + object.operator__name + ')';
                }
                return val;
            }
        },
        {
            name: 'state',
            label: 'Etat',
            orderable: true,
            transform: (value: any) => {
                return <span className={'status ' + value}>{statuses[value] || value}</span>;
            },
            className: 'text-center',
            options: { width: '100px' }
        }
    ];

    return (
        <PagePaper
            title={
                <Grid justifyContent="space-between" alignItems={'center'} container>
                    <Grid item>
                        <StyledTitle className="m-0">Statuts des bases</StyledTitle>
                    </Grid>
                    <Grid item>
                        <Button
                            id={'statuses-refresh-button'}
                            disabled={loading}
                            onClick={() => {
                                checkStatuses();
                            }}
                            className="checkStatus">
                            <Refresh fontSize="small" /> Rafraîchir
                        </Button>
                    </Grid>
                </Grid>
            }>
            <Box
                sx={{
                    mb: 2,
                    border: '1px solid',
                    borderColor: 'divider',
                    borderRadius: '4px',
                    p: 2
                }}>
                <Typography variant="overline">État des liens</Typography>
                <Divider sx={{ mt: 1, mb: 3 }} />

                <h4 className="services" style={{ color: '#666666', paddingLeft: '8px' }}>
                    Services
                </h4>

                {loading === false ? (
                    <div className="statuses">
                        {linkStatuses?.length > 1 && (
                            <Grid container spacing={1}>
                                {linkStatuses &&
                                    linkStatuses.map((linkStatus: any) => {
                                        return (
                                            <Grid
                                                component={Paper}
                                                item
                                                key={linkStatus.checked_date}
                                                sx={{ m: 1, p: 1 }}>
                                                {links[linkStatus.type]
                                                    ? links[linkStatus.type]
                                                    : linkStatus.type}
                                                <div className={'status ' + linkStatus.state}>
                                                    {statuses[linkStatus.state] || linkStatus.state}
                                                </div>
                                            </Grid>
                                        );
                                    })}
                            </Grid>
                        )}
                    </div>
                ) : (
                    <div className="alert">Chargement en cours...</div>
                )}
                <h4
                    className="operators"
                    style={{ color: '#666666', paddingLeft: '8px', marginTop: '16px' }}>
                    Opérateurs
                </h4>
                {loading === false ? (
                    <div className="statuses">
                        {operatorStatuses?.length > 1 && (
                            <Grid container spacing={1}>
                                {operatorStatuses &&
                                    operatorStatuses.map((operatorStatus: any) => {
                                        return (
                                            <Grid
                                                component={Paper}
                                                item
                                                key={operatorStatus.checked_date}
                                                sx={{ m: 1, p: 1 }}>
                                                {operatorStatus.operator__name}
                                                <div className={'status ' + operatorStatus.state}>
                                                    {statuses[operatorStatus.state] ||
                                                        operatorStatus.state}
                                                </div>
                                            </Grid>
                                        );
                                    })}
                            </Grid>
                        )}
                    </div>
                ) : (
                    <div className="alert">Chargement en cours...</div>
                )}
            </Box>
            <Box
                className="d-flex flex-column"
                sx={{
                    border: '1px solid',
                    borderColor: 'divider',
                    borderRadius: '4px',
                    p: 2,
                    flexGrow: 1
                }}>
                <Typography variant="overline">Logs</Typography>
                <Divider sx={{ mt: 1, mb: 3 }} />
                <BSearchForm columns={2} fields={searchFields} setSearchString={setFilters} />
                <BTable
                    model={'scheduler-logs'}
                    endpoint={'/api/scheduler-logs/' + filters}
                    fields={listFields}
                    actions={actions}
                    defaultOrder='-date'
                />
            </Box>
            {showLogDetail && (
                <LogsDetail
                    logItem={showLogDetail}
                    onClose={() => {
                        setShowLogDetail(null);
                    }}
                />
            )}
        </PagePaper>
    );
};

export const LogsDetail = ({ logItem, onClose }: any) => {
    const [show, setShow] = useState(true);
    return (
        <BirdzModal
            open={show}
            onClose={() => {
                setShow(false);
                onClose();
            }}>
            <BirdzModalTitle>Détail</BirdzModalTitle>
            <BirdzModalContent>
                {logItem.detail !== null && <pre>{JSON.stringify(logItem.detail, null, 2)}</pre>}
                {logItem.detail === null && <>Aucun détail à afficher</>}
            </BirdzModalContent>
        </BirdzModal>
    );
};

export default Statuses;
