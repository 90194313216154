import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik, Form, FormikHelpers } from 'formik';
import moment from 'moment';
import axios, { AxiosResponse } from 'axios';
import {
    AppPaper,
    BirdzDialog,
    BirdzNotif,
    InputField,
    StyledTitle,
    useDialog,
    useNotif
} from '@applications-terrains/birdz-react-library';
import './TokenContactForm.scss';
import { Alert, Box, Button, Grid, IconButton } from '@mui/material';
import { ContentCopy, Delete, Edit } from '@mui/icons-material';
import dataService from '../../Common/Services/dataService';
import TokemFormModal from './TokemFormModal';
import * as Yup from 'yup';
import BTable from '../../Tools/Table/BTable';
import TrueFalse from '../../Tools/Table/components/TrueFalse';
import { useSafeTimeout } from '../../../hooks/useSafeTimeout';

type ParamTypes = {
    id: string;
};

const tokenTypes = [
    { value: 'campaign_creation', label: 'Création de campagne' },
    { value: 'frame_generation', label: 'Génération de trames' }
];

export const TokenForm = (params: any) => {
    const { id } = useParams<ParamTypes>();
    const [token, setToken] = useState<any>({
        contact: '',
        email: '',
        company: ''
    });
    const [listVisibility, setListVisibility] = useState<boolean>(true);
    const navigate = useNavigate();
    const [tokenModal, setTokenModal] = useState<any>(null);
    const { notif, notifOptions } = useNotif();
    const { confirmDialog, closeDialog, dialogOptions } = useDialog();

    useEffect(() => {
        if (params.action === 'edit' && id) {
            axios.get('/api/tokens-contacts/' + id + '/').then((response) => {
                let data = response.data;
                setToken(data);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    const createToken = async (values: any) => {
        if (!id) return;

        let tokenPayload = Object.assign({}, values);
        tokenPayload.contact = +id;
        tokenPayload.validity_date = values.validity_date
            ? moment(values.validity_date).format('YYYY-MM-DD')
            : null;

        let savePromise: Promise<AxiosResponse<any>>;
        if (values.id) {
            savePromise = axios.put(`/api/tokens/${values.id}/`, tokenPayload);
        } else {
            savePromise = axios.post('/api/tokens/', tokenPayload);
        }

        savePromise.then(
            () => {
                refreshListing();
                setTokenModal(null);
                notif({
                    type: 'success',
                    content: `Le token a été ${values.id ? 'modifié' : 'créé'} avec succès`
                });
            },
            () => {
                notif({
                    type: 'error',
                    content: "Une erreur est survenue lors de l'enregistrement du token"
                });
            }
        );
    };

    const setSafeTimeout = useSafeTimeout();

    const saveTokenContact = (values: any) => {
        let body = values;
        let savePromise: Promise<any>;
        if (values.id) {
            savePromise = axios.put('/api/tokens-contacts/' + values.id + '/', body);
        } else {
            savePromise = axios.post('/api/tokens-contacts/', body);
        }
        savePromise.then((responseTokenContacts: any) => {
            notif({
                content: values?.id
                    ? 'Enregistrement modifié avec succès'
                    : 'Enregistrement créé avec succès',
                type: 'success'
            });
            setSafeTimeout(() => {
                if (values.id) {
                    navigate('/admin/tokens');
                } else {
                    navigate('/admin/tokens/edit/' + responseTokenContacts?.data?.id);
                }
            }, 2000);
        });
    };

    const refreshListing = () => {
        setListVisibility(false);
        setSafeTimeout(() => {
            setListVisibility(true);
        }, 0);
    };
    return (
        <AppPaper>
            <StyledTitle>
                {token.id ? 'Modifier le contact token' : 'Ajouter un contact token'}
            </StyledTitle>

            <Formik
                initialValues={token}
                enableReinitialize={true}
                onSubmit={(values, { setSubmitting }: FormikHelpers<any>) => {
                    saveTokenContact(values);
                }}
                validationSchema={Yup.object().shape({
                    contact: Yup.string().required('Vous devez renseigner le contact'),
                    email: Yup.string()
                        .email("Le format de l'email n'est pas valide")
                        .required('Vous devez renseigner un email'),
                    company: Yup.string().required('Vous devez renseigner une société')
                })}>
                {({ values, getFieldProps, errors }: any) => (
                    <Form>
                        <h3>Informations</h3>
                        <Grid container spacing={1}>
                            <Grid item xs={4}>
                                Contact
                            </Grid>
                            <Grid item xs={8}>
                                <InputField
                                    id={'tokenForm-contact-input'}
                                    type={'text'}
                                    size="small"
                                    fullWidth
                                    {...getFieldProps('contact')}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                E-mail
                            </Grid>
                            <Grid item xs={8}>
                                <InputField
                                    id={'tokenForm-email-input'}
                                    type={'text'}
                                    size="small"
                                    fullWidth
                                    {...getFieldProps('email')}
                                />
                            </Grid>

                            <Grid item xs={4}>
                                Société
                            </Grid>
                            <Grid item xs={8}>
                                <InputField
                                    id={'tokenForm-company-input'}
                                    type={'text'}
                                    size="small"
                                    fullWidth
                                    {...getFieldProps('company')}
                                />
                            </Grid>
                        </Grid>

                        <h3>Token et validité</h3>

                        {values?.id ? (
                            <>
                                {listVisibility && (
                                    <>
                                        <BTable
                                            model={'tokens' + values.id}
                                            endpoint={'/api/tokens/?contact=' + values?.id}
                                            fields={[
                                                {
                                                    name: 'token',
                                                    label: 'Token',
                                                    transform: (token: string) => {
                                                        return (
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'center'
                                                                }}>
                                                                <Box title={token}>
                                                                    {token.substring(0, 15)}...
                                                                </Box>
                                                                <IconButton
                                                                    id={'tokenForm-copy-button'}
                                                                    onClick={() => {
                                                                        navigator.clipboard.writeText(
                                                                            token
                                                                        );
                                                                        notif({
                                                                            content:
                                                                                'Token copié dans le presse-papier',
                                                                            type: 'success'
                                                                        });
                                                                    }}>
                                                                    <ContentCopy fontSize="small" />
                                                                </IconButton>
                                                            </Box>
                                                        );
                                                    }
                                                },
                                                {
                                                    name: 'last_used',
                                                    label: 'Dernier appel',
                                                    transform: (value: any) => {
                                                        return (
                                                            (value &&
                                                                moment(value).format(
                                                                    'DD/MM/YYYY à HH:mm:ss'
                                                                )) ||
                                                            ''
                                                        );
                                                    },
                                                    orderable: true,
                                                    options: { width: '220px' }
                                                },
                                                {
                                                    name: 'validity_date',
                                                    label: 'Validité max',
                                                    transform: (value: any) => {
                                                        return (
                                                            (value &&
                                                                moment(value).format(
                                                                    'DD/MM/YYYY'
                                                                )) ||
                                                            ''
                                                        );
                                                    },
                                                    orderable: true,
                                                    options: { width: '180px' }
                                                },
                                                {
                                                    name: 'templates_name',
                                                    label: 'Modèles autorisés'
                                                },
                                                {
                                                    name: 'type',
                                                    label: 'Type',
                                                    transform: (value) => {
                                                        return tokenTypes.find(
                                                            (type) => type.value === value
                                                        )?.label;
                                                    }
                                                },
                                                {
                                                    name: 'operators',
                                                    label: 'Opérateurs',
                                                    transform: (operatorIds: number[]) => {
                                                        return operatorIds
                                                            .map((operatorId: number) => {
                                                                return dataService.getOperator(
                                                                    operatorId
                                                                )?.name;
                                                            })
                                                            .join(', ');
                                                    }
                                                },
                                                {
                                                    name: 'customers',
                                                    label: 'Clients',
                                                    transform: (customerCodes: string[]) => {
                                                        return customerCodes.join(', ');
                                                    }
                                                },
                                                {
                                                    name: 'contracts',
                                                    label: 'Contrats',
                                                    transform: (contractCodes: string[]) => {
                                                        return contractCodes.join(', ');
                                                    }
                                                },
                                                {
                                                    name: 'is_active',
                                                    label: 'Actif',
                                                    className: 'text-center',
                                                    transform: (value: boolean) => (
                                                        <TrueFalse value={value} />
                                                    ),
                                                    orderable: true
                                                }
                                            ]}
                                            actions={[
                                                {
                                                    name: 'edit',
                                                    render: (token: any) => {
                                                        return (
                                                            <IconButton
                                                                id={'tokenForm-edit-button'}
                                                                onClick={() => {
                                                                    setTokenModal(token);
                                                                }}>
                                                                <Edit fontSize="small" />
                                                            </IconButton>
                                                        );
                                                    }
                                                },
                                                {
                                                    name: 'delete_token',
                                                    render: (value: any) => {
                                                        return (
                                                            <IconButton
                                                                id={'tokenForm-delete-button'}
                                                                onClick={() => {
                                                                    confirmDialog({
                                                                        title: 'Suppression du token',
                                                                        content:
                                                                            'Souhaitez vous bien supprimer ce token?',
                                                                        onValidate: () => {
                                                                            axios
                                                                                .delete(
                                                                                    '/api/tokens/' +
                                                                                        value.id +
                                                                                        '/'
                                                                                )
                                                                                .then(
                                                                                    () => {
                                                                                        notif({
                                                                                            content:
                                                                                                'Suppression effectuée avec succès',
                                                                                            type: 'success'
                                                                                        });

                                                                                        refreshListing();
                                                                                    },
                                                                                    () => {
                                                                                        notif({
                                                                                            content:
                                                                                                'Une erreur est survenue lors de la suppression',
                                                                                            type: 'error'
                                                                                        });
                                                                                    }
                                                                                )
                                                                                .finally(() => {
                                                                                    closeDialog();
                                                                                });
                                                                        },
                                                                        onCancel: () => {
                                                                            closeDialog();
                                                                        }
                                                                    });
                                                                }}>
                                                                <Delete fontSize="small" />
                                                            </IconButton>
                                                        );
                                                    }
                                                }
                                            ]}
                                        />
                                        <Button
                                            sx={{mt:2}}
                                            id={'tokenForm-add-button'}
                                            variant="contained"
                                            onClick={() => {
                                                setTokenModal({});
                                            }}>
                                            Ajouter un token
                                        </Button>
                                    </>
                                )}
                            </>
                        ) : (
                            <Alert id={'tokenForm-alert'} severity="info" sx={{ mb: 1 }}>
                                Vous devez d'abord enregistrer les informations avant de pouvoir
                                créer un token
                            </Alert>
                        )}

                        {tokenModal !== null && (
                            <TokemFormModal
                                token={tokenModal}
                                onSave={(token: any) => {
                                    createToken(token);
                                }}
                                onCancel={() => {
                                    setTokenModal(null);
                                }}
                            />
                        )}
                        <Box sx={{ textAlign: 'center' }}>
                            <Button
                                variant="contained"
                                type="submit"
                                id={'tokenForm-submit-button'}>
                                Enregistrer
                            </Button>
                        </Box>
                    </Form>
                )}
            </Formik>
            <BirdzNotif options={notifOptions} />
            <BirdzDialog options={dialogOptions} />
        </AppPaper>
    );
};
