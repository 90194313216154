import React, { useCallback, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import 'react-datepicker/dist/react-datepicker.css';
import _ from 'lodash';
import { campaignService } from '../CampaignService';
import { CampaignSaveButtons } from '../CampaignSaveButtons';
import Parameters, {
    getPayloadClear,
    getPayloadClearByDeviceType
} from '../../Parameters/Parameters';
import { CampaignDeviceDevEUI } from '../CampaignDeviceDevEUI';
import { CampaignCodec, getCodecsPath, getCodecsPathByDeviceType } from '../CampaignCodec';
import { CampaignPort } from '../CampaignPort';
import { CampaignOperator } from '../CampaignOperator';
import { CampaignHeaderConfig } from '../CampaignHeaderConfig';
import { CampaignMaxDays } from '../CampaignMaxDays';
import { CampaignMultiAntenna } from '../CampaignMultiAntenna';
import dataService from '../../Common/Services/dataService';
import { ScheduleOptions } from '../../Common/ScheduleOptions/ScheduleOptions';
import { Alert, Box, Checkbox, FormControlLabel, TextField } from '@mui/material';
import {
    AppPaper,
    BirdzDialog,
    BirdzNotif,
    BirdzTitle,
    useDialog,
    useNotif
} from '@applications-terrains/birdz-react-library';
import { CampaignType } from '../../types';
import { useCampaignContext } from '../../../contexts/CampaignContext';
import useCampaignSuccess from '../../../hooks/useCampaignSuccess';

const CUSTOM: CampaignType = 'custom';

const DEFAULT_CAMPAIGN = {
    payload_clear: {},
    type: CUSTOM
};

export const CustomCampaign = () => {
    const [campaign, setCampaign] = useState<any>(DEFAULT_CAMPAIGN);
    const [payloadErrors, setPayloadErrors] = useState<string[]>([]);
    const [headerConfigErrors, setHeaderConfigErrors] = useState<string[]>([]);
    const [deviceTypes, setDeviceTypes] = useState<number[]>([]);
    const [displayAllCodecs, setDisplayAllCodecs] = useState<boolean>(false);

    const { confirmDialog, closeDialog, dialogOptions } = useDialog();
    const { notif, notifOptions } = useNotif();

    const { duplicate } = useCampaignContext();
    const { successNotif, setSuccessObj } = useCampaignSuccess();

    useEffect(() => {
        setCampaign(
            duplicate
                ? { ...duplicate.campaign, name: duplicate.campaign.name + '_COPIE' }
                : DEFAULT_CAMPAIGN
        );
    }, [duplicate]);

    const resetForm = () => {
        confirmDialog({
            title: 'Souhaitez-vous vraiment annuler vos saisies?',
            onValidate: () => {
                setCampaign(DEFAULT_CAMPAIGN);
                closeDialog();
            },
            onCancel: () => {
                closeDialog();
            }
        });
    };

    const operator = campaign?.operator ? dataService.getOperator(campaign?.operator) : null;
    const campaignProtocol = operator?.protocol;

    const onParametersChange = useCallback((parameters: any) => {
        setPayloadErrors([]);
        const timeoutId = setTimeout(() => {
            setCampaign((prevCampaign: any) => ({
                ...prevCampaign,
                payload_clear: getPayloadClearByDeviceType(prevCampaign.devices, parameters)
            }));
        }, 1000);
    
        return () => clearTimeout(timeoutId);
    }, []);

    const onParametersErrors = useCallback((errors: any) => {
        setPayloadErrors(errors);
    }, []);

    const onChangeHeaderConfig = useCallback((headerConfig: any) => {
        setHeaderConfigErrors([]);
        setCampaign((prevCampaign: any) => {
            return {
                ...prevCampaign,
                header_config: headerConfig
            };
        });
    }, []);

    const onErrorsHeaderConfig = useCallback((errors: any) => {
        setHeaderConfigErrors(errors);
    }, []);

    return (
        <AppPaper>
            <BirdzTitle>
                <Trans>Création d'une intention custom</Trans>
            </BirdzTitle>

            <AppPaper>
                <h3>
                    <Trans>Étape 1: équipement cible Dev / EUI / ID</Trans>
                </h3>
                <CampaignOperator
                    onChange={(operator: any) => {
                        setCampaign({
                            ...campaign,
                            ...{
                                operator: operator?.value,
                                scheduled_date: duplicate ? campaign.scheduled_date : undefined,
                                sending_schedule: duplicate ? campaign.sending_schedule : undefined
                            }
                        });
                    }}
                    operator={operator ? { value: operator.id, label: operator.name } : undefined}
                    allowNullOperator={false}
                />

                {campaign.operator !== undefined && (
                    <CampaignDeviceDevEUI
                        onChange={(devices, operator) => {
                            setDeviceTypes(
                                _.uniq(
                                    devices.map((device: any) => {
                                        return device.device_type;
                                    })
                                )
                            );
                            let newCampaign = { ...campaign, ...{ devices } };
                            // if (operator) {
                            //   newCampaign.operator = operator
                            // }
                            setCampaign(newCampaign);
                        }}
                        operatorId={campaign.operator}
                        campaignType={'custom'}
                    />
                )}
            </AppPaper>

            {campaign.devices && campaign.devices.length > 0 && (
                <>
                    <AppPaper sx={{ mt: 1 }}>
                        <h3>
                            <Trans>Étape 2: choisir le type de trame de l'encodeur</Trans>
                        </h3>

                        <Box>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id={'custom-campaign-display-all-codecs-checkbox'}
                                        onChange={(e: any) =>
                                            setDisplayAllCodecs(!displayAllCodecs)
                                        }
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        checked={displayAllCodecs}
                                    />
                                }
                                label="Afficher tous les codecs"
                                sx={{
                                    '& .MuiSvgIcon-root': { fontSize: 18 },
                                    '& .MuiFormControlLabel-label': { fontSize: '15px' }
                                }}
                            />
                        </Box>
                        <Box>
                            <CampaignMultiAntenna
                                onChange={(isMultiAntenna) => {
                                    setCampaign({
                                        ...campaign,
                                        ...{
                                            is_multi_antenna: isMultiAntenna
                                        }
                                    });
                                }}
                            />
                        </Box>

                        <CampaignCodec
                            onChange={(codec) => {
                                setCampaign({
                                    ...campaign,
                                    ...{
                                        codecs_path: getCodecsPathByDeviceType(
                                            campaign.devices,
                                            codec
                                        ),
                                        payload_clear: {}
                                    }
                                });
                            }}
                            codec={getCodecsPath(campaign.codecs_path)}
                            label="Codec"
                            type="encoder"
                            filterProtocol={campaignProtocol}
                            filterDeviceTypes={deviceTypes}
                            showAll={displayAllCodecs}
                        />

                        <CampaignCodec
                            onChange={(codec) => {
                                setCampaign({
                                    ...campaign,
                                    ...{
                                        codecs_path_ack: getCodecsPathByDeviceType(
                                            campaign.devices,
                                            codec
                                        )
                                    }
                                });
                            }}
                            codec={getCodecsPath(campaign.codecs_path_ack)}
                            label="Codec accusé de réception"
                            type="ack"
                            filterProtocol={campaignProtocol}
                            filterDeviceTypes={deviceTypes}
                            showAll={displayAllCodecs}
                        />
                    </AppPaper>

                    {operator && campaignService.operatorUseHRProtocol(campaign?.operator) && (
                        <CampaignHeaderConfig
                            label="Étape 3: header"
                            onChange={onChangeHeaderConfig}
                            value={campaign?.header_config}
                            onErrors={onErrorsHeaderConfig}
                        />
                    )}

                    {campaign.codecs_path && campaign.operator && (
                        <>
                            <AppPaper sx={{ mt: 1 }}>
                                <h3>
                                    <Trans>
                                        Étape{' '}
                                        {operator &&
                                        campaignService.operatorUseHRProtocol(campaign?.operator)
                                            ? '4'
                                            : '3'}
                                        : action sur cible
                                    </Trans>
                                </h3>

                                <MemoizedParameters
                                    codec={getCodecsPath(campaign.codecs_path)}
                                    values={getPayloadClear(campaign.payload_clear)}
                                    duplicationValues={
                                        duplicate ? getPayloadClear(campaign.payload_clear) : null
                                    }
                                    onChange={onParametersChange}
                                    onErrors={onParametersErrors}
                                />

                                <CampaignPort
                                    onChange={(port: number) => {
                                        setCampaign({
                                            ...campaign,
                                            ...{ port }
                                        });
                                    }}
                                    value={campaign.port}
                                />

                                <ScheduleOptions
                                    onChange={(campaignType: any) => {
                                        setCampaign((campaign: any) => {
                                            return {
                                                ...campaign,
                                                ...{
                                                    scheduled_date: campaignType.scheduledDate,
                                                    sending_schedule: campaignType.type
                                                }
                                            };
                                        });
                                    }}
                                    value={{
                                        type: campaign.sending_schedule,
                                        scheduledDate: campaign.scheduled_date
                                    }}
                                    operatorId={campaign.operator}
                                />

                                <CampaignMaxDays
                                    onChange={(max_days: number) => {
                                        setCampaign({
                                            ...campaign,
                                            ...{ max_days }
                                        });
                                    }}
                                    value={campaign.max_days}
                                />
                            </AppPaper>

                            <AppPaper sx={{ mt: 1 }}>
                                <h3>
                                    <Trans>
                                        Étape{' '}
                                        {operator &&
                                        campaignService.operatorUseHRProtocol(campaign?.operator)
                                            ? '5'
                                            : '4'}
                                        : nommer la campagne
                                    </Trans>
                                </h3>

                                <TextField
                                    id={'custom-campaign-name-input'}
                                    type={'text'}
                                    size="small"
                                    fullWidth
                                    onChange={(e: any) => {
                                        setCampaign({
                                            ...campaign,
                                            ...{ name: e.target.value }
                                        });
                                    }}
                                    value={campaign.name || ''}
                                />
                            </AppPaper>
                        </>
                    )}

                    {payloadErrors.length > 0 && (
                        <Alert severity="warning" sx={{ mt: 1, mb: 1 }}>
                            Vous devez corriger les champs suivants du codec:{' '}
                            {payloadErrors.join(', ')}
                        </Alert>
                    )}
                    {headerConfigErrors.length > 0 && (
                        <Alert severity="warning" sx={{ mt: 1, mb: 1 }}>
                            Vous devez corriger les champs suivants du header:{' '}
                            {headerConfigErrors.join(', ')}
                        </Alert>
                    )}

                    <CampaignSaveButtons
                        onReset={() => resetForm()}
                        onSave={() => {
                            const {
                                id,
                                created_at,
                                created_by_token,
                                error_message,
                                state,
                                updated_at,
                                ...campaignToDuplicate
                            } = campaign;
                            const campaignToSave = duplicate ? campaignToDuplicate : campaign;
                            campaignService.save(campaignToSave).then(
                                () => {
                                    setSuccessObj(campaignService.successSavingNotification());
                                },
                                (error) => notif(campaignService.errorSavingNotification(error))
                            );
                        }}
                        saveBtnDisabled={
                            !campaign.name ||
                            payloadErrors.length > 0 ||
                            headerConfigErrors.length > 0
                        }
                    />
                </>
            )}
            <BirdzNotif options={notifOptions} />
            <BirdzDialog options={dialogOptions} />
            {successNotif}
        </AppPaper>
    );
};

export default CustomCampaign;

const MemoizedParameters = React.memo(
    ({ codec, payload, onChange, onErrors, duplicationValues }: any) => {
        return (
            <Box sx={{ mb: 2 }}>
                <Parameters
                    codec={codec}
                    values={payload}
                    duplicationValues={duplicationValues}
                    onChange={onChange}
                    onErrors={onErrors}
                />
            </Box>
        );
    }
);
