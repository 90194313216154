import React from 'react';
import EngineeringIcon from '@mui/icons-material/Engineering';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import PublicIcon from '@mui/icons-material/Public';
import { CircularProgress } from '@mui/material';
import { SidebarBackgroundColor, SidebarTheme } from './types';

export const themeColors: { [key in SidebarTheme]: string } = {
    'base': '#73809d',
    'base-darker': '#434a60',
    'custom-blue': '#635bff',
    'birdz-blue': '#95cde8',
    'birdz-orange': '#f7a677',
    'birdz-green': '#7ed17c'
};

export const backgroundColors: { [key in SidebarBackgroundColor]: string } = {
    base: '#3a4255',
    darker: '#1a1f2c'
};

export const environnements = {
  dev: { label: 'Développement', icon: <EngineeringIcon fontSize={'small'} /> },
  ppd: { label: 'Pré-production', icon: <HourglassTopIcon fontSize={'small'} /> },
  prd: { label: 'Production', icon: <PublicIcon fontSize={'small'} /> },
  default: { label: 'recherche en cours', icon: <CircularProgress color="info" size={15} /> }
};
