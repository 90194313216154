import React, { useState } from 'react';
import { BirdzNotif, useNotif } from '@applications-terrains/birdz-react-library';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import PurposeDetailModal from './PurposeDetailModal';
import { Box, IconButton, Tooltip } from '@mui/material';
import { authService } from '../../../..';
import { SatelliteAlt, Search } from '@mui/icons-material';
import { PiFileCsv } from 'react-icons/pi';
import DuplicateButtonWithSpinner from './DuplicateButtonWithSpinner';
import { useSearchCampaignsAndPurposesContext } from '../SearchCampaignsAndPurposesContext';
import BTable from '../../../Tools/Table/BTable';

export const PurposesList = ({ customFilters }: { customFilters?: string }) => {
    const { purposesFiltersString: filters } = useSearchCampaignsAndPurposesContext();
    const { t } = useTranslation();
    const [sentItem, setSentItem] = useState<any>(null);
    const today = new Date();
    const filename = `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}-${today.getHours()}h${today.getMinutes()}m${today.getSeconds()}s`;
    const exportFileName = `listes-intentions-${filename}.csv`;
    const { notif, notifOptions } = useNotif();

    const getEndpoint = (endpoint: string) =>
        endpoint + (customFilters ? customFilters : filters.replace('&', '?'));

    return (
        <>
            <BTable
                endpoint={getEndpoint('/api/purposes/').replace(
                    'campaign__device_types__in',
                    'device_type__in' //https://birdz.atlassian.net/browse/BID2-1179
                )}
                model={`${customFilters ? 'campaign-details-' : ''}purposes`}
                fields={[
                    {
                        name: 'created_at',
                        label: 'Date',
                        className: 'text-center',
                        options: { width: '180px' },
                        transform: (value: any) => {
                            let date = value && moment.unix(value).format('DD/MM/YYYY à HH:mm:ss');
                            return date || '';
                        },
                        orderable: true
                    },
                    {
                        name: 'id',
                        label: 'Id',
                        className: 'text-center',
                        options: { width: '50px' }
                    },
                    {
                        name: 'aggregated_state',
                        label: 'Statut',
                        className: 'text-center',
                        transform: (status: any) => {
                            return t('status.' + status);
                        },
                        orderable: false
                    },
                    {
                        name: 'device',
                        label: 'Equipement',
                        className: 'text-center',
                        transform: (value: any) => {
                            return value?.module_address;
                        },
                        orderable: false
                    }
                ]}
                actions={[
                    {
                        name: 'refresh',
                        label: 'Rafraîchir',
                        render: (sentItem: any) => {
                            return authService.canAccess('REFRESH_PURPOSES') &&
                                ['processing', 'pending', 'processed'].includes(sentItem.state) ? (
                                <Tooltip title="Rafraîchir" placement="top">
                                    <IconButton
                                        id={'purposesList-refresh-button'}
                                        onClick={() => {
                                            axios
                                                .post('/api/refresh-status/', {
                                                    purpose_id: sentItem.id
                                                })
                                                .then(
                                                    () => {
                                                        notif({
                                                            type: 'success',
                                                            content:
                                                                "L'intention va être mise à jour"
                                                        });
                                                    },
                                                    () => {
                                                        notif({
                                                            type: 'error',
                                                            title: 'Erreur',
                                                            content:
                                                                "Une erreur est survenue lors de la mise à jour de l'intention"
                                                        });
                                                    }
                                                );
                                        }}>
                                        <SatelliteAlt fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            ) : (
                                <></>
                            );
                        }
                    },
                    {
                        name: 'detail',
                        label: 'Voir',
                        render: (sentItem: { id: number }) => {
                            return (
                                <Tooltip title="Voir l'intention" placement="top">
                                    <IconButton
                                        id={'purposesList-see-button'}
                                        onClick={() => {
                                            setSentItem('loading');
                                            axios
                                                .get('/api/purposes/' + sentItem.id + '/')
                                                .then((results) => setSentItem(results.data));
                                        }}>
                                        <Search fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            );
                        }
                    },
                    {
                        name: 'duplicate',
                        label: 'Dupliquer cette intention',
                        render: (sentItem: { campaign: number; id: number | string }) => {
                            return (
                                <Tooltip title="Dupliquer l'intention" placement="top">
                                    <Box>
                                        <DuplicateButtonWithSpinner
                                            url={'/api/campaigns/' + sentItem.campaign + '/'}
                                            purpose={sentItem}
                                        />
                                    </Box>
                                </Tooltip>
                            );
                        }
                    }
                ]}
                exportButtons={[
                    {
                        name: 'Exporter en csv',
                        fileName: exportFileName,
                        type: 'csv',
                        icon: <PiFileCsv />,
                        id: 'purposesList-export-button',
                        requestEndpoint: getEndpoint('/api/purposes/export/'),
                        requestMethod: 'POST',
                        responseType: 'json',
                        onSuccess: () => {
                            notif({
                                type: 'success',
                                title: 'Succès',
                                content:
                                    "L'export est en cours, vous le recevrez par mail lorsqu'il sera terminé"
                            });
                        }
                    }
                ]}
            />

            {sentItem && (
                <PurposeDetailModal
                    sentItem={sentItem}
                    onClose={() => {
                        setSentItem(null);
                    }}
                />
            )}
            <BirdzNotif options={notifOptions} />
        </>
    );
};

export default PurposesList;
