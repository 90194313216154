import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import packageJson from '../../../package.json';
import './Sidebar.scss';
import { useState } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import { authService } from '../..';
import SidebarItem from './SidebarItem/SidebarItem';
import axios from 'axios';
import useGetSidebarItems from './useGetSidebarItems';
import MenuIcon from '@mui/icons-material/Menu';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import ThemeSelector from './ThemeSelector';
import { ApiVersion, SidebarTheme, SidebarBackgroundColor } from './types';
import SidebarInfos from './SidebarInfos';
import { environnements } from './const';
import { useGlobalContext } from '../../contexts/GlobalContext';
import dataService from '../Common/Services/dataService';
import CodecsAndVersions from './CodecsAndVersions';

const Sidebar = ({ ...props }) => {
    const navigate = useNavigate();
    const [apiVersion, setApiVersion] = useState<ApiVersion>();
    const [codec, setCodec] = useState<string>();

    //eslint-disable-next-line
    const { staticContext, ...rest } = props;
    const appVersion = packageJson?.version;

    const sidebarItems = useGetSidebarItems();
    const { theme, setTheme, sidebarIsExpanded, setSidebarIsExpanded, bgColor, setBgColor } =
        useGlobalContext();

    useEffect(() => {
        // Load expanded menu pref stored in localstorage
        const sidebarIsExpanded = JSON.parse(localStorage.getItem('sidebarIsExpanded') || 'true');
        if (typeof sidebarIsExpanded === 'boolean') {
            setSidebarIsExpanded(sidebarIsExpanded);
            setExpandedCssClasses(sidebarIsExpanded);
        }

        const sidebarTheme = localStorage.getItem('sidebarTheme');
        if (sidebarTheme && sidebarTheme !== theme) {
            setTheme(sidebarTheme as SidebarTheme);
        }

        const sidebarBgColor = localStorage.getItem('sidebarBgColor');
        if (sidebarBgColor && sidebarBgColor !== bgColor) {
            setBgColor(sidebarBgColor as SidebarBackgroundColor);
        }

        axios.get('/api/version/').then((response) => {
            setApiVersion(response?.data);
        });

        let encoderSpecs = dataService.getData('encodersSpecs');
        setCodec(`${encoderSpecs?.commit} (${encoderSpecs?.version})`);
    }, [theme, setSidebarIsExpanded, setTheme, bgColor, setBgColor]);

    const toggleBurger = () => {
        localStorage.setItem('sidebarIsExpanded', !sidebarIsExpanded + '');
        setExpandedCssClasses(!sidebarIsExpanded);
        setSidebarIsExpanded(!sidebarIsExpanded);
    };

    const setExpandedCssClasses = (menuIsExpanded: boolean) => {
        if (menuIsExpanded) {
            document.body.classList.add('sidebar-expanded');
            document.body.classList.remove('sidebar-collapsed');
        } else {
            document.body.classList.remove('sidebar-expanded');
            document.body.classList.add('sidebar-collapsed');
        }
    };

    return (
        <div
            className={`sidebar ${theme} bgColor-${bgColor} ${apiVersion?.environment} `}
            {...rest}>
            {sidebarIsExpanded ? (
                <Box>
                    <Box
                        sx={{ mx: 2, mt: 2 }}
                        className="d-flex align-items-center justify-content-between">
                        <Box className="logo d-flex align-items-center">
                            <Box sx={{ ml: 0.5, mt: 0.5 }}>
                                <Link to="/" id="logo">
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/logo-transparent-horizontal-small-white.png'
                                        }
                                        alt="B-Link"
                                        width={180}
                                    />
                                </Link>
                            </Box>
                        </Box>
                        <IconButton
                            id="burger"
                            sx={{ width: 36, height: 36 }}
                            onClick={() => toggleBurger()}>
                            <MenuIcon />
                        </IconButton>
                    </Box>
                </Box>
            ) : (
                <Box
                    sx={{ mx: 2, mt: 3 }}
                    className="d-flex flex-column align-items-center justify-content-between">
                    <IconButton
                        id="burger"
                        sx={{ width: 36, height: 36, mb: 0.5 }}
                        onClick={() => toggleBurger()}>
                        <MenuIcon />
                    </IconButton>
                    <IconButton
                        sx={{ width: 36, height: 36, mb: 1, fontSize: 12, fontWeight: 'bold' }}
                        onClick={() => navigate('/')}
                        className="logo-rotate">
                        {/* <img
                            src={
                                process.env.PUBLIC_URL +
                                '/logo-transparent-horizontal-small-white.png'
                            }
                            alt="B-Link"
                        /> */}
                        B-LINK
                    </IconButton>
                </Box>
            )}
            {sidebarIsExpanded ? (
                <SidebarInfos apiVersion={apiVersion} sidebarIsExpanded={sidebarIsExpanded} />
            ) : (
                <Box className="w-100 d-flex align-items-center justify-content-center collapsed-item">
                    <IconButton
                        sx={{ width: 36, height: 36 }}
                        className={`icon-button ${apiVersion?.environment}`}>
                        {
                            environnements[
                                (apiVersion?.environment as keyof typeof environnements) ||
                                    'default'
                            ].icon
                        }
                    </IconButton>
                    <SidebarInfos apiVersion={apiVersion} sidebarIsExpanded={sidebarIsExpanded} />
                </Box>
            )}

            <Box sx={{ pt: 2 }} className="sidebar-wrapper">
                <Box className="sidebar-scroll">
                    {sidebarItems.filter(Boolean).map((sidebarItem) => (
                        <SidebarItem
                            sidebarItem={sidebarItem}
                            key={sidebarItem.key}
                            path={sidebarItem.key}
                            collapsed={!sidebarIsExpanded}
                        />
                    ))}
                </Box>
            </Box>

            <ThemeSelector
                sidebarIsExpanded={sidebarIsExpanded}
                theme={theme}
                setTheme={(theme) => {
                    setTheme(theme);
                    localStorage.setItem('sidebarTheme', theme);
                }}
                bgColor={bgColor}
                setBgColor={(color) => {
                    setBgColor(color);
                    localStorage.setItem('sidebarBgColor', color);
                }}
            />

            <span className="logout">
                <Tooltip title="se déconnecter" enterDelay={200}>
                    <IconButton onClick={authService.logout}>
                        <PowerSettingsNewIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </span>

            <span className="version">
                <CodecsAndVersions
                    codec={codec || '-'}
                    appVersion={appVersion || '-'}
                    apiVersion={apiVersion}
                />
            </span>
        </div>
    );
};

export default Sidebar;
