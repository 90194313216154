import React from 'react';
import { Box } from '@mui/material';
import { environnements } from './const';
import { ApiVersion } from './types';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { authService } from '../..';
import { useGlobalContext } from '../../contexts/GlobalContext';
import TrueFalse from '../Tools/Table/components/TrueFalse';

const SidebarInfos = ({
    apiVersion,
    sidebarIsExpanded
}: {
    apiVersion: ApiVersion | undefined;
    sidebarIsExpanded: boolean;
}) => {
    const { bgColor } = useGlobalContext();

    return (
        <Box
            fontSize={15}
            className={`environments bgColor-${bgColor} ${!sidebarIsExpanded ? 'collapsed-item-content' : ''}`}>
            <Box sx={{ mb: 0.25, mt: 1 }} className="category">
                Utilisateur
            </Box>
            <Box className="d-flex align-items-end justify-content-between">
                <div className="user-name">{authService.user?.name || '-'}</div>
                <AccountCircleIcon fontSize="small" />
            </Box>

            <Box sx={{ mt: 2, mb: 0.25 }} className="category">
                Environnement
            </Box>
            <Box
                sx={{ mb: 1 }}
                className={`d-flex align-items-end justify-content-between env ${apiVersion?.environment}`}>
                {
                    environnements[
                        (apiVersion?.environment as keyof typeof environnements) || 'default'
                    ].label
                }
                {
                    environnements[
                        (apiVersion?.environment as keyof typeof environnements) || 'default'
                    ].icon
                }
            </Box>
            {apiVersion?.dry_run && (
                <>
                    <Box sx={{ mt: 2, mb: 0.25 }} className="category">
                        Dry run
                    </Box>
                    <Box
                        sx={{ mb: 1 }}
                        className={`d-flex align-items-end justify-content-between`}>
                        <Box>{apiVersion?.dry_run ? 'Activé' : 'Désactivé'}</Box>
                        <Box
                            sx={{
                                backgroundColor: 'white',
                                borderRadius: '50%'
                            }}
                            className={`d-flex align-items-center justify-content-center`}>
                            <TrueFalse value={apiVersion?.dry_run} />
                        </Box>
                    </Box>
                </>
            )}
        </Box>
    );
};

export default SidebarInfos;
