import React from 'react';
import { Box, CircularProgress, IconButton, Popover, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { ApiVersion } from './types';
import { backgroundColors } from './const';
import { useGlobalContext } from '../../contexts/GlobalContext';

const CodecsAndVersions = ({
    codec,
    apiVersion,
    appVersion
}: {
    codec?: string;
    apiVersion?: ApiVersion;
    appVersion: string;
}) => {
    const { bgColor } = useGlobalContext();

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'codecs-versions-popover' : undefined;

    return (
        <Box className="codecs-versions">
            <Tooltip title="codecs et versions" enterDelay={200}>
                <IconButton onClick={handleClick}>
                    {!apiVersion || !codec ? (
                        <CircularProgress color="inherit" size={20} />
                    ) : (
                        <InfoIcon fontSize="small" sx={{ color: 'white' }} />
                    )}
                </IconButton>
            </Tooltip>
            <Popover
                elevation={10}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right'
                }}
                sx={{
                    '& .MuiPaper-root': {
                        backgroundColor: backgroundColors[bgColor],
                        color: 'white',
                        fontSize: 12
                    }
                }}>
                <Box p={2} className="d-flex flex-column justify-content-center" gap={0.5}>
                    <Box
                        sx={{ mb: 0.25, mt: 1, fontWeight: 600, color: '#8a94a6' }}
                        className="category">
                        Codecs
                    </Box>
                    <Box className="d-flex align-items-end justify-content-between">
                        <div className="user-name">{codec || '-'}</div>
                        {/* <AccountCircleIcon fontSize="small" /> */}
                    </Box>
                    <Box
                        sx={{ mb: 0.25, mt: 1, fontWeight: 600, color: '#8a94a6' }}
                        className="category">
                        Version
                    </Box>
                    <Box title={`Dernier commit: ${apiVersion?.last_commit || '-'}`}>
                        {apiVersion?.version}
                    </Box>
                    <Box
                        sx={{ mb: 0.25, mt: 1, fontWeight: 600, color: '#8a94a6' }}
                        className="category">
                        UI
                    </Box>
                    <Box title="UI version">{appVersion || '-'}</Box>
                </Box>
            </Popover>
        </Box>
    );
};

export default CodecsAndVersions;
