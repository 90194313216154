import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { SidebarItemType } from '../useGetSidebarItems';
import { getNestedKeyPath } from './const';
import CollapsedItem from './CollapsedItem';
import Item, { ArrowIcon } from './Item';

const SidebarItem = ({
    sidebarItem,
    level = 0,
    path = '',
    collapsed = false
}: {
    sidebarItem: SidebarItemType;
    level?: number;
    path: string;
    collapsed?: boolean;
}) => {
    const navigate = useNavigate();
    const { pathname, search } = useLocation();
    const [isExpanded, setIsExpanded] = useState(collapsed);
    const hasChildren = sidebarItem.children && sidebarItem?.children?.length > 0;
    let isActive = false;

    if (hasChildren) {
        const childKeys: string[] = [];
        sidebarItem?.children?.forEach((el) => {
            el && childKeys.push(el.key);
        });

        let activeKey = '';
        if (childKeys.length > 0)
            childKeys.forEach((key) => {
          if ((pathname + search).includes(key)) activeKey = key;
        });
        if (activeKey) isActive = pathname.includes(path);
    } else if (level === 0 && pathname.includes(path)) isActive = true;

    useEffect(() => {
        if (!collapsed) {
            setIsExpanded(pathname.includes(path));
        }
    }, [path, pathname, collapsed]);

    if (hasChildren && sidebarItem?.children?.filter(Boolean)?.length === 0) return <></>;

    return collapsed && level === 0 ? (
        <CollapsedItem isActive={isActive} item={sidebarItem} path={path} />
    ) : (
        <Accordion
            disableGutters
            expanded={isExpanded}
            sx={{ px: 2 }}
            className={`${level > 0 ? 'no-padding' : ''} ${isExpanded && hasChildren && level === 0 ? '' : 'no-border'}`}>
            <AccordionSummary
                onClick={(e) => {
                    e.stopPropagation();
                    !collapsed && setIsExpanded(!isExpanded);
                    navigate(
                        sidebarItem.children && sidebarItem?.children?.filter(Boolean)?.length > 0
                            ? level === 0
                                ? getNestedKeyPath(sidebarItem)
                                : path + sidebarItem.children.filter(Boolean)[0].key
                            : path
                    );
                }}
                className={`d-flex w-100 justify-content-between align-items-center level-${level} ${isActive ? 'active' : ''} ${isExpanded && hasChildren ? 'expanded' : ''} ${level > 0 ? 'sidebar-item-child' : 'item'}`}
                expandIcon={hasChildren && <ArrowIcon />}>
                <Item item={sidebarItem} path={path} collapsed={collapsed} />
            </AccordionSummary>
            {hasChildren && (
                <AccordionDetails className={`level-${level}`}>
                    {sidebarItem.children &&
                        sidebarItem?.children?.length > 0 &&
                        sidebarItem.children.map((child) => {
                            if (!child) return null;
                            let item = (
                                <Item
                                    item={child}
                                    key={child.key}
                                    level={1}
                                    path={path + child.key}
                                />
                            );
                            if (child.children && child.children.length > 0) {
                                item = (
                                    <SidebarItem
                                        sidebarItem={child}
                                        key={child.key}
                                        level={1}
                                        path={path + child.key}
                                    />
                                );
                            }
                            return item;
                        })}
                </AccordionDetails>
            )}
        </Accordion>
    );
};

export default SidebarItem;
