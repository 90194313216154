import { Box, Paper } from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';
import './pages.scss'

const PagePaper = (props: PropsWithChildren & { title: ReactNode; className?: string }) => (
    <Paper
        sx={{
            boxShadow: 'none',
            // border: '1px solid',
            // borderColor: 'divider',
            // p: 1,
            pt: 2,
            height: '100%',
            overflow: 'hidden',
            boxSizing: 'border-box'
        }}
        className={`d-flex flex-column page-paper ${props.className}`}>
        <Box
            sx={{
                pb: 3,
                mb: 2,
                borderBottom: '1px solid',
                borderColor: 'divider',
                boxSizing: 'border-box'
            }}>
            {props.title}
        </Box>
        <Box
            sx={{ height: '100%', overflow: 'scroll', boxSizing: 'border-box' }}
            className="d-flex flex-column">
            {props.children}
        </Box>
    </Paper>
);

export default PagePaper;
