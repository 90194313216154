import { Grow, Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';

const LightTooltip = styled(
    ({
        className,
        customPaddingY,
        customShadowElevation,
        customOffsetY,
        customBorderRadius,
        border,
        customShadow, // Nouvelle prop pour la couleur de la boxShadow
        ...props
    }: TooltipProps & {
        customPaddingY?: number;
        customShadowElevation?: number;
        customOffsetY?: number;
        customBorderRadius?: number;
        border?: string;
        customShadow?: string; // Type de la nouvelle prop
    }) => (
        <Tooltip
            {...props}
            classes={{ popper: className }}
            componentsProps={{
                tooltip: {
                    sx: {
                        maxWidth: '600px',
                        py: customPaddingY || 0,
                        px: 2,
                        borderRadius: (customBorderRadius || 4) + 'px'
                    }
                }
            }}
            slots={{
                transition: Grow
            }}
            slotProps={{
                transition: { timeout: 300 },
                popper: {
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, customOffsetY || -10]
                            }
                        }
                    ]
                }
            }}
        />
    )
)(({ theme, customShadowElevation, border, customShadow }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.8)',
        boxShadow: customShadow
            ? customShadow
            : theme.shadows[customShadowElevation !== undefined ? customShadowElevation : 3],
        border
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.white,
        '&::before': {
            backgroundColor: theme.palette.common.white,
            border: '1px solid rgba(0, 0, 0, 0.12)'
        }
    }
}));

export default LightTooltip;
