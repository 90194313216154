import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, Checkbox, Chip } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import useReinitializeForm from '../../../hooks/useReinitializeForm';
import { SelectOptionType } from './types';
import { isArray } from 'lodash';
import ContractsCustomTextField from './ContractsCustomTextField';
import useDisableItemButton from './hooks/useDisableItemButton';

const MultiValuesSelectDropdown = ({
    options,
    onChange,
    defaultValue = [],
    required,
    onSearch,
    searchable,
    selectAll,
    defaultAll
}: {
    options: SelectOptionType[];
    onChange: (data: SelectOptionType[]) => void;
    defaultValue: SelectOptionType[];
    defaultAll: boolean;
    required?: boolean;
    selectAll?: (data: boolean) => void;
    onSearch: (data: string) => void;
    searchable: boolean;
}) => {
    const [value, setValue] = useState<SelectOptionType[] | null>(null);
    const [all, setAll] = useState<boolean>(false);
    const reset = useReinitializeForm();

    const { hasError, setHasError } = useDisableItemButton({
        required: required === true,
        hasValue: all || (value !== null && value.length > 0)
    });

    //handle error status
    useEffect(() => {
        required && all === false && (value === null || value?.length === 0) && setHasError(true);
    }, [value, all, required, setHasError]);

    //setValue
    useEffect(() => {
        if (isArray(defaultValue) && defaultValue.length && value === null) setValue(defaultValue);
        else reset && setValue([]);
    }, [reset, defaultValue, value]);

    // update form on 'all' status change
    useEffect(() => {
        if (selectAll !== undefined) {
            selectAll(all);
        }
    }, [selectAll, all]);

    // make sure 'all' is set if necessary
    useEffect(() => {
        setAll(defaultAll);
    }, [defaultAll]);

    return (
        <Box>
            <Autocomplete
                className="w-100"
                multiple
                value={value || []}
                onChange={(_, newValue) => {
                    setValue(newValue);
                    onChange(newValue);
                    setHasError(required === true && newValue.length === 0);
                    // focusInput();
                }}
                options={options}
                disableCloseOnSelect
                getOptionLabel={(option) => option.label}
                groupBy={(option) => option.group || ''}
                renderOption={(props, option) => {
                    const checked = value?.find((el) => el.value === option.value) !== undefined;
                    return (
                        <li {...props} key={option.label}>
                            <Checkbox
                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                style={{ marginRight: 8 }}
                                checked={checked}
                            />
                            {option.optionLabel}
                        </li>
                    );
                }}
                renderTags={(values: SelectOptionType[], getTagProps: any) => {
                    return values?.map((value: any, index: number) => {
                        const { key, ...tagProps } = getTagProps({ index });
                        return (
                            !all && (
                                <Chip
                                    key={
                                        (typeof key === 'string' ? key : '') +
                                        'chip' +
                                        index +
                                        value?.label
                                    }
                                    label={value?.label}
                                    sx={{ height: '22px' }}
                                    {...tagProps}
                                />
                            )
                        );
                    });
                }}
                isOptionEqualToValue={(option: any, value: any) => {
                    return option.value === value.value;
                }}
                renderInput={(params) => {
                    return (
                        <Box sx={{ position: 'relative' }}>
                            <ContractsCustomTextField
                                {...params}
                                onSearch={onSearch}
                                size="small"
                                error={hasError === true}
                                placeholder={searchable ? 'minimum 3 caractères' : ''}
                                displaySelectAll={selectAll !== undefined}
                                setAll={setAll}
                                all={all}
                            />
                            {all && (
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: 2,
                                        left: 50,
                                        backgroundColor: 'white',
                                        fontSize: '1rem',
                                        borderRadius: '4px',
                                        height: '36px',
                                        width: 'calc(100% - 52px)'
                                    }}
                                    className="d-flex align-items-center">
                                    tous les clients sont selectionnés
                                </Box>
                            )}
                        </Box>
                    );
                }}
            />
            {hasError && (
                <Box
                    sx={{
                        fontWeight: 400,
                        fontSize: '0.75rem',
                        margin: '3px 14px 0',
                        color: '#d32f2f',
                        lineHeight: 1.66,
                        letterSpacing: '0.03333em'
                    }}>
                    Merci de renseigner le contrat / client
                </Box>
            )}
        </Box>
    );
};

export default MultiValuesSelectDropdown;
