import { useEffect, useRef } from 'react';

/**
 * Hook qui retourne une fonction pour exécuter un `setTimeout` nettoyé au démontage.
 * @returns {Function} setSafeTimeout - Fonction pour créer un timeout nettoyé.
 */
export const useSafeTimeout = () => {
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    // Nettoyage automatique au démontage du composant
    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    // Fonction pour enregistrer un timeout
    const setSafeTimeout = (callback: () => void, delay: number) => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(callback, delay);
    };

    return setSafeTimeout;
};
