import moment from 'moment';

type formatErrorInNotifType = {
    message: string;
    errors: string[];
    type?: 'warning' | 'success' | 'info' | 'error';
};
export const formatErrorInNotif = ({ message, errors, type = 'error' }: formatErrorInNotifType) => {
    return {
        type,
        content: (
            <div>
                <>{message}</>
                <ul>
                    {errors.map((error, index) => (
                        <li key={error + index}>
                            {typeof error === 'string' ? error : JSON.stringify(error)}
                        </li>
                    ))}
                </ul>
            </div>
        )
    };
};

export const truncateWithEllipsis = (input: string, length?: number) => {
    const defaultLength = 15;
    return input.length > (length || defaultLength)
        ? `${input.substring(0, length || defaultLength)}...`
        : input;
};

export const getFilterString = (filterValues: { [fieldName: string]: any }) => {
    let filterString = '';
    Object.keys(filterValues).forEach((filterName) => {
        if (typeof filterValues[filterName] === 'undefined' || filterValues[filterName] === null) {
            return;
        }
        if (filterName === 'campaignId') {
            filterString += `&campaign=${filterValues[filterName]}`;
        }
        if (filterName.includes('created_at')) {
            filterString += `&${filterName}=${moment(filterValues[filterName]).format('YYYY-MM-DD')}`;
        } else if (filterValues[filterName] instanceof Array) {
            let filterNameSuffix =
                filterValues[filterName].length > 1 &&
                filterName.substring(filterName.length - 4) !== '__in'
                    ? '__in'
                    : '';
            filterString += `&${filterName}${filterNameSuffix}=${filterValues[filterName].map((obj: { value: string | number; label: string }) => obj.value).join(',')}`;
        } else if (filterValues[filterName] instanceof Object) {
            filterString += `&${filterName}=${filterValues[filterName].value}`;
        } else {
            filterString += `&${filterName}=${filterValues[filterName]}`;
        }
    });
    return filterString;
};

export const getCampaignNumber = (url: string) => {
    // Expression régulière pour capturer le numéro de campagne après '&campaign='
    const regex = /&campaign=(\d+)/;
    const match = url.match(regex);

    // Si un match est trouvé, retourne le groupe capturé (le numéro de campagne), sinon retourne null
    return match ? match[1] : null;
};

export const removeFalsyValues = (obj: Record<string, any>): Record<string, any> => {
    return Object.fromEntries(
        Object.entries(obj).filter(([_, value]) => {
            // Keep entries where the value is not falsy, empty string, or empty array
            return Boolean(value) && !(Array.isArray(value) && value.length === 0) && value !== '';
        })
    );
};

export const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};
