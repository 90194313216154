import React, { useEffect, useState } from 'react';
import Sidebar from './Sidebar/Sidebar';
import dataService from './Common/Services/dataService';
import routes from '../routes';
import { Box, Button, Tooltip } from '@mui/material';
import './DashboardLayout.scss';
import { authService } from '..';
import { Breadcrumbs } from '@applications-terrains/birdz-react-library';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { GlobalContextProvider, useGlobalContext } from '../contexts/GlobalContext';

const Dashboard = ({ component, ...props }: any) => {
    const { staticContext, ...rest } = props;
    const [isReady, setIsReady] = useState<boolean>(false);
    const navigate = useNavigate();
    const { theme, sidebarIsExpanded } = useGlobalContext();

    useEffect(() => {
        async function loadDataService() {
            await dataService.loadData();
        }

        async function getUserInfo() {
            await authService.getUserInfo();
            if (authService.isAuthenticated) {
                await loadDataService();
                setIsReady(true);
            } else {
                navigate('/auth');
            }
        }

        if (!isReady) {
            if (!authService.initialized) {
                getUserInfo();
            } else {
                loadDataService();
                setIsReady(true);
            }
        }
    }, [navigate, isReady]);

    return (
        <>
            {isReady && (
                <div className={`layout-wrapper ${theme}`}>
                    <Sidebar {...rest} />
                    <Box
                        className={`main-panel ${!sidebarIsExpanded ? 'expand' : ''}`}
                        sx={{ boxSizing: 'border-box', backgroundColor: 'background.default' }}>
                        <Box
                            className="d-flex align-items-center justify-content-between"
                            sx={{
                                borderBottom: '1px solid',
                                borderColor: 'divider',
                                height: '50px',
                                mb: 2,
                                mx: 2,
                            }}>
                            <Box className="d-flex align-items-center">
                                <GoBackButton />
                                <Breadcrumbs routes={routes} />
                            </Box>
                            <Tooltip
                                placement="left"
                                title={
                                    authService.user?.previous_login ? (
                                        <span className="last-login">
                                            Dernière connexion le{' '}
                                            {moment(authService.user.previous_login).format(
                                                'DD/MM/YYYY à HH:mm:ss'
                                            )}
                                        </span>
                                    ) : (
                                        ''
                                    )
                                }>
                                <Box className="navbar">
                                    {authService.user ? authService.user.name : ''}
                                    <Box
                                        className="d-flex align-items-center justify-content-center avatar-container"
                                        sx={{
                                            ml: 1
                                        }}>
                                        {authService.user?.avatar ? (
                                            <div
                                                style={{
                                                    backgroundImage:
                                                        'url("' + authService.user.avatar + '"',
                                                    backgroundPosition: 'center',
                                                    height: '30px',
                                                    width: '30px',
                                                    backgroundSize: 'cover'
                                                }}
                                            />
                                        ) : (
                                            <AccountCircleRoundedIcon fontSize="large" />
                                        )}
                                    </Box>
                                </Box>
                            </Tooltip>
                        </Box>
                        <Box className="page-content" sx={{ px: 2 }}>
                            <Box className="inner">{component}</Box>
                        </Box>
                    </Box>
                </div>
            )}
        </>
    );
};

const DashboardLayout = ({ component, ...props }: any) => {
    return (
        <GlobalContextProvider>
            <Dashboard component={component} {...props} />
        </GlobalContextProvider>
    );
};

export default DashboardLayout;

const GoBackButton = () => {
    const [isVisible, setIsVisible] = useState(false);
    const navigate = useNavigate();
    const { pathname } = useLocation();

    useEffect(() => {
        setIsVisible(/settings|new|create|add|view|detail|details|edit/.test(pathname));
    }, [pathname]);

    return (
        <Box
            className={`animated-button ${!isVisible ? 'exit' : ''}`}
            sx={{ mr: isVisible ? 1 : 0 }}>
            <Button
                sx={{
                    px: 0,
                    border: '1px solid',
                    borderColor: 'divider',
                    color: 'divider',
                    width: '35px',
                    maxWidth: '35px',
                    minWidth: '35px',
                    height: '35px'
                }}
                onClick={() => navigate(-1)}>
                <ArrowBackIcon />
            </Button>
        </Box>
    );
};
