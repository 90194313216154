import React, { useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
    BirdzDialog,
    useDialog,
    StyledTitle,
    useNotif,
    BirdzNotif
} from '@applications-terrains/birdz-react-library';
import Axios from 'axios';
import { Button, Grid, IconButton } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Delete, Edit } from '@mui/icons-material';
import PagePaper from '../Tools/Pages/PagePaper';
import BSearchForm from '../Tools/Search/BSearchForm';
import BTable from '../Tools/Table/BTable';
import TrueFalse from '../Tools/Table/components/TrueFalse';

export const providers = [
    { value: '', label: '' },
    { value: 'BIR', label: 'HR Birdz' },
    { value: 'OBS', label: 'Orange Business Service' },
    { value: 'LVO', label: 'Live Objects' },
    { value: 'OBJ', label: 'Objenious' },
    { value: 'ACT', label: 'Actility' },
    { value: 'MOCK', label: 'Mock' },
    { value: 'REQUEA', label: 'Requea' }
];

export const Operators = () => {
    const [filters, setFilters] = useState<string>('');

    const { confirmDialog, closeDialog, dialogOptions } = useDialog();
    const { notif, notifOptions } = useNotif();
    const endpoint = '/api/operators/';
    const model = 'operators-admin';

    const listFields = [
        {
            name: 'id',
            label: 'Id',
            orderable: true,
            options: { width: '80px' }
        },
        {
            name: 'name',
            label: 'Nom',
            orderable: true
        },
        {
            name: 'technical_code',
            label: 'Code opérateur Firestore',
            orderable: true
        },
        {
            name: 'provider',
            label: 'Provider',
            orderable: true,
            transform: (providerCode: string) => {
                return (
                    providers.find((provider) => provider.value === providerCode)?.label ||
                    providerCode
                );
            }
        },
        {
            name: 'type',
            label: 'Type',
            options: { width: '150px' },
            orderable: true
        },
        {
            name: 'is_active',
            label: 'Actif',
            transform: (value: boolean) => {
                return <TrueFalse value={value} />;
            },
            orderable: true
        },
        {
            name: 'updated_at',
            label: 'Date de modification',
            transform: (value: any) => {
                return (value && moment.unix(value).format('DD/MM/YYYY à HH:mm:ss')) || '';
            },
            options: { width: '220px' },
            orderable: true
        }
    ];

    const searchFields = [
        {
            name: 'name',
            label: 'Nom opérateur',
            options: {
                identifier: 'name',
                source: '/api/operators/',
                label: 'name',
                formatResponse: (operator: { name: string }) => ({
                    label: operator.name,
                    value: operator.name
                })
            }
        }
    ];

    const actions = [
        {
            name: 'edit',
            render: (value: any) => {
                return (
                    <IconButton
                        id={'edit-operator-button'}
                        component={Link}
                        to={`/admin/operators/edit/${value.id}`}>
                        <Edit fontSize="small" />
                    </IconButton>
                );
            }
        },
        {
            name: 'delete-operator',
            render: (model: any) => {
                return (
                    <IconButton
                        id={'delete-operator-button'}
                        onClick={() => {
                            confirmDialog({
                                title: "Supprimer l'opérateur",
                                content: 'Êtes-vous sûr de vouloir supprimer cet opérateur?',
                                onValidate: () => {
                                    Axios.delete(`${endpoint}${model.id}/`).then(
                                        () => {
                                            notif({
                                                content: "L'opérateur a été supprimé avec succès",
                                                type: 'success'
                                            });
                                            document.location.reload();
                                        },
                                        () => {
                                            notif({
                                                content:
                                                    'Une erreur est survenue lors de la suppression',
                                                type: 'error'
                                            });
                                        }
                                    );
                                    closeDialog();
                                },
                                onCancel: () => {
                                    closeDialog();
                                }
                            });
                        }}>
                        {' '}
                        <Delete fontSize="small" />
                    </IconButton>
                );
            }
        }
    ];

    return (
        <PagePaper
            title={
                <Grid justifyContent="space-between" alignItems={'center'} container>
                    <Grid item>
                        <StyledTitle className="m-0">Liste des opérateurs</StyledTitle>
                    </Grid>
                    {
                        <Grid item>
                            <Link to="/admin/operators/add">
                                <Button variant="contained" id={'add-operator-button'}>
                                    <AddCircleIcon sx={{ mr: 1 }} /> Ajouter un opérateur
                                </Button>
                            </Link>
                        </Grid>
                    }
                </Grid>
            }>
            <>
                <BSearchForm columns={1} fields={searchFields} setSearchString={setFilters} />
                <BTable
                    model={model}
                    endpoint={endpoint + filters}
                    fields={listFields}
                    actions={actions}
                    defaultOrder="name"
                />
            </>
            <BirdzDialog options={dialogOptions} />
            <BirdzNotif options={notifOptions} />
        </PagePaper>
    );
};

export default Operators;
